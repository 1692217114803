<script>
export default {
    name:'QualificationCertificate'
}
</script>

<template>
<el-image :src="require('/src/assets/imgs/about/no/2.png')" />
</template>

<style scoped>

</style>
