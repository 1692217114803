<template>
<div class="productBox">
  <h1>页面版本正在升级中，敬请期待</h1>
</div>
</template>

<script>

export default {
  name: "ToProDuct",
  data(){
    return{
      chooseProdouct:"",
      moduleSelect:"",
      //导航栏分类
      navigationBarBig:[
        {title:'关于爱登堡',
          navigationBarBigChildren:['企业简介','企业文化','资质证书','企业风采']},
        {title:'新闻资讯',
          navigationBarBigChildren:['企业新闻','行业信息','媒体渠道']},
        {title:'产品中心',
          navigationBarBigChildren:['载客电梯','自动扶梯','载货电梯','医用电梯','杂物电梯','别墅电梯','老楼加装电梯']},
        {title:'业务范围',
          navigationBarBigChildren:['电梯销售','电梯安装','电梯维修','电梯保养','电梯配件','电梯老旧小区加装']},
        {title:'工程案例',
          navigationBarBigChildren:['工程案例']},
        {title:'电梯知识',
          navigationBarBigChildren:['电梯知识','电梯养护']},
        {title:'服务支持',
          navigationBarBigChildren:['客户关怀','营销网络','常见问题']},
        {title:'联系我们',
          navigationBarBigChildren:['联系方式','在线留言']},
      ],
    }
  },
  methods:{
    handleSelect(key,keypath){
      console.log(key,keypath)
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.productBox{
  position: relative;
  z-index: 0;
  width: 99%;
  margin: auto;
  //第一部分
  .welcomeStatement{
    background-color: #e4e6e7;
    height: calc(100vw * 35 / 1920);
    width: 100%;
    font-size: calc(100vw * 12 / 1920);
    color: #999999;
  }
  #welcomeStatementText{
    width: 60%;
    margin-left: 20%;
    float: left;
  }
  #welcomeStatementLink{
    margin: auto;
    float: left;
  }
  //第二部分
  .companyLogo{
    width: 100%;
    height: calc(100vw * 180 / 1920);
    background-color: #0c0c0c;
    .box{
      width: 70%;
      height: 100%;
      margin: auto;
      .adbLogo{
        width: 30%;
        margin-top: 3%;
        float: left;
      }
      .telephoneBox{
        width: 40%;
        margin-left: calc(100vw * 1000 / 1920);
        padding-top: calc(100vw * 46 / 1920);
        .telephone{
          float: left;
          background-position: 0 0;
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-size: auto;
          background-origin: padding-box;
          background-clip: border-box;
          display: inline-block;
        }
        .moduleText{
          display: inline-block;
          color: #fff;
          text-align: left;
          font-family: "Arial","Microsoft YaHei",Tahoma,"Simsun",sans-serif;
          line-height: calc(100vw * 16 / 1920);
          .moduleText1{
            font-size: calc(100vw * 32 / 1920);
          }
          .moduleText2{
            font-size: calc(100vw * 24 / 1920);
          }
        }
      }
    }
  }
  //第三部分
  .navigationBarBig{
    width: 100%;
    .el-menu{
      background-color: #e6002f;
    }
    ::v-deep .el-menu-item {
      background-color: #e6002f;
    }
    .el-menu-item.is-active{
      border-bottom: #EBEEF5 !important;
      color: rgba(0, 0, 0, 0);
    }
    /* //设置鼠标悬停时el-menu-item的样式 */
    /deep/ .el-sub-menu:hover .el-sub-menu__title {
      background-color: #0c0c0c !important;
    }
    /deep/ .el-sub-menu.is-active .el-sub-menu__title {
      background-color: #0c0c0c !important;
    }
    /* 点击出来的submenu下划线进行隐藏 */

    ::v-deep .el-sub-menu.is-active .el-sub-menu__title {
      border-bottom: #EBEEF5 !important;
    }
    .el-menu-item:hover {
      background-color: #0c0c0c;
    }
    .flex-grow{
      flex-grow: 0.4;
    }
  }
  //第四部分
  .bg{
    width: 100%;
    height: calc(100vw * 420 / 1920);
    .bigBg{
      width: 100%;
      height: calc(100vw * 420 / 1920);
      position: absolute;
      z-index: 1;
      margin: auto;
    }
    //大标题
    .titleOne {
      background-color: #e6002f;
      width: calc(100vw * 320 / 1920);
      height: calc(100vw * 170 / 1920);
      position: absolute;
      margin-left: 5%;
      margin-top: calc(100vw * 320 / 1920);
      z-index: 2;

      .productionIcon {
        float: left;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: auto;
        background-origin: padding-box;
        background-clip: border-box;
        display: inline-block;
        margin-left: 5%;
        margin-top: 15%;
      }

      .productionIconText {
        display: inline-block;
        color: #fff;
        text-align: left;

        .productionIconText1 {
          font-size: calc(100vw * 32 / 1920);
        }

        .productionIconText2 {
          font-size: calc(100vw * 24 / 1920);
        }
      }
    }
  }
  //第五部分
  .productIntroduction{
    .productShowBox{
      width: 90%;
      background-color: #f4f6f4;
      margin: auto;
      height: auto;
      display: block;
      overflow: hidden;
      .productMenu{
        width: calc(100vw * 320 / 1920);
        margin-top: calc(100vw * 90 / 1920);
        float: left;
        a{
          text-decoration: none;
        }
        /deep/ .router-link-active {
          text-decoration: none;
          color: #b23521;
        }
        #kf{
          margin-top: calc(100vw * 16 / 1920);
          height: auto;
          width: 100%;
        }
        #words{
          width: 100px;
          height: calc(100vw * 16 / 1920);
          text-align: center;
          line-height: calc(100vw * 16 / 1920);
          color: #666666;
          margin: calc(100vw * 16 / 1920) auto 0;
        }
        #tel{
          color: #e6002f;
          font-size: calc(100vw * 20 / 1920);
          text-align: center;
          font-weight: bold;
          height: calc(100vw * 24 / 1920);
          line-height: calc(100vw * 24 / 1920);
          margin-top: calc(100vw * 16 / 1920);
        }
      }
      .productDisplay{
        width: 79%;
        height: auto;
        margin-left: calc(100vw * 16 / 1920);
        /*background-color: #b23521;*/
        float: left;
      }
    }
  }
}

</style>
