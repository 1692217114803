<script>


export default {
  inject:['reload'],
  // eslint-disable-next-line vue/multi-word-component-names
  name:'products',
  data(){
      return{
        chooseLanguage:'',
        options:[
          {
            value: 'CHINESE',
            label: '中',
          },
          {
            value: 'ENGLISH',
            label: 'EN',
          }
        ],
        width:document.body.clientWidth,
        chooseCategories: 'CK',
        chooseProjectPJ:'厅门式样',
        products: [
          {
            'categories':'CK',
            'slogan':'融汇创新的安全、节能技术，为您的楼宇提供一体化的解决方案',
            'sloganEn':'Integrating innovative safety and energy-saving technologies to provide integrated solutions for your building',
            'title':'乘客电梯轿厢装潢展示',
            'titleEn':'Passenger elevator car decoration display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[
              {'name':'EC-800系列',
              'nameEn':'EC-800 series',
                'img':require('/src/assets/imgs/product/CK/EC800.png')
              },
              {'name':'EC-810系列',
                'nameEn':'EC-810 series',
                'img':require('/src/assets/imgs/product/CK/EC810.png')
              },
              {'name':'EC-830系列','nameEn':'EC-830 series',
                'img':require('/src/assets/imgs/product/CK/EC830.png')
              },
              {'name':'EC-850系列','nameEn':'EC-850 series',
                'img':require('/src/assets/imgs/product/CK/EC850.png')
              },
              {'name':'EC-821系列','nameEn':'EC-821 series',
                'img':require('/src/assets/imgs/product/CK/EC821.png')
              },
              {'name':'EC-822系列','nameEn':'EC-822 series',
                'img':require('/src/assets/imgs/product/CK/EC822.png')
              },
              {'name':'EC-701系列','nameEn':'EC-701 series',
                'img':require('/src/assets/imgs/product/CK/EC701.png')
              },
              {'name':'EC-720系列','nameEn':'EC-720 series',
                'img':require('/src/assets/imgs/product/CK/EC720.png')
              },
              {'name':'EC-753系列','nameEn':'EC-753 series',
                'img':require('/src/assets/imgs/product/CK/EC753.png')
              },
            ]
          },
          {
            'categories':'JY',
            'slogan':'融汇创新的安全、节能技术，为您的楼宇提供一体化的解决方案',
            'sloganEn':'Integrating innovative safety and energy-saving technologies to provide integrated solutions for your building',
            'title':'家用电梯轿厢装潢展示',
            'titleEn':'Home elevator car cabin interior decoration display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[
            ]
          },
          {
            'categories':'GG',
            'slogan':'一道移动的风景，美观而高雅，有着精致的视觉美感和完美的乘坐舒适感。',
            'sloganEn':'A moving landscape, beautiful and elegant, with exquisite visual beauty and perfect riding comfort.',
            'title':'观光电梯轿厢装潢展示',
            'titleEn':'Scenic elevator car cabin interior decoration display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[
              {'name':'EC-930系列','nameEn':'EC-930 series',
                'img':require('/src/assets/imgs/product/GG/EC930.png')
              },
              {'name':'EC-910系列','nameEn':'EC-910 series',
                'img':require('/src/assets/imgs/product/GG/EC910.png')
              },
              {'name':'EC-920系列','nameEn':'EC-920 series',
                'img':require('/src/assets/imgs/product/GG/EC920.png')
              },
              {'name':'EC-931系列','nameEn':'EC-931 series',
                'img':require('/src/assets/imgs/product/GG/EC931.png')
              },
              {'name':'EC-950系列','nameEn':'EC-950 series',
                'img':require('/src/assets/imgs/product/GG/EC950.png')
              },
              {'name':'EC-951系列','nameEn':'EC-951 series',
                'img':require('/src/assets/imgs/product/GG/EC951.png')
              },
            ]
          },
          {
            'categories':'YY',
            'slogan':'为医院提供智能化高效的垂直设备，为医患创造一个舒适安全的空间。',
            'sloganEn':'Provide intelligent and efficient vertical equipment for hospitals, creating a comfortable and safe space for doctors and patients.',
            'title':'医用电梯轿厢装潢展示',
            'titleEn':'Medical elevator car cabin interior decoration display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[
              {'name':'EC-510系列','nameEn':'EC-510 series',
                'img':require('/src/assets/imgs/product/YY/EC510.png')
              },
              {'name':'EC-520系列','nameEn':'EC-520 series',
                'img':require('/src/assets/imgs/product/YY/EC520.png')
              },
            ]
          },
          {
            'categories':'ZH',
            'slogan':'领先的声控技术的应用，超强的运载效能开辟出智慧垂直运输之路。适用超市、工业园、港口、宾馆酒店、机场等场合。',
            'sloganEn':'The application of advanced voice control technology and super strong transportation efficiency have opened up the path of intelligent vertical transportation. Suitable for supermarkets, industrial parks, ports, hotels, airports, and other occasions.',
            'title':'载货电梯轿厢装潢展示',
            'titleEn':'Cargo elevator car cabin interior decoration display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[
              {'name':'EC-310系列','nameEn':'EC-310 series',
                'img':require('/src/assets/imgs/product/ZH/EC310.jpg')
              },
              {'name':'EC-320系列','nameEn':'EC-320 series',
                'img':require('/src/assets/imgs/product/ZH/EC320.jpg')
              },
            ]
          },
          {
            'categories':'PJ',
            'title':'电梯配件展示',
            'titleEn':'Elevator accessories display',
            'warning':'* 效果图仅供参考，详细信息请咨询当地销售。',
            'warningEn':'* The rendering is for reference only, please consult local sales for detailed information.',
            'products':[]
          }
        ],
        productPJ:[
          {
            'categoriesPJ':'厅门式样',
            'categoriesPJEn':'Hall Door Style',
            'product':[
              {'name':'玫瑰金镜面不锈钢（倒角菱形蚀刻）',
               'nameEn':'Rose gold mirror stainless steel (chamfered diamond etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/meigui.jpg')
              },
              {'name':'咖啡金镜面不锈钢（柳叶蚀刻）',
                'nameEn':'Coffee gold mirror stainless steel (willow leaf etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/kafeijing.jpg')
              },
              {'name':'黑钛金镜面不锈钢(龙柱蚀刻）',
                'nameEn':'Black titanium gold mirror stainless steel (dragon column etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/heitai.jpg')
              },
              {'name':'古铜发纹不锈钢(四叶草蚀刻）',
                'nameEn':'Ancient copper hairline stainless steel (four leaf grass etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/gutong.jpg')
              },
              {'name':'香槟金镜面不锈钢(星型蚀刻）',
                'nameEn':'Champagne Gold Mirror Stainless Steel (Star Etched)',
              'img':require('/src/assets/imgs/product/PJ/TM/xiangbing.jpg')
              },
              {'name':'钛金镜面不锈钢(方拱形蚀刻）',
                'nameEn':'Titanium mirror stainless steel (square arch etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/taijing.jpg')
              },
              {'name':'浅古铜发纹不锈钢',
                'nameEn':'Light antique copper hairline stainless steel',
              'img':require('/src/assets/imgs/product/PJ/TM/qiangutong.jpg')
              },
              {'name':'镜面不锈钢(横条蚀刻）',
                'nameEn':'Mirror stainless steel (horizontal strip etching)',
              'img':require('/src/assets/imgs/product/PJ/TM/jingmian.jpg')
              },
              {'name':'发纹不锈钢',
                'nameEn':'Hairline stainless steel',
              'img':require('/src/assets/imgs/product/PJ/TM/fawen.jpg')
              },
            ]
          },
          {
            'categoriesPJ':'到站灯式样',
            'categoriesPJEn':'Arrival Light Style',
            'product':[
              { 'name':'EL2-02F4',
                'nameTwoEn':'Long wall hanging bottomless box',
                'nameTwo':'长条形挂壁无底盒',
                'img':require('/src/assets/imgs/product/PJ/DZD/EL202F4.jpg')
              },
              {'name':'EL2-02F5',
                'nameTwo':'方形挂壁无底盒',
                'nameTwoEn':'Square wall mounted bottomless box',
                'nameThree':'(可选到站铃)',
                'nameThreeEn':'(Optional arrival bell)',
                'img':require('/src/assets/imgs/product/PJ/DZD/EL202F5.jpg')
              },
              {'name':'EL2-S2',
                'nameTwo':'立体三角挂壁无底盒',
                'nameTwoEn':'3D triangular wall mounted bottomless box',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DZD/EL2S2.jpg')
              },
              {'name':'EL2-02Y2',
                'nameTwo':'半球形挂壁无底盒',
                'nameTwoEn':'Hemispherical wall mounted bottomless box',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DZD/EL202Y2.jpg')
              },
            ]
          },
          {
            'categoriesPJ':'外呼式样',
            'categoriesPJEn':'Outgoing Call Style',
            'product':[
              {'name':'EB8-发纹不锈钢单按钮',
                'nameEn':'EB8-Hairline stainless steel single button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB8-发纹不锈钢单按钮.jpg')
              },
              {'name':'EB8-玫金不锈钢单按钮',
                'nameEn':'EB8-Rose gold stainless steel single button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB8-玫瑰金不锈钢单按钮.jpg')
              },
              {'name':'EB8-发纹不锈钢双按钮',
                'nameEn':'EB8-Hairline stainless steel double button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB8-发纹不锈钢.jpg')
              },
              {'name':'EB8-玫瑰金不锈钢双按钮',
                'nameEn':'EB8-Rose gold stainless steel double button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB8-玫瑰金不锈钢.jpg')
              },
              {'name':'EB10-白色玻璃面板单触屏按钮',
                'nameEn':'EB10-White glass panel single touch screen button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB10-白色玻璃面板chumo.jpg')
              },
              {'name':'EB10-黑色玻璃面板单触屏按钮',
                'nameEn':'EB10-Black glass panel single touch screen button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB10-黑色玻璃面板chumo.jpg')
              },
              {'name':'EB10-白色玻璃面板双触屏按钮',
                'nameEn':'EB10-White glass panel double touch screen button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB10-白色玻璃面板chumo双按钮.jpg')
              },
              {'name':'EB10-黑色玻璃面板双触屏按钮',
                'nameEn':'EB10-Black glass panel double touch screen button',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB10-黑色玻璃面板chumo双按钮.jpg')
              },
              /*{'name':'EB5',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB5.jpg')
              },
              {'name':'EB5-带声控',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB5-带声控.jpg')
              },
              {'name':'EB5-带刷卡',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB5-带刷卡.jpg')
              },*/
              {'name':'EB7',
                'nameEn':'EB7',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB7.jpg')
              },
              {'name':'EB9',
                'nameEn':'EB9',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB9.jpg')
              },
              {'name':'EW3',
                'nameEn':'EW3',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EW3.jpg')
              },
              {'name':'EW3-玫瑰金不锈钢',
                'nameEn':'EW3-Rose gold stainless steel',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EW3-玫瑰金不锈钢.jpg')
              },
              {'name':'EB8-浅古铜不锈钢并联带声控',
                'nameEn':'EB8-Shallow antique copper and stainless steel parallel connection with sound control',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/WH/EB8-浅古铜不锈钢-带声控.jpg')
              },
            ]
          },
          {
            'categoriesPJ':'吊顶式样',
            'categoriesPJEn':'Ceiling Style',
            'product':[
              {'name':'EDT-06',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-06.jpg')
              },
              {'name':'EDT-07',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-07.jpg')
              },
              {'name':'EDT-19',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-19.jpg')
              },
              {'name':'EDT-21',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-21.jpg')
              },
              {'name':'EDT-22',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-22.jpg')
              },
              {'name':'EDT-23',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-23.jpg')
              },
              {'name':'EDT-27',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-27.jpg')
              },
              {'name':'EDT-28',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-28.jpg')
              },
              {'name':'EDT-29',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DD/EDT-29.jpg')
              },
            ]
          },
          {
            'categoriesPJ':'地坪式样',
            'categoriesPJEn':'Floor Pattern',
            'product':[
              {'name':'FL-35',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-35.jpg')
              },
              {'name':'FL-36',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-36.jpg')
              },
              {'name':'FL-53',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-53.jpg')
              },
              {'name':'FL-52',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-52.jpg')
              },
              {'name':'FL-33',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-33.jpg')
              },
              {'name':'FL-50',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-50.jpg')
              },
              {'name':'FL-31',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-31.jpg')
              },
              {'name':'FL-32',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-32.jpg')
              },
              {'name':'FL-30',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-30.jpg')
              },
              {'name':'FL-07',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-07.jpg')
              },
              {'name':'FL-51',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-51.jpg')
              },
              {'name':'FL-12',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/DP/FL-12.jpg')
              },
            ]
          },
          {
            'categoriesPJ':'按钮式样',
            'categoriesPJEn':'Button Style',
            'product':[
              {'name':'方形不锈钢',
                'nameEn':'Square stainless steel',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/fxbx.png')
              },
              {'name':'圆形不锈钢',
                'nameEn':'Round stainless steel',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/yxbxg.png')
              },
              {'name':'方形不锈钢',
                'nameEn':'Square stainless steel',
                'nameTwo':'(专用于残操)',
                'nameTwoEn':'(dedicated to disabled operations)',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/fxbxg.png')
              },
              {'name':'圆形铜制按钮(高端)',
                'nameEn':'Round copper button (high-end)',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/yxtzan.png')
              },
              {'name':'方形亚克力',
                'nameEn':'Square acrylic',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/fxykl.png')
              },
              {'name':'圆形亚克力',
                'nameEn':'Round acrylic',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/yxykl.png')
              },
              {'name':'长方形亚克力',
                'nameEn':'Rectangular acrylic',
                'nameTwo':'',
                'nameThree':'',
                'img':require('/src/assets/imgs/product/PJ/AN/cfxykl.png')
              },
            ]
          },
        ],
      }

  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
  components: {},
  methods:{
      // 判断phone端还是pc端
      _isMobile() {
          let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
          return flag;
      },
      showTel(){
          this.isShowTels=true;
      },
      goTop(){
          window.scrollTo( 0, 0 );
      },
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  }
}
</script>

<template>
<div>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div :style="{width:this.width+'px',height:(this.width/2543)*868+'px'}" class="firstPart">
    <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*642+'px'}">
      <p v-show="this.chooseLanguage==='CHINESE'" :style="{marginTop:(this.width/2543)*228+'px',marginLeft:(this.width/2543)*50+'px',fontSize:(this.width/2543)*65.06+'px'}" style="color: white;font-weight: bolder;font-family: '华文细黑">
        <span>
          不仅是上上下下的<span style="background-color: #d20000">享受</span>
          <br>
          更是进进出出的<span style="background-color: #d20000">平安</span>和<span style="background-color: #d20000">健康</span>
        </span>
      </p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :style="{marginTop:(this.width/2543)*228+'px',marginLeft:(this.width/2543)*50+'px',fontSize:(this.width/2543)*50.06+'px'}" style="color: white;font-weight: bolder;font-family: '华文细黑">
        <span>
          It's not just about <span style="background-color: #d20000">enjoying</span> travelling up and down
          <br>
          It is also <span style="background-color: #d20000">the safety and health</span> of entering and exiting
        </span>
      </p>
    </div>
    <div :style="{width:this.width+'px'}">
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px'}" @click="this.chooseCategories='CK'" :class="this.chooseCategories=='CK'?'chosenCategories':'sixCategories'">
        <div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productCKLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          乘客电梯
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Passenger Elevator
        </span>
      </div>
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px',marginLeft:(this.width/2543)*4+'px'}" @click="$router.push('/villaLadder')" :class="this.chooseCategories=='JY'?'chosenCategories':'sixCategories'">
        <div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productJYLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          家用电梯
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Home Elevator
        </span>
      </div>
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px',marginLeft:(this.width/2543)*4+'px'}" @click="this.chooseCategories='GG'" :class="this.chooseCategories=='GG'?'chosenCategories':'sixCategories'"><div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productGGLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          观光电梯
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Sightseeing Elevator
        </span>
      </div>
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px',marginLeft:(this.width/2543)*4+'px'}" @click="this.chooseCategories='YY'" :class="this.chooseCategories=='YY'?'chosenCategories':'sixCategories'"><div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productYYLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          医用电梯
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Hospital Elevator
        </span>
      </div>
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px',marginLeft:(this.width/2543)*4+'px'}" @click="this.chooseCategories='ZH'" :class="this.chooseCategories=='ZH'?'chosenCategories':'sixCategories'"><div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productZHLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          载货电梯
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Freight Elevator
        </span>
      </div>
      <div :style="{width:(this.width/2543)*420+'px',height:(this.width/2543)*223+'px',marginLeft:(this.width/2543)*4+'px'}" @click="this.chooseCategories='PJ'" :class="this.chooseCategories=='PJ'?'chosenCategories':'sixCategories'"><div :style="{width:(this.width/2543)*113+'px',height:(this.width/2543)*125+'px'}" class="productPJLogo"></div>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          电梯配件
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder;color: white;font-family: '华文雅黑">
          Elevator Parts
        </span>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px'}" class="secondPart">
    <div :style="{width:(this.width/2543)*1364+'px'}" v-for="(item,index) in this.products" :key="index">
      <p v-if="item.categories===this.chooseCategories" :style="{fontSize:(this.width/2543)*30+'px'}" style="text-align: center">
        <span v-show="this.chooseLanguage==='CHINESE'" :class="item.categories==='PJ'?'partTitlePJ':'partTitle'">{{item.title}}</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :class="item.categories==='PJ'?'partTitlePJ':'partTitle'">{{item.titleEn}}</span>
      </p>
      <p v-if="item.categories===this.chooseCategories" :style="{fontSize:(this.width/2543)*30+'px'}" style="text-align: center">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{item.slogan}}</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{item.sloganEn}}</span>
      </p>
<!--      配件的内容-->
      <div v-if="item.categories===this.chooseCategories&&item.categories==='PJ'">
        <div :style="{width:(this.width/2543)*1364+'px'}" >
          <p :style="{width:(this.width/2543)*224+'px',height:(this.width/2543)*108+'px',fontSize:(this.width/2543)*30+'px',marginRight:indexPJ/this.productPJ.length!==1?(this.width/2543)*1.5+'px':0}"  v-for="(productsPJ,indexPJ) in this.productPJ" :key="indexPJ" :class="this.chooseProjectPJ===productsPJ.categoriesPJ?'productPJChoose':'productPJ'" @click="this.chooseProjectPJ=productsPJ.categoriesPJ">
            <span v-show="this.chooseLanguage==='CHINESE'">{{productsPJ.categoriesPJ}}</span>
            <span v-show="this.chooseLanguage!=='CHINESE'">{{productsPJ.categoriesPJEn}}</span>
          </p>
          <div  v-for="(productsPJ,indexPJ) in this.productPJ" :key="indexPJ" style="float: left">
            <div v-if="this.chooseProjectPJ===productsPJ.categoriesPJ">
              <div v-show="this.chooseProjectPJ==='厅门式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{marginRight:(productPJSIndex+1)%3===0?0:(this.width/2543)*20+'px',width:(this.width/2543)*437+'px',height:(this.width/2543)*500+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf;float: left"></div>
                <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:productsPJ.product.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*437+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                </p>
                <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:productsPJ.product.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*437+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameEn}}</span>
                </p>
              </div>
              <div v-show="this.chooseProjectPJ==='到站灯式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{marginRight:(productPJSIndex+1)%4===0?0:(this.width/2543)*20+'px',width:(this.width/2543)*319+'px',height:(this.width/2543)*522+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf;float: left"></div>
                <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*319+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameTwo}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameThree}}</span>
                </p>
                <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*319+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameTwoEn}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameThreeEn}}</span>
                </p>
              </div>
              <div v-show="this.chooseProjectPJ==='外呼式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{marginRight:(productPJSIndex+1)%4===0?0:(this.width/2543)*20+'px',width:(this.width/2543)*319+'px',height:(this.width/2543)*522+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf;float: left"></div>
                <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*319+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameTwo}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameThree}}</span>
                </p>
                <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*319+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameEn}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameTwo}}</span>
                  <br>
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.nameThree}}</span>
                </p>
              </div>
              <div v-show="this.chooseProjectPJ==='吊顶式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{marginRight:(productPJSIndex+1)%3===0?0:(this.width/2543)*20+'px',width:(this.width/2543)*437+'px',height:(this.width/2543)*500+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf;float: left"></div>
                <p :style="{width:productsPJ.product.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*437+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                </p>
              </div>
              <div v-show="this.chooseProjectPJ==='地坪式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{marginRight:(productPJSIndex+1)%3===0?0:(this.width/2543)*20+'px',width:(this.width/2543)*437+'px',height:(this.width/2543)*500+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf;float: left"></div>
                <p :style="{width:productsPJ.product.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*437+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                </p>
              </div>
              <div v-show="this.chooseProjectPJ==='按钮式样'"  v-for="(productPJS,productPJSIndex) in productsPJ.product" :key="productPJSIndex" style="float: left">
                <div :style="{width:productPJS.name!=='长方形亚克力'?(this.width/2543)*1364*0.19+'px':(this.width/2543)*1364*0.4+'px',height:(this.width/2543)*1364*0.2+'px',backgroundImage:this.chooseProjectPJ===productsPJ.categoriesPJ?'url('+productPJS.img+')':'',marginBottom:(this.width/2543)*15+'px'}" style="background-size: 100% 100%;border: 1px solid rgba(207,207,207,0);float: left"></div>
                <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:productPJS.name!=='长方形亚克力'?(this.width/2543)*1364*0.19+'px':(this.width/2543)*1364*0.4+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{productPJS.name}}</span>
                </p>
                <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:productPJS.name!=='长方形亚克力'?(this.width/2543)*1364*0.19+'px':(this.width/2543)*1364*0.4+'px'}" style="text-align: center" >
                  <span :style="{fontSize:(this.width/2543)*15+'px'}" style="color: #333333">{{productPJS.nameEn}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="item.categories===this.chooseCategories&&item.categories!=='PJ'" :style="{marginTop:(this.width/2543)*50+'px',marginRight:(productIndex+1)%3===0?0:(this.width/2543)*30+'px',height:'auto'}" v-for="(needProduct,productIndex) in item.products" :key="productIndex" style="float: left">
        <div :style="{width:item.products.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*430+'px',height:(this.width/2543)*549+'px',backgroundImage:'url('+needProduct.img+')'}" style="background-size: 100% 100%;border: 1px solid #cfcfcf"></div>
        <p :style="{width:item.products.length===2?(this.width/2543)*1364*0.45+'px':(this.width/2543)*430+'px'}" style="text-align: center">
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{needProduct.name}}</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="color: #333333">{{needProduct.nameEn}}</span>
        </p>
      </div>
    </div>
    <p :style="{fontSize:(this.width/2543)*30+'px',width:(this.width/2543)*1364+'px'}" style="text-align: right">
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',marginTop:(this.width/2543)*50+'px'}" style="color: #5e5e5e">* 效果图仅供参考，详细信息请咨询当地销售。</span>
      <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',marginTop:(this.width/2543)*50+'px'}" style="color: #5e5e5e">* The rendering is for reference only, please consult local sales for detailed information.</span>
    </p>
  </div>
  <div class="titleEight">
      <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
  </div>
</div>
</template>

<style lang="scss" scoped>
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.firstPart{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("/src/assets/imgs/product/productsImgOne.png");
  background-size: 100% 100%;
  .chosenCategories{
    float: left;
    display: flex;
    background-color: #e10012;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .sixCategories{
    float: left;
    display: flex;
    background-color: rgba(12, 12, 12, 0.6);
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .productCKLogo{
    background-image: url("/src/assets/imgs/product/productCKLogo.png");
    background-size: 100% 100%;
  }
  .productJYLogo{
    background-image: url("/src/assets/imgs/product/productJYLogo.png");
    background-size: 100% 100%;
  }
  .productGGLogo{
    background-image: url("/src/assets/imgs/product/productGGLogo.png");
    background-size: 100% 100%;
  }
  .productYYLogo{
    background-image: url("/src/assets/imgs/product/productYYLogo.png");
    background-size: 100% 100%;
  }
  .productZHLogo{
    background-image: url("/src/assets/imgs/product/productZHLogo.png");
    background-size: 100% 100%;
  }
  .productPJLogo{
    background-image: url("/src/assets/imgs/product/productPJLogo.png");
    background-size: 100% 100%;
  }
  .sixCategories:hover{
    background-color: #e10012;
  }
}
.secondPart{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .productPJ{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    float: left;
    color: #333333;
    font-weight: bolder;
    font-family: '华文细黑';
    background-color: #dedede;
  }
  .productPJ:hover{
    background-color: #e10012;
    color: white;
  }
  .productPJChoose{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    float: left;
    color: white;
    font-weight: bolder;
    font-family: '华文细黑';
    background-color: #e10012;
  }
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
    height: auto;
    text-align: right;
    color: white;
    background-color: #0c0c0c;
}
</style>
