<script>
import {CirclePlus} from "@element-plus/icons-vue";

export default {
  name:'hotNews',
  components:[CirclePlus],
  data(){
    return{
      intervalId: null, // 用于存储定时器标识符
      width:document.body.clientWidth,
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
      isStayNews:false,
      news:[
        {
          'newsId':0,
          'newsTitle':'李绥校董在东华大学2023届学生毕业典礼上作为校友代表发言',
          'newsTitleEn':'Li Sui, President of Donghua University, delivered a speech as an alumni representative at the 2023 Student Graduation Ceremony',
          'newsTextEn':'On June 27th, the 2023 graduation ceremony and degree awarding ceremony of Donghua University was held at the ice hockey center of Shanghai University Sports Center in Songjiang University Town.',
          'newsText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。\n' +
              '李绥校董在研究生毕业典礼上的发言原文:\n' +
              '尊敬的各位老师、亲爱的学弟学妹们：\n' +
              '大家好！非常高兴再次回到母校与各位老师、同学在此相聚，与大家一起分享此刻的喜悦、见证同学们的毕业典礼！\n' +
              '首先，祝贺各位同学顺利完成研究生学习、踏入人生新的旅程，我为你们感到骄傲！三十多年前，我和同学们一样，完成研究生阶段的学习。我的第一份工作是留校当老师、第二份工作是在外资企业做技术研发。\n' +
              '1996年，我离开外资企业创业，成立了“上海爱登堡电子有限公司”，在母校的支持下，在自动化系三位退休老师的帮助和参与下，研发、生产变频变压调速装置。那个时候，变频变压调速装置依赖进口、市场价格很高，我们生产销售的产品也有利润，3年后积累了一定的经济基础，就筹划买地建厂，想由生产电梯核心部件发展到生产整机的电梯公司，但当时很难取得整机生产许可证。\n' +
              '幸运的是，1999年，学校建高层教师公寓，需要三台电梯。我主动向母校表明了我的创业理想和当时事业发展遇到的困境，并承诺15年质保做一台样梯，感谢母校的信任，给了我制造样梯、尝试创新机会；从那以后，我经常带客户去参观、并与相邻的两台三菱电梯作性能比较，不仅以质量取胜，而且获得了不少新的订单，公司逐渐走上了整机生产和销售的快速发展之路。\n' +
              '今天，爱登堡集团已是拥有上海闵行、上海临港、德国三个研发中心和江苏、湖北、贵州三个生产基地的企业集团，业务涉及多个领域，产品除在中国销售外，也销往欧洲、中东及东南亚国家和地区。爱登堡被评为国家火炬计划重点高新技术企业、上海市知识产权示范企业、上海市名牌产品、上海市重点新产品、长三角四省一市100佳名牌企业、人工智能绿色电梯上海品牌等。饮水思源，我深知爱登堡这些发展成绩和荣誉的背后，离不开母校对我的培养和支持！\n' +
              '\n' +
              '回顾我的成长和企业发展历程，有五点感悟，想和同学们分享：\n' +
              '一、要有追求定力，才有成功的可能\n' +
              '我离开外资企业，在没有其它人投资的情况下去创业，企业从核心部件生产向整机生产发展，从租房到花费重金买地建厂，都验证了：一个人要有追求、要有强烈的成功欲望，才会果断、大胆抓住机会并为之全力以赴。\n' +
              '二、要有创新思维，才有创新的机会\n' +
              '二三十年前只要胆子大，企业就有发展。但今天，只有创新，企业才有发展；也只有创新，我们的工作和生活才有活力，才有更好的未来！\n' +
              '请问同学们“创新难吗？”实际上并不难！创新的源点在于创新思维，而创新思维来自：勤于观察、寻找痛点、追求增值。有了创新思维，你就会发现我们碰到的事物、所处的场所都存在着大量的可创新之处，也存在于与理想、与希望有差距的地方，这些差距正是我们工作或创业成功的良好机会。\n' +
              '三、既要有主见，又要听相反意见，稳健决策\n' +
              '我知道同学们研究生毕业都学有所成、学有所获，但个人能力强的人有时不想花太多时间听取别人的意见，特别是相反的意见。今天作为校友代表，我想建议同学们养成稳健决策的好习惯：一个决策（特别是重大关键性决策），务必用心争取或创造机会去反复听取反对者的意见，以论证不利情况发生时你是否可以承受，并在执行决策的过程中时刻预防不利情况的发生。我相信，在座的同学们将来都是各行各业的精英，这个稳健的决策习惯会帮助你们的事业稳步提升。\n' +
              '四、分享和感恩，善良和真诚就是人品\n' +
              '对于帮助过你的人，要心怀感恩之情，要及时分享你的每一点成功。别人接受或不接受你的分享或感恩那是别人的事，但你是否给予感恩、分享，那是你的人品。我们往住会忽视对朋友和亲人的分享和感恩，特别是父母、老师；我想告诉同学们：对父母\\老师或者曾受帮助而没有感恩之心的人，在社会上、特别是在一个优秀的企业内部是不受欢迎、也不会被重用的！\n' +
              '对于没有帮助过你的人也要保持一颗善良的心，要善解人意。在别人有任何需要的时候，请真诚地帮助别人，不求回报，这是美德，也是最能创建良好人缘的品德！\n' +
              '请同学们相信，人品和人缘是我们做成任何事情的长期资本。\n' +
              '五、以阳光快乐的心情工作和生活\n' +
              '我们是否常常因为受不公平对待或被人误会或没有达成心愿，而感到沮丧、闷闷不乐？刚工作时的一件事让我记忆犹新：有一年春节，单位发鱼和香蕉，办公室的同事将比较小的鱼和断裂的香蕉分给了我，而把大的鱼和好的香蕉留给自己和他的熟人。当时我不开心，但静下心来想：小的鱼和断的香蕉分给了其它员工，那个员工也会不开心，那个吃亏而不开心的人为什么不能是我呢？后来在工作中，我坚持吃亏在我，凡事从好处想、找快乐，同事们看我从不计较也都愿意帮我，在外资企业的第二年就当上研发部部长；特别是在自己创业后，我想方设法成就员工：专利、科技成果、科技进步奖都归功于员工，公司员工队伍中被评为高级工程师、首席技师的比例很高，普通员工落户上海的比例也很高。2023年，公司有位员工获得全国五一劳动奖章，并且获得全国最美职工称号（全国十位，上海唯一一位）。\n' +
              '同学们，在我们将来的工作和生活中，“不公平”是常态，将“不公平”认为公平是一种心态，更是智慧，很多时候“吃亏是福”，心情快乐是人生最大的财富！\n' +
              '有人说：只要有贵人相助，事业就顺利，生活就美好！我想说：世上没有无缘无故的爱，除了紧急情况，也没有无缘无故的相助。只要我们有追求、能创新、有主见、能分享、会感恩，并以阳光的心态对待工作和生活，我们一定会遇到贵人。\n' +
              '我还想说：父母是我们与生俱来的贵人，而母校和老师也是我们不能忘记的贵人。他们真正无私地奉献、热切期盼我们成长、支持我们成功，让我们在毕业典礼这人生中重要的时刻，感恩父母、感恩母校和老师，向他们深情地道一声：谢谢！\n',
          'newsImages':[],
          'newsIntroductionText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。',
          'newsIntroductionTextEn':'On June 27th, the 2023 graduation ceremony and degree awarding ceremony of Donghua University was held at the ice hockey center of Shanghai University Sports Center in Songjiang University Town.',
          'newsCover':require('/src/assets/imgs/news/bgOne.png'),
          'newsCreateDate':'03',
          'newsCreateDateEn':'03',
          'newsCreateMonth':'2023-07',
          'newsCreateMonthEn':'2023-07',
        },
        {
          'newsId':1,
          'newsTitle':'关于生产厂家对电梯安全运行和维保技术的公告函',
          'newsText':'根据《中华人民共和国特种设备安全法》第四十六条 “电梯投入使用后，电梯制造单位\n' +
              '应当对其制造的电梯的安全运行情况进行跟踪调查和了解，对电梯的维护保养单位或者使用\n' +
              '单位在维护保养和安全运行方面存在的问题，提出改进建议，并提供必要的技术帮助”的要',
          'newsImages':[],
          'newsIntroductionText':'根据《中华人民共和国特种设备安全法》第四十六条 “电梯投入使用后，电梯制造单位\n' +
              '应当对其制造的电梯的安全运行情况进行跟踪调查和了解，对电梯的维护保养单位或者使用',
          'newsCover':require('/src/assets/imgs/news/bgNewsTwo.png'),
          'newsCreateDate':'01',
          'newsCreateMonth':'2024-07'
        },
        /*{
          'newsId':2,
          'newsTitle':'李绥校董在东华大学2023届学生毕业典礼上作为校友代表发言',
          'newsText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。\n' +
              '李绥校董在研究生毕业典礼上的发言原文:\n' +
              '尊敬的各位老师、亲爱的学弟学妹们：\n' +
              '大家好！非常高兴再次回到母校与各位老师、同学在此相聚，与大家一起分享此刻的喜悦、见证同学们的毕业典礼！\n' +
              '首先，祝贺各位同学顺利完成研究生学习、踏入人生新的旅程，我为你们感到骄傲！三十多年前，我和同学们一样，完成研究生阶段的学习。我的第一份工作是留校当老师、第二份工作是在外资企业做技术研发。\n' +
              '1996年，我离开外资企业创业，成立了“上海爱登堡电子有限公司”，在母校的支持下，在自动化系三位退休老师的帮助和参与下，研发、生产变频变压调速装置。那个时候，变频变压调速装置依赖进口、市场价格很高，我们生产销售的产品也有利润，3年后积累了一定的经济基础，就筹划买地建厂，想由生产电梯核心部件发展到生产整机的电梯公司，但当时很难取得整机生产许可证。\n' +
              '幸运的是，1999年，学校建高层教师公寓，需要三台电梯。我主动向母校表明了我的创业理想和当时事业发展遇到的困境，并承诺15年质保做一台样梯，感谢母校的信任，给了我制造样梯、尝试创新机会；从那以后，我经常带客户去参观、并与相邻的两台三菱电梯作性能比较，不仅以质量取胜，而且获得了不少新的订单，公司逐渐走上了整机生产和销售的快速发展之路。\n' +
              '今天，爱登堡集团已是拥有上海闵行、上海临港、德国三个研发中心和江苏、湖北、贵州三个生产基地的企业集团，业务涉及多个领域，产品除在中国销售外，也销往欧洲、中东及东南亚国家和地区。爱登堡被评为国家火炬计划重点高新技术企业、上海市知识产权示范企业、上海市名牌产品、上海市重点新产品、长三角四省一市100佳名牌企业、人工智能绿色电梯上海品牌等。饮水思源，我深知爱登堡这些发展成绩和荣誉的背后，离不开母校对我的培养和支持！\n' +
              '\n' +
              '回顾我的成长和企业发展历程，有五点感悟，想和同学们分享：\n' +
              '一、要有追求定力，才有成功的可能\n' +
              '我离开外资企业，在没有其它人投资的情况下去创业，企业从核心部件生产向整机生产发展，从租房到花费重金买地建厂，都验证了：一个人要有追求、要有强烈的成功欲望，才会果断、大胆抓住机会并为之全力以赴。\n' +
              '二、要有创新思维，才有创新的机会\n' +
              '二三十年前只要胆子大，企业就有发展。但今天，只有创新，企业才有发展；也只有创新，我们的工作和生活才有活力，才有更好的未来！\n' +
              '请问同学们“创新难吗？”实际上并不难！创新的源点在于创新思维，而创新思维来自：勤于观察、寻找痛点、追求增值。有了创新思维，你就会发现我们碰到的事物、所处的场所都存在着大量的可创新之处，也存在于与理想、与希望有差距的地方，这些差距正是我们工作或创业成功的良好机会。\n' +
              '三、既要有主见，又要听相反意见，稳健决策\n' +
              '我知道同学们研究生毕业都学有所成、学有所获，但个人能力强的人有时不想花太多时间听取别人的意见，特别是相反的意见。今天作为校友代表，我想建议同学们养成稳健决策的好习惯：一个决策（特别是重大关键性决策），务必用心争取或创造机会去反复听取反对者的意见，以论证不利情况发生时你是否可以承受，并在执行决策的过程中时刻预防不利情况的发生。我相信，在座的同学们将来都是各行各业的精英，这个稳健的决策习惯会帮助你们的事业稳步提升。\n' +
              '四、分享和感恩，善良和真诚就是人品\n' +
              '对于帮助过你的人，要心怀感恩之情，要及时分享你的每一点成功。别人接受或不接受你的分享或感恩那是别人的事，但你是否给予感恩、分享，那是你的人品。我们往住会忽视对朋友和亲人的分享和感恩，特别是父母、老师；我想告诉同学们：对父母\\老师或者曾受帮助而没有感恩之心的人，在社会上、特别是在一个优秀的企业内部是不受欢迎、也不会被重用的！\n' +
              '对于没有帮助过你的人也要保持一颗善良的心，要善解人意。在别人有任何需要的时候，请真诚地帮助别人，不求回报，这是美德，也是最能创建良好人缘的品德！\n' +
              '请同学们相信，人品和人缘是我们做成任何事情的长期资本。\n' +
              '五、以阳光快乐的心情工作和生活\n' +
              '我们是否常常因为受不公平对待或被人误会或没有达成心愿，而感到沮丧、闷闷不乐？刚工作时的一件事让我记忆犹新：有一年春节，单位发鱼和香蕉，办公室的同事将比较小的鱼和断裂的香蕉分给了我，而把大的鱼和好的香蕉留给自己和他的熟人。当时我不开心，但静下心来想：小的鱼和断的香蕉分给了其它员工，那个员工也会不开心，那个吃亏而不开心的人为什么不能是我呢？后来在工作中，我坚持吃亏在我，凡事从好处想、找快乐，同事们看我从不计较也都愿意帮我，在外资企业的第二年就当上研发部部长；特别是在自己创业后，我想方设法成就员工：专利、科技成果、科技进步奖都归功于员工，公司员工队伍中被评为高级工程师、首席技师的比例很高，普通员工落户上海的比例也很高。2023年，公司有位员工获得全国五一劳动奖章，并且获得全国最美职工称号（全国十位，上海唯一一位）。\n' +
              '同学们，在我们将来的工作和生活中，“不公平”是常态，将“不公平”认为公平是一种心态，更是智慧，很多时候“吃亏是福”，心情快乐是人生最大的财富！\n' +
              '有人说：只要有贵人相助，事业就顺利，生活就美好！我想说：世上没有无缘无故的爱，除了紧急情况，也没有无缘无故的相助。只要我们有追求、能创新、有主见、能分享、会感恩，并以阳光的心态对待工作和生活，我们一定会遇到贵人。\n' +
              '我还想说：父母是我们与生俱来的贵人，而母校和老师也是我们不能忘记的贵人。他们真正无私地奉献、热切期盼我们成长、支持我们成功，让我们在毕业典礼这人生中重要的时刻，感恩父母、感恩母校和老师，向他们深情地道一声：谢谢！\n',
          'newsImages':[],
          'newsIntroductionText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。',
          'newsCover':require('/src/assets/imgs/news/bgThree.png'),
          'newsCreateDate':'03',
          'newsCreateMonth':'2023-07'
        },
        {
          'newsId':3,
          'newsTitle':'李绥校董在东华大学2023届学生毕业典礼上作为校友代表发言',
          'newsText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。\n' +
              '李绥校董在研究生毕业典礼上的发言原文:\n' +
              '尊敬的各位老师、亲爱的学弟学妹们：\n' +
              '大家好！非常高兴再次回到母校与各位老师、同学在此相聚，与大家一起分享此刻的喜悦、见证同学们的毕业典礼！\n' +
              '首先，祝贺各位同学顺利完成研究生学习、踏入人生新的旅程，我为你们感到骄傲！三十多年前，我和同学们一样，完成研究生阶段的学习。我的第一份工作是留校当老师、第二份工作是在外资企业做技术研发。\n' +
              '1996年，我离开外资企业创业，成立了“上海爱登堡电子有限公司”，在母校的支持下，在自动化系三位退休老师的帮助和参与下，研发、生产变频变压调速装置。那个时候，变频变压调速装置依赖进口、市场价格很高，我们生产销售的产品也有利润，3年后积累了一定的经济基础，就筹划买地建厂，想由生产电梯核心部件发展到生产整机的电梯公司，但当时很难取得整机生产许可证。\n' +
              '幸运的是，1999年，学校建高层教师公寓，需要三台电梯。我主动向母校表明了我的创业理想和当时事业发展遇到的困境，并承诺15年质保做一台样梯，感谢母校的信任，给了我制造样梯、尝试创新机会；从那以后，我经常带客户去参观、并与相邻的两台三菱电梯作性能比较，不仅以质量取胜，而且获得了不少新的订单，公司逐渐走上了整机生产和销售的快速发展之路。\n' +
              '今天，爱登堡集团已是拥有上海闵行、上海临港、德国三个研发中心和江苏、湖北、贵州三个生产基地的企业集团，业务涉及多个领域，产品除在中国销售外，也销往欧洲、中东及东南亚国家和地区。爱登堡被评为国家火炬计划重点高新技术企业、上海市知识产权示范企业、上海市名牌产品、上海市重点新产品、长三角四省一市100佳名牌企业、人工智能绿色电梯上海品牌等。饮水思源，我深知爱登堡这些发展成绩和荣誉的背后，离不开母校对我的培养和支持！\n' +
              '\n' +
              '回顾我的成长和企业发展历程，有五点感悟，想和同学们分享：\n' +
              '一、要有追求定力，才有成功的可能\n' +
              '我离开外资企业，在没有其它人投资的情况下去创业，企业从核心部件生产向整机生产发展，从租房到花费重金买地建厂，都验证了：一个人要有追求、要有强烈的成功欲望，才会果断、大胆抓住机会并为之全力以赴。\n' +
              '二、要有创新思维，才有创新的机会\n' +
              '二三十年前只要胆子大，企业就有发展。但今天，只有创新，企业才有发展；也只有创新，我们的工作和生活才有活力，才有更好的未来！\n' +
              '请问同学们“创新难吗？”实际上并不难！创新的源点在于创新思维，而创新思维来自：勤于观察、寻找痛点、追求增值。有了创新思维，你就会发现我们碰到的事物、所处的场所都存在着大量的可创新之处，也存在于与理想、与希望有差距的地方，这些差距正是我们工作或创业成功的良好机会。\n' +
              '三、既要有主见，又要听相反意见，稳健决策\n' +
              '我知道同学们研究生毕业都学有所成、学有所获，但个人能力强的人有时不想花太多时间听取别人的意见，特别是相反的意见。今天作为校友代表，我想建议同学们养成稳健决策的好习惯：一个决策（特别是重大关键性决策），务必用心争取或创造机会去反复听取反对者的意见，以论证不利情况发生时你是否可以承受，并在执行决策的过程中时刻预防不利情况的发生。我相信，在座的同学们将来都是各行各业的精英，这个稳健的决策习惯会帮助你们的事业稳步提升。\n' +
              '四、分享和感恩，善良和真诚就是人品\n' +
              '对于帮助过你的人，要心怀感恩之情，要及时分享你的每一点成功。别人接受或不接受你的分享或感恩那是别人的事，但你是否给予感恩、分享，那是你的人品。我们往住会忽视对朋友和亲人的分享和感恩，特别是父母、老师；我想告诉同学们：对父母\\老师或者曾受帮助而没有感恩之心的人，在社会上、特别是在一个优秀的企业内部是不受欢迎、也不会被重用的！\n' +
              '对于没有帮助过你的人也要保持一颗善良的心，要善解人意。在别人有任何需要的时候，请真诚地帮助别人，不求回报，这是美德，也是最能创建良好人缘的品德！\n' +
              '请同学们相信，人品和人缘是我们做成任何事情的长期资本。\n' +
              '五、以阳光快乐的心情工作和生活\n' +
              '我们是否常常因为受不公平对待或被人误会或没有达成心愿，而感到沮丧、闷闷不乐？刚工作时的一件事让我记忆犹新：有一年春节，单位发鱼和香蕉，办公室的同事将比较小的鱼和断裂的香蕉分给了我，而把大的鱼和好的香蕉留给自己和他的熟人。当时我不开心，但静下心来想：小的鱼和断的香蕉分给了其它员工，那个员工也会不开心，那个吃亏而不开心的人为什么不能是我呢？后来在工作中，我坚持吃亏在我，凡事从好处想、找快乐，同事们看我从不计较也都愿意帮我，在外资企业的第二年就当上研发部部长；特别是在自己创业后，我想方设法成就员工：专利、科技成果、科技进步奖都归功于员工，公司员工队伍中被评为高级工程师、首席技师的比例很高，普通员工落户上海的比例也很高。2023年，公司有位员工获得全国五一劳动奖章，并且获得全国最美职工称号（全国十位，上海唯一一位）。\n' +
              '同学们，在我们将来的工作和生活中，“不公平”是常态，将“不公平”认为公平是一种心态，更是智慧，很多时候“吃亏是福”，心情快乐是人生最大的财富！\n' +
              '有人说：只要有贵人相助，事业就顺利，生活就美好！我想说：世上没有无缘无故的爱，除了紧急情况，也没有无缘无故的相助。只要我们有追求、能创新、有主见、能分享、会感恩，并以阳光的心态对待工作和生活，我们一定会遇到贵人。\n' +
              '我还想说：父母是我们与生俱来的贵人，而母校和老师也是我们不能忘记的贵人。他们真正无私地奉献、热切期盼我们成长、支持我们成功，让我们在毕业典礼这人生中重要的时刻，感恩父母、感恩母校和老师，向他们深情地道一声：谢谢！\n',
          'newsImages':[],
          'newsIntroductionText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。',
          'newsCover':require('/src/assets/imgs/news/addIcon.png'),
          'newsCreateDate':'03',
          'newsCreateMonth':'2023-07'
        },
        {
          'newsId':4,
          'newsTitle':'李绥校董在东华大学2023届学生毕业典礼上作为校友代表发言',
          'newsText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。\n' +
              '李绥校董在研究生毕业典礼上的发言原文:\n' +
              '尊敬的各位老师、亲爱的学弟学妹们：\n' +
              '大家好！非常高兴再次回到母校与各位老师、同学在此相聚，与大家一起分享此刻的喜悦、见证同学们的毕业典礼！\n' +
              '首先，祝贺各位同学顺利完成研究生学习、踏入人生新的旅程，我为你们感到骄傲！三十多年前，我和同学们一样，完成研究生阶段的学习。我的第一份工作是留校当老师、第二份工作是在外资企业做技术研发。\n' +
              '1996年，我离开外资企业创业，成立了“上海爱登堡电子有限公司”，在母校的支持下，在自动化系三位退休老师的帮助和参与下，研发、生产变频变压调速装置。那个时候，变频变压调速装置依赖进口、市场价格很高，我们生产销售的产品也有利润，3年后积累了一定的经济基础，就筹划买地建厂，想由生产电梯核心部件发展到生产整机的电梯公司，但当时很难取得整机生产许可证。\n' +
              '幸运的是，1999年，学校建高层教师公寓，需要三台电梯。我主动向母校表明了我的创业理想和当时事业发展遇到的困境，并承诺15年质保做一台样梯，感谢母校的信任，给了我制造样梯、尝试创新机会；从那以后，我经常带客户去参观、并与相邻的两台三菱电梯作性能比较，不仅以质量取胜，而且获得了不少新的订单，公司逐渐走上了整机生产和销售的快速发展之路。\n' +
              '今天，爱登堡集团已是拥有上海闵行、上海临港、德国三个研发中心和江苏、湖北、贵州三个生产基地的企业集团，业务涉及多个领域，产品除在中国销售外，也销往欧洲、中东及东南亚国家和地区。爱登堡被评为国家火炬计划重点高新技术企业、上海市知识产权示范企业、上海市名牌产品、上海市重点新产品、长三角四省一市100佳名牌企业、人工智能绿色电梯上海品牌等。饮水思源，我深知爱登堡这些发展成绩和荣誉的背后，离不开母校对我的培养和支持！\n' +
              '\n' +
              '回顾我的成长和企业发展历程，有五点感悟，想和同学们分享：\n' +
              '一、要有追求定力，才有成功的可能\n' +
              '我离开外资企业，在没有其它人投资的情况下去创业，企业从核心部件生产向整机生产发展，从租房到花费重金买地建厂，都验证了：一个人要有追求、要有强烈的成功欲望，才会果断、大胆抓住机会并为之全力以赴。\n' +
              '二、要有创新思维，才有创新的机会\n' +
              '二三十年前只要胆子大，企业就有发展。但今天，只有创新，企业才有发展；也只有创新，我们的工作和生活才有活力，才有更好的未来！\n' +
              '请问同学们“创新难吗？”实际上并不难！创新的源点在于创新思维，而创新思维来自：勤于观察、寻找痛点、追求增值。有了创新思维，你就会发现我们碰到的事物、所处的场所都存在着大量的可创新之处，也存在于与理想、与希望有差距的地方，这些差距正是我们工作或创业成功的良好机会。\n' +
              '三、既要有主见，又要听相反意见，稳健决策\n' +
              '我知道同学们研究生毕业都学有所成、学有所获，但个人能力强的人有时不想花太多时间听取别人的意见，特别是相反的意见。今天作为校友代表，我想建议同学们养成稳健决策的好习惯：一个决策（特别是重大关键性决策），务必用心争取或创造机会去反复听取反对者的意见，以论证不利情况发生时你是否可以承受，并在执行决策的过程中时刻预防不利情况的发生。我相信，在座的同学们将来都是各行各业的精英，这个稳健的决策习惯会帮助你们的事业稳步提升。\n' +
              '四、分享和感恩，善良和真诚就是人品\n' +
              '对于帮助过你的人，要心怀感恩之情，要及时分享你的每一点成功。别人接受或不接受你的分享或感恩那是别人的事，但你是否给予感恩、分享，那是你的人品。我们往住会忽视对朋友和亲人的分享和感恩，特别是父母、老师；我想告诉同学们：对父母\\老师或者曾受帮助而没有感恩之心的人，在社会上、特别是在一个优秀的企业内部是不受欢迎、也不会被重用的！\n' +
              '对于没有帮助过你的人也要保持一颗善良的心，要善解人意。在别人有任何需要的时候，请真诚地帮助别人，不求回报，这是美德，也是最能创建良好人缘的品德！\n' +
              '请同学们相信，人品和人缘是我们做成任何事情的长期资本。\n' +
              '五、以阳光快乐的心情工作和生活\n' +
              '我们是否常常因为受不公平对待或被人误会或没有达成心愿，而感到沮丧、闷闷不乐？刚工作时的一件事让我记忆犹新：有一年春节，单位发鱼和香蕉，办公室的同事将比较小的鱼和断裂的香蕉分给了我，而把大的鱼和好的香蕉留给自己和他的熟人。当时我不开心，但静下心来想：小的鱼和断的香蕉分给了其它员工，那个员工也会不开心，那个吃亏而不开心的人为什么不能是我呢？后来在工作中，我坚持吃亏在我，凡事从好处想、找快乐，同事们看我从不计较也都愿意帮我，在外资企业的第二年就当上研发部部长；特别是在自己创业后，我想方设法成就员工：专利、科技成果、科技进步奖都归功于员工，公司员工队伍中被评为高级工程师、首席技师的比例很高，普通员工落户上海的比例也很高。2023年，公司有位员工获得全国五一劳动奖章，并且获得全国最美职工称号（全国十位，上海唯一一位）。\n' +
              '同学们，在我们将来的工作和生活中，“不公平”是常态，将“不公平”认为公平是一种心态，更是智慧，很多时候“吃亏是福”，心情快乐是人生最大的财富！\n' +
              '有人说：只要有贵人相助，事业就顺利，生活就美好！我想说：世上没有无缘无故的爱，除了紧急情况，也没有无缘无故的相助。只要我们有追求、能创新、有主见、能分享、会感恩，并以阳光的心态对待工作和生活，我们一定会遇到贵人。\n' +
              '我还想说：父母是我们与生俱来的贵人，而母校和老师也是我们不能忘记的贵人。他们真正无私地奉献、热切期盼我们成长、支持我们成功，让我们在毕业典礼这人生中重要的时刻，感恩父母、感恩母校和老师，向他们深情地道一声：谢谢！\n',
          'newsImages':[],
          'newsIntroductionText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。',
          'newsCover':require('/src/assets/imgs/news/newsTitleBg.png'),
          'newsCreateDate':'03',
          'newsCreateMonth':'2023-07'
        },
        {
          'newsId':5,
          'newsTitle':'李绥校董在东华大学2023届学生毕业典礼上作为校友代表发言',
          'newsText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。\n' +
              '李绥校董在研究生毕业典礼上的发言原文:\n' +
              '尊敬的各位老师、亲爱的学弟学妹们：\n' +
              '大家好！非常高兴再次回到母校与各位老师、同学在此相聚，与大家一起分享此刻的喜悦、见证同学们的毕业典礼！\n' +
              '首先，祝贺各位同学顺利完成研究生学习、踏入人生新的旅程，我为你们感到骄傲！三十多年前，我和同学们一样，完成研究生阶段的学习。我的第一份工作是留校当老师、第二份工作是在外资企业做技术研发。\n' +
              '1996年，我离开外资企业创业，成立了“上海爱登堡电子有限公司”，在母校的支持下，在自动化系三位退休老师的帮助和参与下，研发、生产变频变压调速装置。那个时候，变频变压调速装置依赖进口、市场价格很高，我们生产销售的产品也有利润，3年后积累了一定的经济基础，就筹划买地建厂，想由生产电梯核心部件发展到生产整机的电梯公司，但当时很难取得整机生产许可证。\n' +
              '幸运的是，1999年，学校建高层教师公寓，需要三台电梯。我主动向母校表明了我的创业理想和当时事业发展遇到的困境，并承诺15年质保做一台样梯，感谢母校的信任，给了我制造样梯、尝试创新机会；从那以后，我经常带客户去参观、并与相邻的两台三菱电梯作性能比较，不仅以质量取胜，而且获得了不少新的订单，公司逐渐走上了整机生产和销售的快速发展之路。\n' +
              '今天，爱登堡集团已是拥有上海闵行、上海临港、德国三个研发中心和江苏、湖北、贵州三个生产基地的企业集团，业务涉及多个领域，产品除在中国销售外，也销往欧洲、中东及东南亚国家和地区。爱登堡被评为国家火炬计划重点高新技术企业、上海市知识产权示范企业、上海市名牌产品、上海市重点新产品、长三角四省一市100佳名牌企业、人工智能绿色电梯上海品牌等。饮水思源，我深知爱登堡这些发展成绩和荣誉的背后，离不开母校对我的培养和支持！\n' +
              '\n' +
              '回顾我的成长和企业发展历程，有五点感悟，想和同学们分享：\n' +
              '一、要有追求定力，才有成功的可能\n' +
              '我离开外资企业，在没有其它人投资的情况下去创业，企业从核心部件生产向整机生产发展，从租房到花费重金买地建厂，都验证了：一个人要有追求、要有强烈的成功欲望，才会果断、大胆抓住机会并为之全力以赴。\n' +
              '二、要有创新思维，才有创新的机会\n' +
              '二三十年前只要胆子大，企业就有发展。但今天，只有创新，企业才有发展；也只有创新，我们的工作和生活才有活力，才有更好的未来！\n' +
              '请问同学们“创新难吗？”实际上并不难！创新的源点在于创新思维，而创新思维来自：勤于观察、寻找痛点、追求增值。有了创新思维，你就会发现我们碰到的事物、所处的场所都存在着大量的可创新之处，也存在于与理想、与希望有差距的地方，这些差距正是我们工作或创业成功的良好机会。\n' +
              '三、既要有主见，又要听相反意见，稳健决策\n' +
              '我知道同学们研究生毕业都学有所成、学有所获，但个人能力强的人有时不想花太多时间听取别人的意见，特别是相反的意见。今天作为校友代表，我想建议同学们养成稳健决策的好习惯：一个决策（特别是重大关键性决策），务必用心争取或创造机会去反复听取反对者的意见，以论证不利情况发生时你是否可以承受，并在执行决策的过程中时刻预防不利情况的发生。我相信，在座的同学们将来都是各行各业的精英，这个稳健的决策习惯会帮助你们的事业稳步提升。\n' +
              '四、分享和感恩，善良和真诚就是人品\n' +
              '对于帮助过你的人，要心怀感恩之情，要及时分享你的每一点成功。别人接受或不接受你的分享或感恩那是别人的事，但你是否给予感恩、分享，那是你的人品。我们往住会忽视对朋友和亲人的分享和感恩，特别是父母、老师；我想告诉同学们：对父母\\老师或者曾受帮助而没有感恩之心的人，在社会上、特别是在一个优秀的企业内部是不受欢迎、也不会被重用的！\n' +
              '对于没有帮助过你的人也要保持一颗善良的心，要善解人意。在别人有任何需要的时候，请真诚地帮助别人，不求回报，这是美德，也是最能创建良好人缘的品德！\n' +
              '请同学们相信，人品和人缘是我们做成任何事情的长期资本。\n' +
              '五、以阳光快乐的心情工作和生活\n' +
              '我们是否常常因为受不公平对待或被人误会或没有达成心愿，而感到沮丧、闷闷不乐？刚工作时的一件事让我记忆犹新：有一年春节，单位发鱼和香蕉，办公室的同事将比较小的鱼和断裂的香蕉分给了我，而把大的鱼和好的香蕉留给自己和他的熟人。当时我不开心，但静下心来想：小的鱼和断的香蕉分给了其它员工，那个员工也会不开心，那个吃亏而不开心的人为什么不能是我呢？后来在工作中，我坚持吃亏在我，凡事从好处想、找快乐，同事们看我从不计较也都愿意帮我，在外资企业的第二年就当上研发部部长；特别是在自己创业后，我想方设法成就员工：专利、科技成果、科技进步奖都归功于员工，公司员工队伍中被评为高级工程师、首席技师的比例很高，普通员工落户上海的比例也很高。2023年，公司有位员工获得全国五一劳动奖章，并且获得全国最美职工称号（全国十位，上海唯一一位）。\n' +
              '同学们，在我们将来的工作和生活中，“不公平”是常态，将“不公平”认为公平是一种心态，更是智慧，很多时候“吃亏是福”，心情快乐是人生最大的财富！\n' +
              '有人说：只要有贵人相助，事业就顺利，生活就美好！我想说：世上没有无缘无故的爱，除了紧急情况，也没有无缘无故的相助。只要我们有追求、能创新、有主见、能分享、会感恩，并以阳光的心态对待工作和生活，我们一定会遇到贵人。\n' +
              '我还想说：父母是我们与生俱来的贵人，而母校和老师也是我们不能忘记的贵人。他们真正无私地奉献、热切期盼我们成长、支持我们成功，让我们在毕业典礼这人生中重要的时刻，感恩父母、感恩母校和老师，向他们深情地道一声：谢谢！\n',
          'newsImages':[],
          'newsIntroductionText':'6月27日，东华大学2023届学生毕业典礼暨学位授予仪式在松江大学城上海大学生体育中心冰球馆举行。李绥校董受邀参加本科生、研究生毕业典礼，并深情寄语毕业生。',
          'newsCover':require('/src/assets/imgs/news/addIcon.png'),
          'newsCreateDate':'03',
          'newsCreateMonth':'2023-07'
        },*/
      ],
      needNewsId:0
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
    this.isGoogle()
    this.startLoop()
  },
  methods: {
    isGoogle(){
      console.log(navigator.userAgent)
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Chrome') && userAgent.includes('Google')) {
        console.log('This browser is Google Chrome');
      } else {
        console.log('This browser is not Google Chrome');
      }
    },
    startLoop() {
      this.intervalId=setInterval(() => {
          this.needNewsId += 1
          if (this.needNewsId > this.news.length-1) {
            this.needNewsId = 0
          }
        }, 10000) // 每十秒执行一次
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      } else {
        return text;
      }
    },
    // eslint-disable-next-line no-unused-vars
    stayThisNews(index){
      clearInterval(this.intervalId)
      this.needNewsId=index
    },
    isOverflow() {
      const div = document.createElement('div');
      div.textContent = this.textContent;
      document.body.appendChild(div);
      const isOverflow = div.offsetWidth < div.scrollWidth;
      document.body.removeChild(div);
      return isOverflow;
    },
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
<div style="margin: 0;padding: 0">
  <div :style="{width:(this.width/2543)*1364+'px'}" style=" display: flex;flex-direction: column">
    <p :style="{fontSize:(this.width/2543)*40+'px',width:(this.width/2543)*1364+'px'}" style="text-align: center">
      <span v-show="this.chooseLanguage==='CHINESE'" class="partTitle">集团资讯</span>
      <span v-show="this.chooseLanguage!=='CHINESE'" class="partTitle">Group Information</span>
    </p>
    <div :style="{marginTop:(this.width/2543)*29+'px'}">
      <div :style="{width:(this.width/2543)*1364*0.5+'px'}" style="float: left">
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" style="font-family: '宋体';">热点新闻</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" style="font-family: '宋体';">hot news</span>
        </p>
        <div :style="{width:(this.width/2543)*648+'px'}" style="background-color: #f5f5f5">
          <el-image :src="this.news[this.needNewsId].newsCover" class="newsNeedCover" :style="{width:(this.width/2543)*602+'px',height:(this.width/2543)*452+'px',top:(this.width/2543)*26+'px',right:(this.width/2543)*26+'px',left:(this.width/2543)*26+'px'}" style="position: relative;"></el-image>
          <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*596+'px',marginTop: (this.width/2543)*56+'px',marginLeft: (this.width/2543)*26+'px',fontSize:(this.width/2543)*20+'px'}" style="color:#333333;">
            <span>{{this.news[this.needNewsId].newsTitle}}</span>
          </div>
          <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*596+'px',marginTop: (this.width/2543)*56+'px',marginLeft: (this.width/2543)*26+'px',fontSize:(this.width/2543)*20+'px'}" style="color:#333333;">
            <span>{{this.news[this.needNewsId].newsTitleEn}}</span>
          </div>
          <div :style="{width:(this.width/2543)*596+'px',marginTop: (this.width/2543)*26+'px',marginLeft: (this.width/2543)*26+'px',fontSize:(this.width/2543)*14+'px',lineHeight:(this.width/2543)*25+'px'}" style="color:#333333;">
            <span v-show="this.chooseLanguage==='CHINESE'">{{this.news[this.needNewsId].newsIntroductionText}}</span>
            <span v-show="this.chooseLanguage!=='CHINESE'">{{this.news[this.needNewsId].newsIntroductionTextEn}}</span>
          </div>
          <div :style="{marginTop:(this.width/2543)*22+'px',width:(this.width/2543)*596+'px',height:(this.width/2543)*74+'px',marginLeft: (this.width/2543)*26+'px'}">
            <div  class="addIcon" :style="{width:(this.width/2543)*31+'px',height:(this.width/2543)*31+'px'}" style="float: right">
            </div>
          </div>
        </div>
      </div>
      <div :style="{width:(this.width/2543)*1364*0.45+'px'}" style="float: left">
        <span>
          <p>
            <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" style="font-family: '宋体';">最新动态</span>
            <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" style="font-family: '宋体';">latest news</span>
          </p>
          <div v-show="this.chooseLanguage!=='CHINESE'&&item.newsId!==1" :style="{width:(this.width/2543)*1364*0.45+'px',height:(this.width/2543)*81+'px',marginBottom:(this.width/2543)*30+'px'}" style="border-bottom:1px solid #e5e5e5;position: relative;cursor: pointer;" v-for="(item,index) in this.news" :key="index" @mouseover="stayThisNews(index)" @mouseleave="startLoop" @click="$router.push({name:'newsText',query:{param1:item.newsId}})">
            <div :style="{height:(this.width/2543)*63+'px',width:(this.width/2543)*1364*0.45+'px'}" style="position: absolute;bottom: 0">
              <div style="float: left">
              <div :style="{width:(this.width/2543)*80+'px',height:(this.width/2543)*43+'px',fontSize:(this.width/2543)*23.24+'px',backgroundColor:item.newsId===this.needNewsId?'#ec1326':'#676767'}" style="display: flex;align-items: center;justify-content: center;font-weight: bolder;font-family: 'Regular';color: #ffffff">
                <span v-show="this.chooseLanguage==='CHINESE'">{{item.newsCreateDate}}</span>
                <span v-show="this.chooseLanguage!=='CHINESE'">{{item.newsCreateDateEn}}</span>
              </div>
              <div :style="{width:(this.width/2543)*80+'px',height:(this.width/2543)*22+'px',fontSize:12+'px',backgroundColor:item.newsId===this.needNewsId?'#d80c18':'#515151'}" style="display: flex;align-items: center;justify-content: center;font-weight: bolder;color: #ffffff">
                <span v-show="this.chooseLanguage==='CHINESE'" class="textWords" :style="{transform: 'scale(' + this.width/2543 + ')' }">{{item.newsCreateMonth}}</span>
                <span v-show="this.chooseLanguage!=='CHINESE'" class="textWords" :style="{transform: 'scale(' + this.width/2543 + ')' }">{{item.newsCreateMonthEn}}</span>
              </div>
              </div>
              <div v-show="this.chooseLanguage==='CHINESE'" style="float: left">
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*16+'px',color:item.newsId===this.needNewsId?'#d80c18':'#333333',textDecoration: item.newsId===this.needNewsId?'underline':'none'}"><span>{{item.newsTitle}}</span></div>
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*12+'px',width:(this.width/2543)*1364*0.45-100+'px',}"><p :class="{'ellipsis': isOverflow}">{{item.newsText}}</p></div>
              </div>
              <div v-show="this.chooseLanguage!=='CHINESE'" style="float: left">
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*16+'px',color:item.newsId===this.needNewsId?'#d80c18':'#333333',textDecoration: item.newsId===this.needNewsId?'underline':'none'}"><p :class="{'ellipsis': isOverflow}">{{item.newsTitleEn}}</p></div>
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*12+'px',width:(this.width/2543)*1364*0.45-100+'px',}"><p :class="{'ellipsis': isOverflow}">{{item.newsTextEn}}</p></div>
              </div>
            </div>
          </div>
          <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1364*0.45+'px',height:(this.width/2543)*81+'px',marginBottom:(this.width/2543)*30+'px'}" style="border-bottom:1px solid #e5e5e5;position: relative;cursor: pointer;" v-for="(item,index) in this.news" :key="index" @mouseover="stayThisNews(index)" @mouseleave="startLoop" @click="$router.push({name:'newsText',query:{param1:item.newsId}})">
            <div :style="{height:(this.width/2543)*63+'px',width:(this.width/2543)*1364*0.45+'px'}" style="position: absolute;bottom: 0">
              <div style="float: left">
              <div :style="{width:(this.width/2543)*80+'px',height:(this.width/2543)*43+'px',fontSize:(this.width/2543)*23.24+'px',backgroundColor:item.newsId===this.needNewsId?'#ec1326':'#676767'}" style="display: flex;align-items: center;justify-content: center;font-weight: bolder;font-family: 'Regular';color: #ffffff">
                <span v-show="this.chooseLanguage==='CHINESE'">{{item.newsCreateDate}}</span>
                <span v-show="this.chooseLanguage!=='CHINESE'">{{item.newsCreateDateEn}}</span>
              </div>
              <div :style="{width:(this.width/2543)*80+'px',height:(this.width/2543)*22+'px',fontSize:12+'px',backgroundColor:item.newsId===this.needNewsId?'#d80c18':'#515151'}" style="display: flex;align-items: center;justify-content: center;font-weight: bolder;color: #ffffff">
                <span v-show="this.chooseLanguage==='CHINESE'" class="textWords" :style="{transform: 'scale(' + this.width/2543 + ')' }">{{item.newsCreateMonth}}</span>
                <span v-show="this.chooseLanguage!=='CHINESE'" class="textWords" :style="{transform: 'scale(' + this.width/2543 + ')' }">{{item.newsCreateMonthEn}}</span>
              </div>
              </div>
              <div v-show="this.chooseLanguage==='CHINESE'" style="float: left">
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*16+'px',color:item.newsId===this.needNewsId?'#d80c18':'#333333',textDecoration: item.newsId===this.needNewsId?'underline':'none'}"><span>{{item.newsTitle}}</span></div>
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*12+'px',width:(this.width/2543)*1364*0.45-100+'px',}"><p :class="{'ellipsis': isOverflow}">{{item.newsText}}</p></div>
              </div>
              <div v-show="this.chooseLanguage!=='CHINESE'" style="float: left">
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*16+'px',color:item.newsId===this.needNewsId?'#d80c18':'#333333',textDecoration: item.newsId===this.needNewsId?'underline':'none'}"><p :class="{'ellipsis': isOverflow}">{{item.newsTitleEn}}</p></div>
                <div :style="{marginLeft:(this.width/2543)*12+'px',fontSize:(this.width/2543)*12+'px',width:(this.width/2543)*1364*0.45-100+'px',}"><p :class="{'ellipsis': isOverflow}">{{item.newsTextEn}}</p></div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div :style="{width:(this.width/2543)*1364+'px'}" style="display: flex;justify-content: center;">
      <div :style="{marginTop:(this.width/2543)*50+'px',width:(this.width/2543)*259+'px',height:(this.width/2543)*40+'px'}" style="background-color: #e60012;align-items: center;justify-content: center;display: flex;cursor: pointer;" @click="$router.push('/informationNews')">
        <span :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white;font-family: '华文细黑';display: flex;justify-content: center;">Learn more +</span>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.textWords{

}
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.addIcon{
  background-image: url('/src/assets/imgs/news/addIcon.png');
  background-size: 100% 100%
}
.newsNeedCover{
  background-size: 100% 100%
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}
</style>
