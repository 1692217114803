<template>
<span :endTime="endTime" :endText="endText" :auctionId="auctionId" :style="{color: this.colorChange,fontWeight: this.weightFont,fontSize:this.sizeFont}">
  <slot>{{content}}</slot>
	</span>
</template>

<script>



export default {
  name: "countDown",
  data(){
    return {
      time:'',
      content: '',
      colorChange:'black',
      weightFont:'500',
      sizeFont:'calc(100vw * 20 / 1920)'
    }
  },
  props:{//接收父组件的数据
    endTime:{type:String,default:''},
    endText:{type:String,default:''},
    auctionId:{type:String,default:''}
  },
  watch: {//监听时间的变化
    endTime() {
      this.countDown(this.endTime)
    }
  },
  mounted () {
    this.countDown(this.endTime)
  },
  methods: {
    countDown(timestamp){
      let self = this;
      let timer = setInterval(function(){
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if(t>0){
          let day = Math.floor(t/86400000);
          let hour=Math.floor((t/3600000)%24);
          let min=Math.floor((t/60000)%60);
          let sec=Math.floor((t/1000)%60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = '';
          if(day > 0){
            format = `${day}天${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour > 0 ){
            format = `${hour}小时${min}分${sec}秒`;
          }
          if(day <= 0 && hour <= 0){
            format =`${min}分${sec}秒`;
          }
          if(day <= 0 && hour <= 0 && min<=10){
            self.colorChange='red'
            self.weightFont=900
            self.sizeFont='calc(100vw * 25 / 1920)'
            format =`当前竞价将在`+`${min}分${sec}秒`+`后结束，请尽快竞价！！`;
          }
          self.content = format;
        }else{
          clearInterval(timer);
          self.content = self.endText;
          /*postRequestByTokenDownLoad("/auction/stopAuction",self.auctionId.toString());*/
        }
      },1000);
    }
  }
}
</script>

<style scoped>

</style>
