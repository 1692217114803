/*注册*/
import axios from "axios";
import {ElMessage} from "element-plus";

axios.interceptors.response.use(success=>{
        if(success.status&&success.status==200&&success.data.code==300){
            ElMessage.error({message:success.data.msg})
            return;
        }
        if(success.data.msg){
            ElMessage.success({message:success.data.msg})
        }
        return success;
    },
    error => {
        if(error.status==504 || error.status==404){
            ElMessage.error({message:'服务器被吃了'})
        } else if(error.status == 403){
            ElMessage.error({message:'权限不足'})
        } else if(error.status == 401){
            ElMessage.error({message:'还未登录，请登陆'})
        } else {
            if(error.data.msg){
                ElMessage.error({message:error.data.msg})
            } else {
                ElMessage.error({message:'未知错误！'})
            }
        }
        return;
    });
/*封装请求*/
let base='/api';
/*let base='http://localhost:8082'*/
//登录的请求(默认只支持KeyValue形式的传参)
export const postKeyValueRequest=(url,params)=>{
    return axios({
        method:"post",
        //此处为` 而不是单引号'
        url:`${base}${url}`,
        data:params,
        //自定义一个方法，将返回的内容转换为key-value的键值对形式
        /*transformRequest:[function (data){
            let ret='';
            for(let i in data){
                ret+=encodeURIComponent(i)+'='+encodeURIComponent(data[i])+'&'
            }
            return ret;
        }],*/
        headers:{
            'Content-Type':'application/json;charset=utf-8',
        }
    });
}

//封装传递json的post请求
export const postRequestByToken=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params,
        headers:{
            'satoken': window.sessionStorage.getItem("userSatokenValue").toString()
        }
    })
}
export const postRequestWithoutToken=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params,
    })
}
//封装传递json的put请求
export const putRequest=(url,params)=>{
    return axios({
        method:'put',
        url:`${base}${url}`,
        data:params
    })
}
export const postRequestByFile=(url,params)=>{
    return axios({
        method:'post',
        responseType: 'blob',
        url:`${base}${url}`,
        data:params
    })
}
export const postRequestByVideo=(url,params)=>{
    return axios({
        method:'post',
        responseType: 'blob',
        url:`${base}${url}`,
        data:params
    })
}
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data:params
    })
}
//封装传递json的get请求
export const getRequestByToken=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data:params,
        headers:{
            "satoken": window.sessionStorage.getItem("userSatokenValue").toString()
        }
    })
}
export const postRequestByTokenDownLoad=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params,
        headers:{
            "satoken": window.sessionStorage.getItem("userSatokenValue").toString()
        },
        responseType:'blob'
    })
}
//封装传递json的delete请求
export const deleteRequest=(url,params)=>{
    return axios({
        method:'delete',
        url:`${base}${url}`,
        data:params
    })
}
