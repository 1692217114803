<script>
import {Phone} from "@element-plus/icons-vue";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'aboutUs',
    data(){
        return{
            isShowTels:false
        }

    },
    components: {Phone},
    methods:{
        // 判断phone端还是pc端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        showTel(){
            this.isShowTels=true;
        },
    }
}
</script>

<template>
    <div>
      <el-menu
          class="topNavBar"
          mode="horizontal"
          :ellipsis="false"
      >
        <el-menu-item index="0" @click="$router.push('/')">
          <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
        </el-menu-item>
        <div class="flex-grow" />
        <el-select
            v-model="chooseLanguage"
            @change="chooseCNorEN"
            class="m-2"
            placeholder="中/EN"
            size="large"
            :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
        >
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
        <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
        <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
        <el-sub-menu index="2" @click="$router.push('/technology/safe')">
          <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
          <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
          <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
          <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="3" @click="$router.push('/product')">
          <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
          <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
          <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
          <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
          <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
          <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
          <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
          <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
        <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
        <div class="flex-grow" />
        <el-menu-item index="5" style="display: flex;" disabled>
          <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
          <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
        </el-menu-item>

      </el-menu>
      <el-affix :offset="120">
          <div :class="this._isMobile()==null?'floatWindow':'floatWindowMobile'">
              <div class="telShow" @click="this.isShowTels=true" @mouseenter="this.showTel">
                  <el-icon><Phone style="color: white" /></el-icon>
              </div>
              <div :class="this._isMobile()==null?'telShowWords':'telShowWordsMobile'" @mouseleave="this.isShowTels=false" v-show="isShowTels">
                <h1 style="color: #fcfafa;">总机：</h1>
                <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">021-33884999</p>
                <h1 style="color: #fcfafa;">免费服务热线：</h1>
                <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">400 889 0388</p>
                <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">800 988 0388</p>
              </div>
          </div>
      </el-affix>
      <div>
          <el-image :src="require('/src/assets/imgs/aboutUs/1.png')" />
<!--            <router-view />-->
      </div>
      <div class="titleEight">
          <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.floatWindow {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vw * 50 / 1920);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.floatWindowMobile{
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vh/10);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.telShow {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: calc(100vh/18);
  width: calc(100vh/18);
  font-size: calc(100vh/18);
  float: left;
  .icon {
    display: flex;
  }
}
.telShowWords{
  float: left;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: auto;
  width: calc(100vh/2);
}
.telShowWordsMobile{
  float: left;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: auto;
  width: calc(100vh/2);
}
.telWords{
  color: #f80606;
  font-size: calc(100vw/90)
}
.telWordsMobile{
  color: #fcf8f8;
  font-size: calc(100vh/60)
}
/deep/ .el-menu{
  background-color: rgba(0,0,0,0.1);
  color: white;
  font-family: '华文细黑';
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
</style>
