import {createRouter, createWebHistory} from "vue-router"
import MyHome from "@/view/auction/myHome.vue";
import myAuction from "@/view/auction/myAuction.vue";
import myAuctionMobile from "@/view/auction/myAuctionMobile.vue";
import VillaLadder from "@/view/products/elevator/villaLadder.vue";
import products from "@/view/products/products.vue";
import projectPresentation from "@/view/projectPresentation/projectPresentation.vue";
import retrofittingOfOldBuildings from "@/view/products/retrofittingOfOldBuildings/RetrofittingOfOldBuildings.vue";
import QualificationCertificate from "@/view/products/qualificationCertificate/qualificationCertificate.vue";
import AboutUs from "@/view/aboutUs/aboutUs.vue";
import newTo from "@/view/newTo.vue";
import technology from "@/view/introduction/coreTechnology/technology.vue";
import companyIntroduction from "@/view/introduction/CompanyIntroduction.vue";
import safe from "@/components/about/safe.vue";
import energySaving from "@/components/about/energySaving.vue";
import intelligence from "@/components/about/intelligence.vue";
import pdfView from "@/view/introduction/pdfView.vue";
import news from "@/view/news/news.vue";
import hotNews from "@/components/news/hotNews.vue";
import informationNews from "@/components/news/informationNews.vue";
import NewsText from "@/components/news/newsText.vue";
import IndustrialPark from "@/view/industrialPark/industrialPark.vue";
import addNews from "@/view/news/addNews.vue";
import escalator from "@/view/products/escalator/Escalator.vue";
import inductionTraining from "@/view/inductionTraining/inductionTraining.vue";
import InductionTrainingLogin from "@/view/inductionTraining/inductionTrainingLogin.vue";
import garage from "@/view/products/garage/Garage.vue";
import educationAndTraining from "@/view/educationAndTraining/educationAndTraining.vue";
import adbWebHome from "@/view/adbWebHome.vue";
import uploadWeb from "@/view/UploadWeb.vue";


const routes=[
    {
        path:'/',
        name:'首页',
        component:adbWebHome
    },
    {
        path:'/projectPresentation',
        name:'项目展示',
        component: projectPresentation
    },
    {
        path: '/technology',
        name:'核心技术',
        component: technology,
        children: [
            {
                path: '/technology/safe',
                component: safe
            },
            {
                path: '/technology/energySaving',
                component: energySaving
            },
            {
                path: '/technology/intelligence',
                component: intelligence
            },
        ]
    },
    {
      path:'/toProduct',
        name:'产品',
        component: newTo
    },
    {
        path: '/aboutUs',
        name:'关于我们',
        component: AboutUs
    },
    {
        path:'/product',
        name:'产品展示',
        component: products,
    },
    {
        path:'/industrialPark',
        name:'产业园',
        component: IndustrialPark
    },
    {
        path:'/villaLadder',
        name:'别墅梯',
        component: VillaLadder
    },
    {
        path:'/retrofittingOfOldBuildings',
        name:'旧楼加装',
        component: retrofittingOfOldBuildings
    },
    {
        path:'/qualificationCertificate',
        name: '资质认证',
        component: QualificationCertificate
    },
    {
        path: '/auction',
        component: MyHome,
    },
    {
        path:'/auctionShow',
        component: myAuction
    },
    {
        path:'/auctionShowMobile',
        component: myAuctionMobile
    },
    {
        path: '/companyIntroduction',
        name:'公司介绍',
        component: companyIntroduction
    },
    {
        path:'/news',
        name:'新闻',
        component: news,
        children: [
            {
                path: '/hotNews',
                component: hotNews
            },
            {
                path:'/informationNews',
                component: informationNews
            },
            {
                path:'/newsText',
                name:'newsText',
                component: NewsText,
                // 接受查询参数
                props: (route) => ({ query: route.query })
            }
        ]
    },
    {
        path:'/pdfView',
        name:'预览pdf',
        component: pdfView
    },
    {
        path:'/testNews',
        component: addNews
    },
    {
        path:'/escalator',
        component: escalator
    }
    ,
    {
        path:'/garage',
        component: garage
    },
    {
        path:'/inductionTraining',
        component: inductionTraining
    },
    {
        path:'/inductionTrainingLogin',
        component: InductionTrainingLogin
    },
    {
        path:'/educationAndTraining',
        component: educationAndTraining
    },
    {
        path:'/uploadWeb',
        component: uploadWeb
    },
    ]
// 3. 创建路由实例
const router = createRouter({
    // 4. 采用hash 模式
    //history: createWebHashHistory(),
    // 采用 history 模式
    history: createWebHistory(),
    routes, //使用上方定义的路由配置
});

//要添加的代码
router.beforeEach((to, from, next) => {
    if(to==='/villaLadder'){
        // chrome
        document.body.scrollTop = 0
        // firefox
        document.documentElement.scrollTop = 0
        // safari
        window.pageYOffset = 0
    }
    next()
})
export default router
