<script>
// eslint-disable-next-line no-unused-vars
import {ElMessage} from "element-plus";
// eslint-disable-next-line no-unused-vars
import {getRequest, postRequest, postRequestByFile} from "@/utils/api";

export default {
  name:'inductionTraining',
  inject:['reload'],
  data(){
    return{
      pictureViewShow:false,
      videoViewShow:false,
      pdfViewShow:false,
      inductionTrainData:[],
      imageSrc:'',
      videoUrl: '',
    }
  },
  mounted() {
    this.getInductionTrainingTable();
  },
  methods:{
    getInductionTrainingTable(){
      getRequest("/inductionTraining/getInductionTraining")
          .then(resp=>{
            console.log(resp.data.data)
            this.inductionTrainData=resp.data.data
          })
    },
    viewInductionTrain(row){
      this.pictureViewShow=true
      postRequestByFile("/inductionTraining/getFiles", this.inductionTrainData[row])
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            this.imageSrc = url;
            /*const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.inductionTrainData[row].inductionTrainingFileName);
            document.body.appendChild(link);
            link.click();*/
          })
          .catch((error) => {
            console.log(error);
          });
    },
    viewInductionTrainVideo(row){
      this.videoViewShow=true
      let filename=this.inductionTrainData[row].inductionTrainingFileUUID
      this.videoUrl = `http://localhost:8082/inductionTraining/video/${filename}`
      this.$refs.videoPlayer.load()
    },
    viewInductionTrainPDF(row){
      this.pdfViewShow=true
      let filename=this.inductionTrainData[row].inductionTrainingFileUUID
      this.pdfUrl = `http://localhost:8082/inductionTraining/pdf/${filename}`
      this.$refs.pdfViewer.src = this.pdfUrl
    },
    // eslint-disable-next-line no-unused-vars
    handleSuccess(response, file, fileList) {
      this.$message.success('文件上传成功');
      this.$refs.upload.clearFiles();
    },
    // eslint-disable-next-line no-unused-vars
    handleError(err, file, fileList) {
      this.$message.error('文件上传失败');
    },
    beforeUpload(file) {
      /*const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';*/
      const isLt50GB = file.size / 1024 < 500000000;
      /*if (!isJPG) {
        this.$message.error('上传文件只能是 JPG 或 PNG 格式!');
      }*/
      if (!isLt50GB) {
        this.$message.error('上传文件大小不能超过 50GB!');
      }
      return isLt50GB;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    playVideo() {
      this.$refs.videoPlayer.play();
    }
  }

}
</script>

<template>
<div>
  <el-upload
      ref="upload"
      action="http://localhost:8082/inductionTraining/upload"
      :file-list="fileList"
      :auto-upload="false"
      multiple
      :on-success="this.handleSuccess"
      :on-error="this.handleError"
      :before-upload="this.beforeUpload"
  >
    <el-button size="small" type="primary">选取文件</el-button>
    <div class="el-upload__tip">上传文件不超过50G大小</div>
  </el-upload>
  <el-button type="success" @click="this.submitUpload">开始上传</el-button>
  <el-table :data="this.inductionTrainData" style="width: 100%">
    <el-table-column fixed prop="inductionTrainingFileName" label="文件名称" width="150" />
    <el-table-column prop="inductionTrainingFileUUID" label="文件UUID" width="300" />
    <el-table-column prop="inductionTrainingFilePath" label="文件地址" width="600" />
    <el-table-column prop="inductionTrainingFileType" label="文件类型" width="300" />
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default="scope">
        <el-button v-show="this.inductionTrainData[scope.$index].inductionTrainingFileType==='image/png'||this.inductionTrainData[scope.$index].inductionTrainingFileType==='image/jpeg'" link type="primary" size="small" @click="viewInductionTrain(scope.$index)"
        >查看图片</el-button>
        <el-button v-show="this.inductionTrainData[scope.$index].inductionTrainingFileType==='video/mp4'" link type="primary" size="small" @click="vzxiewInductionTrainVideo(scope.$index)"
        >查看视频</el-button>
        <el-button v-show="this.inductionTrainData[scope.$index].inductionTrainingFileType==='application/pdf'" link type="primary" size="small" @click="viewInductionTrainPDF(scope.$index)"
        >查看PDF文档</el-button>
        <el-button link type="primary" size="small">Edit</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog v-model="this.pictureViewShow" title="查看图片">
    <img :src="this.imageSrc" style="width: 100%;height: 100%" />
  </el-dialog>
  <el-dialog v-model="this.videoViewShow" title="查看视频" class="video-container">
    <video ref="videoPlayer" controls>
      <source :src="videoUrl" type="video/mp4" />
    </video>
  </el-dialog>
  <el-dialog v-model="this.pdfViewShow" title="查看文档">
    <iframe ref="pdfViewer" width="600" height="800"></iframe>
  </el-dialog>
</div>
</template>

<style scoped lang="scss">
.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
