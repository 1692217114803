<script>
import axios from "axios";

export default {
  name:'UploadWeb',
  data() {
    return {
      uploadUrl: '',
      folders:[
        {
          needFolder: '一中心/胡怀华/',
          label: '胡怀华',
        },
        {
          needFolder: '一中心/杨海飞/',
          label: '杨海飞',
        },
        {
          needFolder: '一中心/周龙全/',
          label: '周龙全',
        },
        {
          needFolder: '二中心/付家家/',
          label: '付家家',
        },
        {
          needFolder: '二中心/韩茜/',
          label: '韩茜',
        },
        {
          needFolder: '二中心/刘宜蓉/',
          label: '刘宜蓉',
        },
        {
          needFolder: '二中心/许飞燕/',
          label: '许飞燕',
        },
        {
          needFolder: '三中心/赵永青/',
          label: '赵永青',
        },
      ],
      uploadProgress: {},
    };
  },
  computed: {
    computedUploadUrl() {
      return `/api/uploadFileApplication`;
    }
  },
  methods: {
    customHttpRequest({
                        file,
                        onProgress,
                        onError,
                        onSuccess
                      }) {
      const formData = new FormData();
      const uploadUrl=this.folders.needFolder
      console.log(uploadUrl)
      formData.append("getFiles", file);
      formData.append("uploadUrl",uploadUrl)
      axios.post(`/api/uploadFileApplication`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (event) => {
          const progress = Math.round((event.loaded * 100) / event.total);
          this.set(this.uploadProgress, file.name, progress);
          onProgress(event);
        }
      }).then(response => {
        onSuccess(response.data);
      }).catch(onError);
    },
    beforeUpload(file) {
      const isLt500M = file.size / 1024 / 1024 < 500;
      if (!isLt500M) {
        this.$message.error('上传文件大小不能超过 500MB');
        return false;
      }
      return true;
    },
    // eslint-disable-next-line no-unused-vars
    handleProgress(event, file, fileList) {
      console.log('Progress:', event, file.name);
    },
    // eslint-disable-next-line no-unused-vars
    handleSuccess(response, file, fileList) {
      this.$message.success(`${file.name} 上传成功`);
      this.uploadProgress[file.name] = 100; // Ensure progress is set to 100 on success
    },
    // eslint-disable-next-line no-unused-vars
    handleError(err, file, fileList) {
      this.$message.error(`${file.name} 上传失败`);
      this.uploadProgress[file.name] = 0; // Reset progress on error
    },
    submitUpload() {
      this.$refs.upload.submit();
    }
  }
};
</script>

<template>
  <div>
    <el-form>
      <el-form-item label="选择上传文件夹">
        <el-select
            v-model="this.folders.needFolder"
            placeholder="Select"
            size="large"
            style="width: 240px"
        >
          <el-option
              v-for="item in folders"
              :key="item.needFolder"
              :label="item.label"
              :value="item.needFolder"
          />
        </el-select>
      </el-form-item>
      <el-upload
          class="upload-demo"
          ref="upload"
          :action="computedUploadUrl"
          :http-request="customHttpRequest"
          :before-upload="beforeUpload"
          multiple
          :on-progress="handleProgress"
          :on-success="handleSuccess"
          :on-error="handleError">
        <el-button size="small" type="primary">选择文件</el-button>
        <el-button size="small" type="success" @click="submitUpload">上传文件</el-button>
        <div class="el-upload__tip">单个文件不超过 500MB</div>
      </el-upload>
    </el-form>
    <div v-for="(progress, name) in uploadProgress" :key="name">
      {{ name }}: {{ progress }}%
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
