<script>
export default {
  name:'industrialPark',
  inject:['reload'],
  data(){
    return{
      width:document.body.clientWidth,
      chooseCyyIndex:0,
      cyyFourth:[
        {'cyyFourthImg':require('/src/assets/imgs/industrialPark/cyyFourthOne.png'),
          'cyyFourthWords':'一区：恒西路139号',
          'cyyFourthWordsEn':'Zone 1: No. 139 Hengxi Road',
          'cyyFourthIcon':require('/src/assets/imgs/industrialPark/cyyFourthIcon.png'),
          'cyyFourthFirstPart':require('/src/assets/imgs/industrialPark/cyyFourthFirstPartOfOne.png'),
          'cyyFourthSecondPartOfTitle':'产业园配套设施',
          'cyyFourthSecondPartOfTitleEn':'Supporting facilities of the industrial park',
          'cyyFourthSecondPartOfImg':require('/src/assets/imgs/industrialPark/cyyFourthSecondPartImgsOfOne.png'),
        },
        {'cyyFourthImg':require('/src/assets/imgs/industrialPark/cyyFourthTwo.png'),
          'cyyFourthWords':'二区：浦星公路1601号',
          'cyyFourthWordsEn':'Zone 2: No. 1601 Puxing Road',
          'cyyFourthIcon':require('/src/assets/imgs/industrialPark/cyyFourthIcon.png'),
          'cyyFourthFirstPart':require('/src/assets/imgs/industrialPark/cyyFourthFirstPartOfTwo.png'),
          'cyyFourthSecondPartOfTitle':'SPACES联合办公区',
          'cyyFourthSecondPartOfTitleEn':'SPACES Joint Office Area',
          'cyyFourthSecondPartOfImg':require('/src/assets/imgs/industrialPark/cyyFourthSecondPartImgsOfTwo.png'),
        },
        {'cyyFourthImg':require('/src/assets/imgs/industrialPark/cyyFourthThree.png'),
          'cyyFourthWords':'三区：恒西路88号',
          'cyyFourthWordsEn':'Zone 3: No. 88 Hengxi Road',
          'cyyFourthIcon':require('/src/assets/imgs/industrialPark/cyyFourthIcon.png'),
          'cyyFourthFirstPart':require('/src/assets/imgs/industrialPark/cyyFourthFirstPartOfThree.png'),
          'cyyFourthSecondPartOfTitle':'园区效果图',
          'cyyFourthSecondPartOfTitleEn':'Park rendering',
          'cyyFourthSecondPartOfImg':require('/src/assets/imgs/industrialPark/cyyFourthSecondPartImgsOfThree.png'),
        }
      ],
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    };
  },
  methods:{
    chooseCyy(index){
      this.chooseCyyIndex=index
    },
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
<div style="padding: 0;margin: 0">
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div :style="{width:(this.width/2543)*2543+'px'}">
<!--    标头-->
    <div :style="{width:(this.width/2543)*2543+'px',height:(this.width/2543)*121+'px',marginTop:(this.width/2543)*26+'px'}" class="cyyFirst">
      <div :style="{width:(this.width/2543)*1364+'px',}">
        <div :style="{width:(this.width/2543)*574+'px',height:(this.width/2543)*89+'px'}" @click="$router.push('/')" style="cursor: pointer;"></div>
      </div>
    </div>
    <div :style="{width:(this.width/2543)*2543+'px',height:(this.width/2543)*572+'px',marginTop:(this.width/2543)*26+'px'}" class="cyySecond"></div>
    <div :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*26+'px'}" style="display: flex;justify-content: center;align-items: center;">
      <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*495+'px'}" style="position: relative;">
        <div :style="{width:(this.width/2543)*682+'px',height:(this.width/2543)*495+'px'}" style="float: left; display: flex;justify-content: center;align-items: center;">
          <div :style="{width:(this.width/2543)*600+'px',height:(this.width/2543)*400+'px'}">
            <p :style="{fontSize:(this.width/2543)*40+'px'}" style="font-family: '宋体';font-weight: bold;">
              <span v-show="this.chooseLanguage==='CHINESE'">爱登堡科技产业园</span>
              <span v-show="this.chooseLanguage!=='CHINESE'">Edunburgh Technology Industrial Park</span>
            </p>
            <span v-show="this.chooseLanguage==='CHINESE'">
              <span :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.5"><span style="color: #e60013;">爱登堡科技产业园区</span>坐落于上海市闵行区浦江镇，建筑面积近10万平方米，拥有大批公共绿地，环境优雅。拥有“上海市质量监督检验技术研究院”、“上海市软件技术创新服务平台”“CANS认证的测试中心”等技术平台。<br/>
            同时园区获评<span style="color: #e60013">上海市科技企业孵化器、上海市科技创业苗圃、大浦江创客服务联盟、紫竹创业办公室等</span>称号。上海爱登堡电梯集团股份有限公司作为总部在闵行的企业也坐落于此，作为全国的研发销售培训中心，获得“闵行区最具社会责任企业”、“闵行区文明单位”的等荣誉称号。</span>
            </span>
            <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*14+'px'}">
              <span style="color: #e60013;">The Edunburgh Technology Industrial Park </span>is located in Pujiang Town, Minhang District, Shanghai, with a construction area of nearly 100000 square meters and a large number of public green spaces, providing an elegant environment.We have technical platforms such as "Shanghai Quality Supervision and Inspection Technology Research Institute", "Shanghai Software Technology Innovation Service Platform", and "CANS Certified Testing Center".<br/>
              At the same time, the park has been awarded titles <span style="color: #e60013">such as Shanghai Science and Technology Enterprise Incubator, Shanghai Science and Technology Entrepreneurship Nursery, Dapu River Maker Service Alliance, and Zizhu Entrepreneurship Office.</span>Shanghai Edunburgh Elevator Group Co., Ltd as a company headquartered in Minhang, is also located here,As a national research and development sales training center, we have been awarded honorary titles such as "Minhang District Most Socially Responsible Enterprise" and "Minhang District Civilized Unit".
            </p>
          </div>
        </div>
        <div :style="{width:(this.width/2543)*682+'px',height:(this.width/2543)*495+'px'}" style="float: left;right: 0;position: absolute;" class="cyyThird"></div>
      </div>
    </div>
    <div>
      <div v-for="(item,index) in this.cyyFourth" :key="index">
        <div style="float: left;cursor: pointer;" @mouseup="chooseCyy(index)" >
          <div :style="{width:(this.width/2543)*2535*(1/3)+'px',height:(this.width/2543)*1027+'px',backgroundImage:'url('+item.cyyFourthImg+')',marginRight:(index+1)!=3?(this.width/2543)*2+'px':0,opacity:this.chooseCyyIndex===index?1:0.8}" style="background-size: 100% 100%"></div>
          <div :style="{width:(this.width/2543)*2535*(1/3)+'px',height:(this.width/2543)*146+'px',marginRight:(index+1)!=3?(this.width/2543)*2+'px':0,backgroundColor:this.chooseCyyIndex===index?'#e60013':'#ac000e'}" style="float: left;display: flex;justify-content: center;align-items: center;">
            <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*45.18 +'px'}" style="color: white;font-weight: bolder;float: left">{{item.cyyFourthWords}}</span>
            <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*45.18 +'px'}" style="color: white;font-weight: bolder;float: left">{{item.cyyFourthWordsEn}}</span>
            <div :style="{width:(this.width/2543)*45+'px',height:(this.width/2543)*45+'px',marginLeft:(this.width/2543)*20+'px',backgroundImage:'url('+item.cyyFourthIcon+')'}" style="background-size: 100% 100%"></div>
          </div>
        </div>
      </div>
      <div>
        <div :style="{width:(this.width/2543)*2539+'px',height:(this.width/2543)*776+'px',backgroundImage:'url('+cyyFourth[this.chooseCyyIndex].cyyFourthFirstPart+')'}" style="background-size: 100% 100%;float: left"></div>
        <div :style="{width:(this.width/2543)*2539+'px',height:(this.width/2543)*111+'px',}" style="background-color:#eeeeee;float: left;display: flex;justify-content: center;align-items: center;">
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder">{{cyyFourth[this.chooseCyyIndex].cyyFourthSecondPartOfTitle}}</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*40+'px'}" style="font-weight: bolder">{{cyyFourth[this.chooseCyyIndex].cyyFourthSecondPartOfTitleEn}}</span>
        </div>
        <div :style="{width:(this.width/2543)*2539+'px',height:(this.width/2543)*355+'px',backgroundImage:'url('+cyyFourth[this.chooseCyyIndex].cyyFourthSecondPartOfImg+')'}" style="background-size: 100% 100%;float: left"></div>
        <div :style="{width:(this.width/2543)*2539+'px',height:(this.width/2543)*315+'px'}" style="background-size: 100% 100%;float: left" class="cyyFifth"></div>
        <div :style="{width:(this.width/2543)*2539+'px',height:(this.width/2543)*190+'px'}" style="float: left;bottom:0;height: auto;text-align: right;color: white;background-color: #0c0c0c;">
          <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.flex-grow{
  flex-grow: 0.5;
}
.cyyFirst{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assets/imgs/industrialPark/cyyFirst.png");
  background-size: 100% 100%;
}
.cyySecond{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assets/imgs/industrialPark/cyySecond.png");
  background-size: 100% 100%;
}
.cyyThird{
  background-image: url("/src/assets/imgs/industrialPark/cyyThird.png");
  background-size: 100% 100%;

}
.cyyFourth{
opacity: 0.2;
}
.cyyFifth{
  background-image: url("/src/assets/imgs/industrialPark/cyyFifth.png");
  background-size: 100% 100%;
}
</style>
