<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'safe',
    data(){
        return{
          width:document.body.clientWidth,
          chooseLanguage:window.localStorage.getItem('language'),
        }
    },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  }
}
</script>

<template>
<div :style="{width:this.width+'px'}" style="justify-content: center;display: flex">
  <div :style="{width:(this.width/2543)*1364+'px'}" class="firstPart">
    <div :style="{marginLeft:(this.width/2543)*350+'px',width:(this.width/2543)*350+'px',height:(this.width/2543)*95+'px'}" class="dialog-box">
      <div class="dialog-content">
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',lineHeight:1.5}">
          <span style="color: #d20000">
            爱登堡设计的出发点：
          </span>
          <br>
          <span style="color: #d20000">
            钢丝绳绕在曳引轮上会打滑！
          </span>
          <br>
          <span>
            (其他厂家不会考虑该打滑情况)
          </span>
        </p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*12+'px'}">
          <span style="color: #d20000">
           The starting point of Attenborough's design:
          </span>
          <br>
          <span style="color: #d20000">
            The steel wire rope will slip around the traction wheel！
          </span>
          <br>
          <span>
            (Other manufacturers will not consider this slipping situation)
          </span>
        </p>
      </div>
      <div class="dialog-triangle">
      </div>
    </div>
    <div :style="{marginLeft:(this.width/2543)*350+'px',width:(this.width/2543)*666+'px',height:(this.width/2543)*166+'px',marginTop:(this.width/2543)*40+'px'}" style="background-color: #d20000;border-radius: 5px;justify-content: center;display: flex;align-items: center">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white">爱登堡电梯<span style="color: yellow">采用双编码器</span>，同时监测轿厢和曳引机的速度</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white">Edunburgh Elevator<span style="color: yellow"> adopts dual encoders</span>，to simultaneously monitor the speed and displacement of the car and traction machine</span>
        <br v-show="this.chooseLanguage==='CHINESE'">
        <br>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white">及位移并进行比对，实现整机特有的安全可靠运行。</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white"> And compare them to achieve the unique safe and reliable operation of the entire machine.</span>
      </p>
    </div>
    <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*894+'px',marginTop:(this.width/2543)*30+'px'}" class="safeImg"></div>
    <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*894+'px',marginTop:(this.width/2543)*30+'px'}" class="safeImgEn"></div>
  </div>
</div>
<div :style="{width:this.width+'px',height:(this.width/2543)*620+'px'}" style="background-color: #eaf0dd" class="secondPart">
  <p :style="{fontSize:(this.width/2543)*40+'px',width:this.width+'px',paddingTop:(this.width/2543)*70+'px'}">
    <span v-show="this.chooseLanguage==='CHINESE'" class="partTitle"><span style="color: #d20000">连续多届</span>获得政府采购<span style="color: #d20000">首选安全</span>品牌</span>
    <span v-show="this.chooseLanguage!=='CHINESE'" class="partTitle">Obtained<span style="color: #d20000"> the first choice</span> of government procurement safety brand for <span style="color: #d20000">multiple consecutive times</span></span>
  </p>
  <div :style="{width:this.width+'px',height:(this.width/2543)*334+'px'}" style="display: flex;justify-content: center;align-items: center">
    <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*334+'px',marginTop:(this.width/2543)*80+'px'}" style="display: flex;justify-content: center;align-items: center" class="safePreferredBrand">
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color: #d20000;
}
.firstPart{
  .dialog-box {
    justify-content: center;
    display: flex;
    position: relative;
    background-color: #dedede;
    border: 1px solid #dedede;
    border-radius: 5px;
  }
  .dialog-triangle {
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #dedede;
  }
  .safeImg{
    background-image: url("/src/assets/imgs/about/safeImg.png");
    background-size: 100% 100%;
  }
  .safeImgEn{
    background-image: url("/src/assets/imgs/about/safeImgEn.png");
    background-size: 100% 100%;
  }
}
.secondPart{
  text-align: center;
  .safePreferredBrand{
    background-image: url('/src/assets/imgs/about/safePreferredBrand.png');
    background-size: 100% 100%;
  }
}
</style>
