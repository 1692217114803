<script>
import {Phone, Top} from "@element-plus/icons-vue";

export default {
    name:'projectPresentation',
    components: {Top, Phone},
    inject:['reload'],
    data(){
        return{
          width: document.body.clientWidth,
          chooseLanguage:'',
          options:[
            {
              value: 'CHINESE',
              label: '中',
            },
            {
              value: 'ENGLISH',
              label: 'EN',
            }
          ],
            imgs:[
                require('/src/assets/imgs/projectPresentation/1.png'),
                require('/src/assets/imgs/projectPresentation/2.png'),
                require('/src/assets/imgs/projectPresentation/3.png'),
                require('/src/assets/imgs/projectPresentation/4.png'),
                require('/src/assets/imgs/projectPresentation/5.png'),
                require('/src/assets/imgs/projectPresentation/6.png'),
                require('/src/assets/imgs/projectPresentation/7.png'),
                require('/src/assets/imgs/projectPresentation/8.png'),
                require('/src/assets/imgs/projectPresentation/9.png'),
                require('/src/assets/imgs/projectPresentation/10.png'),
                require('/src/assets/imgs/projectPresentation/11.png'),
                require('/src/assets/imgs/projectPresentation/12.png'),
                require('/src/assets/imgs/projectPresentation/13.png'),
                require('/src/assets/imgs/projectPresentation/14.png'),
                require('/src/assets/imgs/projectPresentation/15.png'),
                require('/src/assets/imgs/projectPresentation/16.png'),
                require('/src/assets/imgs/projectPresentation/17.png'),
                require('/src/assets/imgs/projectPresentation/18.png'),
                require('/src/assets/imgs/projectPresentation/19.png'),
                require('/src/assets/imgs/projectPresentation/20.png'),
                require('/src/assets/imgs/projectPresentation/21.png'),
                require('/src/assets/imgs/projectPresentation/22.png'),
                require('/src/assets/imgs/projectPresentation/23.png'),
                require('/src/assets/imgs/projectPresentation/24.png'),
                require('/src/assets/imgs/projectPresentation/25.png'),
                require('/src/assets/imgs/projectPresentation/26.png'),
                require('/src/assets/imgs/projectPresentation/27.png'),
                require('/src/assets/imgs/projectPresentation/28.png'),
                require('/src/assets/imgs/projectPresentation/29.png'),
                require('/src/assets/imgs/projectPresentation/30.png'),
                require('/src/assets/imgs/projectPresentation/31.png'),
                require('/src/assets/imgs/projectPresentation/32.png'),
                require('/src/assets/imgs/projectPresentation/33.png'),
                require('/src/assets/imgs/projectPresentation/34.png'),
                require('/src/assets/imgs/projectPresentation/35.png'),
                require('/src/assets/imgs/projectPresentation/36.png'),
                require('/src/assets/imgs/projectPresentation/37.png'),
                require('/src/assets/imgs/projectPresentation/38.png'),
                require('/src/assets/imgs/projectPresentation/39.png'),
                require('/src/assets/imgs/projectPresentation/40.png'),
                require('/src/assets/imgs/projectPresentation/41.png'),
                require('/src/assets/imgs/projectPresentation/42.png'),
                require('/src/assets/imgs/projectPresentation/43.png'),
                require('/src/assets/imgs/projectPresentation/44.png'),
                require('/src/assets/imgs/projectPresentation/45.png'),
                require('/src/assets/imgs/projectPresentation/46.png'),

            ],
            isShowTels:false
        }
    },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
    methods:{
        // 判断phone端还是pc端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        showTel(){
            this.isShowTels=true;
        },
        goTop(){
            window.scrollTo( 0, 0 );
        },
      chooseCNorEN(value){
        window.localStorage.setItem('language',value)
        this.reload()
      }
    },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
<div>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>
  </el-menu>
  <el-affix :offset="600">
      <div :class="this._isMobile()==null?'floatWindow':'floatWindowMobile'">
          <div class="telShow">
              <el-icon><Top style="color: white" @click="goTop"/></el-icon>
          </div>
      </div>
  </el-affix>
  <el-affix :offset="100">
      <div :class="this._isMobile()==null?'floatWindow':'floatWindowMobile'">
          <div class="telShow" @click="this.isShowTels=true" @mouseenter="this.showTel">
              <el-icon><Phone style="color: white" /></el-icon>
          </div>
        <div :class="this._isMobile()==null?'telShowWords':'telShowWordsMobile'" @mouseleave="this.isShowTels=false" v-show="isShowTels">
          <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">总机：</h1>
          <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">switchboard：</h1>
          <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">021-33884999</p>
          <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">免费服务热线：</h1>
          <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">Free Service Hotline：</h1>
          <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">400 889 0388</p>
          <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">800 988 0388</p>
          <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">集团产业联系人：</h1>
          <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">Contact person for the group's industry：</h1>
          <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">电梯改造/加装：15921690151</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Elevator renovation/installation：15921690151</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">产业园区：13817622467</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Industrial Park：13817622467</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">教育培训：13671895774</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Education Training：13671895774</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">智能楼宇：15821672579</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Smart Buildings：15821672579</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">鼎恒昇：15821672360</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">DHS：15821672360</p>
        </div>
      </div>
  </el-affix>
  <el-image v-for="item in this.imgs" :key="item" :src="item" />
  <div class="titleEight">
      沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1
  </div>
</div>
</template>

<style lang="scss" scoped>
.floatWindow {
    position: absolute;
    z-index: 1;
    text-align: center;
    float: left;
    width: auto;
    height: auto;
}
.floatWindowMobile{
    position: absolute;
    z-index: 1;
    text-align: center;
    padding-top: calc(100vh/10);
    float: left;
    width: auto;
    height: auto;
    right: 0;
}
.telShow {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #000;
    height: calc(100vh/18);
    width: calc(100vh/18);
    font-size: calc(100vh/18);
    float: left;
    .icon {
        display: flex;
    }
}
.telShowWords{
    float: left;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #000;
    height: auto;
    width: calc(100vh/2);
}
.telShowWordsMobile{
    float: left;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #000;
    height: auto;
    width: calc(100vh/2);
}
.telWords{
    color: #f80606;
    font-size: calc(100vw/90)
}
.telWordsMobile{
    color: #fcf8f8;
    font-size: calc(100vh/60)
}
.topNavBar{
    width: 100%;
    height: auto;
    z-index: 1;
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
</style>
