import {createApp} from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

let vue=createApp()

vue.component('svg-icon',SvgIcon)
const req = require.context('@/assets/icon/svg',false,/\.svg$/)
const requireAll = requireContext =>{
    // requireContext.keys()数据：['./404.svg', './agency.svg', './det.svg', './user.svg']
    requireContext.keys().map(requireContext)
}
requireAll(req)
