<template>
<div>
    <div v-for="(item,index) in this.auctionM" :key="index">
        {{item.auctionId}}
    </div>
</div>
</template>

<script>
import {getRequestByToken} from "@/utils/api";

export default {
    name: "myAuctionMobile",
    data(){
      return{
          auctionM:[],
      }
    },
    methods: {
        getAuctionInformationM() {
            getRequestByToken("/auction/getAuctionInformation")
                .then(resp => {
                    window.sessionStorage.setItem("auctionsNum", resp.data.data.length)
                    for (let i = 0; i < resp.data.data.length; i++) {
                        let a = new Date(resp.data.data[i].auctionEndTime);
                        const date = new Date(a);
                        const Y = date.getFullYear() + '/';
                        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
                        const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
                        const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                        const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                        const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
                        const endTime = Y + M + D + h + m + s;
                        this.auctionM.push({
                            "auctionName": resp.data.data[i].auctionName,
                            "auctionInvoiceCompany": resp.data.data[i].auctionInvoiceCompany,
                            "auctionNotes": resp.data.data[i].auctionNotes,
                            "auctionSpecifications": resp.data.data[i].auctionSpecifications,
                            "auctionMaterials": resp.data.data[i].auctionMaterials,
                            "auctionUnit": resp.data.data[i].auctionUnit,
                            "auctionQuantity": resp.data.data[i].auctionQuantity,
                            "auctionDestination": resp.data.data[i].auctionDestination,
                            "description": resp.data.data[i].description,
                            "auctionEndTime": endTime,
                            "auctionSituation": "当前出价为" + resp.data.data[i].auctionPrice + "元,每次最低减价" + resp.data.data[i].singleBid + "元.",
                            "isIngOrNot": resp.data.data[i].status,
                            "auctionId": resp.data.data[i].auctionId,
                        })
                    }
                })
        }
    },
    mounted() {
        this.getAuctionInformationM()
    }
}
</script>

<style scoped>

</style>
