import { createStore } from 'vuex'
export default createStore({
    state: {
        // 这里放置需要管理的状态
    },
    mutations: {
        // 这里是改变状态的方法
    },
    actions: {
        // 这里是触发改变状态的行为
    },
    modules: {
        // 如果状态较多，可以将状态分割到多个模块中管理
    }
})