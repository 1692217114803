<script>
import PDFJSViewer from "@/components/about/PDFJSViewer.vue";

export default {
  name:'pdfView',
  data(){
    return{
      path:'pdf/web/viewer.html',
      name:'adbPdf.pdf'
    }
  },
  components:{
    PDFJSViewer
  }
}
</script>

<template>
  <div>
    <PDFJSViewer :path="`${path}`" :fileName="`${name}`"/>
  </div>
</template>

<style scoped>

</style>
