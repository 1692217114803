<template>
  <div :style="{ height: screenHeight + 'px'}" class="auctionBg">
    <div>
<!--        <el-input size="small" v-model="needShowAuctions" @change="getAuctionInformation" placeholder="请输入您要搜索的竞标">
            <template #prepend>
                <p style="" type="primary" @click="getAuctionInformation">搜索条件</p>
            </template>
        </el-input>-->
      <el-button type="primary" v-show="this.isAdmin" @click="uploadExcel">导入excel</el-button>
      <el-button type="primary" v-show="this.isAdmin" @click="selectAuctionsList">查询竞价列表</el-button>
      <el-button type="primary" v-show="this.isAdmin" :disabled="startRealTimeRefresh==true" @click="getMaxPrice">{{this.startRealTimeRefresh==false?'开启实时刷新':'正在实时刷新中......'}}</el-button>
      <el-button type="primary" v-show="this.isAdmin&&this.canClose" @click="stopMaxPrice">关闭</el-button>
      <el-button type="primary" @click="showAuctions">竞价结果查询</el-button>
      <el-button type="primary" v-show="this.isAdmin" @click="selectLogin">查询当前登录用户</el-button>
      <el-tag
          effect="plain"
          round
      >
        登录用户：{{this.nickname}}
      </el-tag>
    </div>
    <div v-show="this.fullscreenLoading" v-loading="this.fullscreenLoading" element-loading-text="当前暂无竞价开始，请耐心等待！！！" style="margin-top: 50vh;margin-right: 30vw" >
    </div>
    <div element-loading-background="rgba(122, 122, 122, 0.8)">
      <el-row  :gutter="20" style="width: 90%">
        <el-col :span="10" v-for="(item,index) in auctions" :key="index">
          <div id="itemCss" :style="{backgroundColor:this.mouseChosen===index?'#98fa67':'#f5f4f4',marginTop:'calc(100vw * 16 / 1080)'}" @mouseenter="bgColorChangeRed(index)" @mouseleave="bgColorChangeGary(index)" @click="openAuction(index)">
            <el-row v-show="false">{{item.auctionId}}</el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem"><h1 style="line-height: 1em;">{{item.auctionName}}</h1></el-row>
            <el-row v-show="false" style="margin-bottom: 0.3rem;margin-left: 0.2rem;font-size: calc(100vw * 18 / 1920);font-weight: bold">发票公司:{{item.auctionInvoiceCompany}}</el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem">
              <el-col :span="6" style="font-size: calc(100vw * 18 / 1920)">规格:</el-col>
              <el-col :span="8" style="font-size: calc(100vw * 18 / 1920)">{{item.auctionSpecifications}}</el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem" v-show="item.auctionUnit.indexOf('项')==-1">
              <el-col :span="6" style="font-size: calc(100vw * 18 / 1920)">数量:</el-col>
              <el-col :span="8" style="font-size: calc(100vw * 18 / 1920)">{{item.auctionQuantity}}({{item.auctionUnit}})</el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem">
              <el-col :span="6" style="font-size: calc(100vw * 18 / 1920)">送货地址:</el-col>
              <el-col :span="15" style="font-size: calc(100vw * 18 / 1920)">{{item.auctionDestination}}</el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem">
              <el-col :span="6" style="font-size: calc(100vw * 18 / 1920)">备注:</el-col>
              <el-col :span="8" style="font-size: calc(100vw * 18 / 1920)">{{item.auctionNotes}}</el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem">
              <el-col :span="6" style="font-size: calc(100vw * 18 / 1920)">详情描述：</el-col>
              <el-col :span="8" style="overflow:hidden;text-overflow: ellipsis;white-space:nowrap;font-size: calc(100vw * 18 / 1920)">{{item.description.replace(/<[^>]+>/g," ")}}</el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem;line-height: 0.5rem">
              <el-col :span="6" style="font-size: calc(100vw * 20 / 1920)">结束时间:</el-col>
              <el-col :span="15" style="overflow:hidden;white-space:nowrap"><count-down :endTime="(new Date(item.auctionEndTime).getTime()/1000)+' '" :endText="'竞价结束' " :auctionId="this.auctions[index].auctionId" /></el-col>
            </el-row>
            <el-row style="margin-bottom: 0.3rem;margin-left: 0.2rem;"><h1 style="line-height: 1em;color: red">{{this.auctions[index].auctionSituation}}</h1></el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
        v-model="upLoadExcelVisible"
        title="Tips"
        width="30%"
        :before-close="handleClose"
    >
      <el-form :model="form">
        <el-form-item label="上传文件" label-width="80px">
          <el-upload ref="uploadDemo" :headers="this.myHeader" :on-success="uploadSuccess" :on-error="uploadError" action="http://192.168.0.229:8082/auction/uploadExcelFile2" :auto-upload="false" multiple :file-list="fileList">
            <el-button size="small" type="primary">选择文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="uploadFile">立即上传</el-button>
          <el-button size="small">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog v-model="setStartTimeTableVisible" title="修改未结束竞价" :before-close="closeStartTimeTable" width="70%">
        <el-button
                link
                type="primary"
                size="small"
                @click.prevent="auctionsUpdate"
        >
           一键更新
        </el-button>
        <el-table border :data="setStartTimeTable" style="width: 100%" max-height="500" :row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" />
            <el-table-column fixed prop="auctionName" label="竞价名称" width="300" />
            <el-table-column prop="auctionSpecifications" label="竞价规格" width="400" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionSpecifications"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入竞价规格"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionMaterials" label="材料" width="200" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionMaterials"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入材料"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionUnit" label="单位" width="120" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionUnit"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入单位"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionNotes" label="备注" width="120" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionNotes"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入备注"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionQuantity" label="数量" width="120" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionQuantity"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入数量"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionDestination" label="目的地" width="400" >
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].auctionDestination"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入目的地"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="auctionStartTime" label="开始时间" width="250" >
            <template #default="scope">
              <el-date-picker
                      v-model="setStartTimeTable[scope.$index].auctionStartTime"
                      :disabled="setStartTimeTable[scope.$index].auctionStatus==1"
                      type="datetime"
                      placeholder="添加开始时间"
              />
            </template>
            </el-table-column>
            <el-table-column prop="auctionStartTime" label="结束时间" width="250" >
                <template #default="scope">

                    <el-date-picker
                            v-model="setStartTimeTable[scope.$index].auctionEndTime"
                            :disabled="!setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            type="datetime"
                            placeholder="添加结束时间"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="descriptionOne" label="详情一" width="300">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].descriptionOne"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入详情"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="descriptionTwo" label="详情二" width="300">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].descriptionTwo"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入详情"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="descriptionThree" label="详情三" width="300">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].descriptionThree"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入详情"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="descriptionFour" label="详情四" width="300">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].descriptionFour"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入详情"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="descriptionFive" label="详情五" width="300">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].descriptionFive"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='创建数据'"
                            size="large"
                            placeholder="请输入详情"
                    />
                </template>
            </el-table-column>
            <el-table-column prop="singleBid" label="单次最低减价" width="120">
                <template #default="scope">
                    <el-input
                            v-model="setStartTimeTable[scope.$index].singleBid"
                            :disabled="setStartTimeTable[scope.$index].buttonName=='更新数据'"
                            size="large"
                            placeholder="请输入单次最低减价区间"
                    />
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="200">
                <template #default="scope">
                    <el-button
                            link
                            type="primary"
                            size="small"
                            :disabled="setStartTimeTable[scope.$index].isUpdate"
                            @click.prevent="updateStartTime(scope.$index)"
                    >
                        {{setStartTimeTable[scope.$index].buttonName}}
                    </el-button>
                    <el-button
                            link
                            type="primary"
                            size="small"
                            @click.prevent="pauseAuction(scope.$index)"
                    >
                        {{setStartTimeTable[scope.$index].buttonNameTwo}}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
    <el-dialog v-model="dialogTableVisible" title="竞价详情" :before-close="closeDialog">
      <h1>{{this.auctions[this.clicked].auctionName}}</h1>
      <div>
        <h3 class="ml-3 w-35 text-gray-600 inline-flex items-center" style="white-space: pre-wrap;">详情描述：</h3>
        <div v-html="this.auctions[this.clicked].description" />
      </div>
      <div>
        <h3 class="ml-3 w-35 text-gray-600 inline-flex items-center">请输入价格：</h3>
        <el-input
            v-model="yourPrice"
            placeholder="请输入您的价格"
        />
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">关闭竞价</el-button>
        <el-button type="primary" @click="submissionAuctionPrice(this.clicked)">
          提交竞价价格
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="addAuctionDialogVisible"
        title="添加竞价品"
        width="37%"
        :before-close="addAuctionBeforeClose"
    >
      <el-form :model="addAuctionViews" label-width="2.5rem">
        <el-form-item label="竞价品名称" placeholder="请输入物品名称">
          <el-input v-model="addAuctionViews.auctionName" />
        </el-form-item>
        <el-form-item label="发票公司" placeholder="发票公司">
          <el-select v-model="auctionInvoiceCompany" style="width: 100%" placeholder="请选择发票公司">
            <el-option
                v-for="item in this.auctionInvoiceCompanys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="竞价品规格" placeholder="请输入规格">
          <el-input v-model="addAuctionViews.auctionSpecifications" />
        </el-form-item>
        <el-form-item label="竞价品单位" placeholder="请输入单位">
          <el-input v-model="addAuctionViews.auctionUnit" />
        </el-form-item>
        <el-form-item label="竞价品数量" placeholder="请输入所需数量">
          <el-input v-model="auctionQuantity" />
        </el-form-item>
        <el-form-item label="竞价品送货地" placeholder="请输入送货地">
          <el-select v-model="auctionDestination" allow-create filterable default-first-option style="width: 100%" placeholder="海安">
            <el-option
                v-for="item in this.auctionDestinations"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="竞价品备注" placeholder="请输入备注">
          <el-input v-model="addAuctionViews.auctionNotes" maxlength="20"/>
        </el-form-item>
        <el-form-item label="竞价品初始价格" placeholder="请输入初始价格">
          <el-input v-model="auctionPrice" />
        </el-form-item>
        <el-form-item v-for="(item,index) in addDescription" :key="item.id" :label="'详情描述'+(index+1)">
          <el-input
              v-model="item.data"
              type="input"
              placeholder="请输入详情描述"
          />
          <el-button type="danger" @click="deleteDescription(item,index)">-</el-button>
          <el-button type="primary" @click="add">+</el-button>
        </el-form-item>
        <el-form-item label="竞价结束时间">
          <div class="block">
            <!--            <span class="demonstration"></span>-->
            <el-date-picker
                v-model="addAuctionViews.date"
                type="datetimerange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
            />
          </div>
        </el-form-item>
        <el-form-item label="单次减价最小值" placeholder="请输入单次最小减价">
          <el-input v-model="singleBid"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addAuctionDialogVisible = false">关闭</el-button>
        <el-button type="primary" @click="addAuctionForm">
          提交
        </el-button>
      </span>
      </template>
    </el-dialog>
    <el-dialog
        v-model="showAuctionTable"
        title="已完成的竞价"
        width="1100"
        :before-close="showAuctionTableBeforeClose"
    >
      <el-date-picker
          v-model="selectTime"
          type="datetimerange"
          :shortcuts="shortcuts"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      />
      <el-button size="default" type="primary" @click="downloadAuctions">下载</el-button>
      <el-table :data="auctionsFinished" :row-class-name="tableRowClassName">
        <el-table-column property="auctionName" label="竞价品名称" width="200"/>
        <el-table-column property="auctionProperty" label="竞标类型" width="200"
                         :filters="this.finishedAuctionProperty"
                         :filter-method="finishedAuctionPropertyHandler"/>
        <el-table-column property="auctionSpecifications" label="规格" width="150"/>
        <el-table-column property="auctionBidders" label="竞买者" width="200" />
        <el-table-column property="auctionStartTime"
                         label="开始时间  "
                         width="200"
                         column-key="date"
                         sortable
                         :filters="this.finishedStartDate"
                         :filter-method="finishedStartDateHandler"/>
        <el-table-column property="auctionEndTime" label="结束时间" width="200" />
        <el-table-column property="auctionPrice" label="最终价格(元)" width="120"/>
      </el-table>
    </el-dialog>
    <el-dialog
        v-model="loginUsersShow"
        title="当前登录用户"
        width="500"
        :before-close="showLoginUsersBeforeClose"
    >
      <el-table :data="loginUsers">
        <el-table-column property="username" label="登录用户"/>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import countDown from '@/components/countDown'//引入路径，可更改
import {ElMessage, ElMessageBox} from "element-plus";
import {getRequestByToken, postRequestByToken, postRequestByTokenDownLoad} from "@/utils/api";
export default {
  inject:['reload'],
  name: "myAuction",
  data(){
    return{
      needShowAuctions:'',
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids:[],
      auctionsId:null,
      isUpdateOrNot:null,
      setStartTimeTableVisible:false,
      setStartTimeTable:[],
      finishedAuctionProperty:[],
      finishedStartDate:[],
      loginUsers:[],
      loginUsersShow:false,
      contentLast: '',
      nickname:window.sessionStorage.getItem('nickname'),
      fullscreenLoading:'',
      canClose:'',
      isAdmin:'',
      selectTime:[Date,Date],
      shortcuts:[
        {
          text: '前一周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '前一个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '前三个月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      myHeader:{
        'satoken': window.sessionStorage.getItem("userSatokenValue").toString()
      },
      form: {
        file: ''
      },
      upLoadExcelVisible:false,
      fileList:[],
      screenHeight: document.body.clientHeight - 110,
      startRealTimeRefresh:false,
      auctionDestinations:[
        {
          value: '海安',
          label: '海安',
        },
        {
          value: '宜昌',
          label: '宜昌',
        },
        {
          value: '贵阳',
          label: '贵阳',
        },
        {
          value: '上海',
          label: '上海',
        },],
      auctionInvoiceCompanys:[
        {
          value: '上海爱登堡电梯集团股份有限公司',
          label: '上海爱登堡电梯集团股份有限公司',
        },
        {
          value: '上海爱登堡电梯江苏有限公司',
          label: '上海爱登堡电梯江苏有限公司',
        },
        {
          value: '上海爱登堡电梯(宜昌)有限公司',
          label: '上海爱登堡电梯(宜昌)有限公司',
        },
        {
          value: '上海爱登堡电梯贵州有限公司',
          label: '上海爱登堡电梯贵州有限公司',
        },],
      auctionsFinished:[],
      auctionsNeedUpdate:[],
      auctions:[],
      mouseChosen:null,
      clicked:null,
      dialogTableVisible:false,
      yourPrice:null,
      auctionDestination:'海安',
      singleBid:100,
      auctionPrice:10000000,
      auctionQuantity:1,
      auctionInvoiceCompany:'',
      addAuctionViews:[{auctionId:'',auctionName:'',auctionInvoiceCompany:'',auctionSpecifications:'',auctionMaterials:'',auctionUnit:'',auctionQuantity:'',auctionNotes:'',auctionDestination:'海安',auctionPrice:'0',description:[{}],time:'',singleBid:''}],
      addDescription:[
        {
          id:"",
          data:""}
      ],
      descriptionNum:0,
      addAuctionDialogVisible:false,
      showAuctionTable:false,
      auctionInformation:[],
    }
  },
  components:{
    countDown
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        // 可以限制最小高度
        // if (document.body.clientHeight - 240 < 450) {
        //   return
        // }
        window.screenHeight = document.body.clientHeight- 110
        that.screenHeight = window.screenHeight
      })()
    }
    getRequestByToken("/auction/getStartRealTimeRefresh")
        .then(resp=>{
          this.startRealTimeRefresh=resp.data.data
        })
     if(window.sessionStorage.getItem("role").indexOf("admin")==-1){
      this.isAdmin=false
    }else {
      this.isAdmin=true
    }
    if(window.sessionStorage.getItem("role")==("superadmin")){
      this.canClose=true
    }else {
      this.canClose=false
    }
    this.getAuctionInformation()
    this.initWebSocket()
  },
  watch:{
    screenHeight (val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenHeight = val
        this.timer = true
        let that = this
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.timer = false
        }, 400)
      }
    }
  },
  methods:{
    /**
     * 当鼠标移动上去时变为红色
     * */
    bgColorChangeRed(index){
      this.mouseChosen=index
    },
    /**
     * 当鼠标移开时颜色变回
     * */
    bgColorChangeGary(){
      this.mouseChosen=null
    },
    /**
     * openAuction,closeDialog,submissionAuctionPrice为竞价的方法
     * */
    openAuction(index){
      this.clicked=index
      this.dialogTableVisible=true
    },
    closeDialog(){
      ElMessageBox.confirm('请问您是否要取消当前出价?')
          .then(() => {
            this.dialogTableVisible=false
            this.clicked=0
          })
          .catch(() => {
            // catch error
          })
    },
    submissionAuctionPrice(index){
      let getNowPrice = new RegExp(`${'当前出价为'}(.*?)${'元'}`)
      let getMinimumReduction=new RegExp(`${'每次最低减价'}(.*?)${'元'}`)
      if(this.auctions[index].auctionSituation.indexOf('当前您出价最低')!=-1){
        postRequestByToken("/auction/getPrice",{'auctionNumber':index,'auctionId':this.auctions[index].auctionId,'auctionPrice':this.yourPrice})
            .then(resp=>{
              this.yourPrice=null
              //console.log(resp.data.data)
              if(resp.data.data[1]=="false"){
                ElMessage.error("当前您出价高于最低价格")
                this.reload()
              }else {
                this.auctions[index].auctionSituation='您当前出价最低,请勿重复出价！！!('+resp.data.data[2]+'元)';
              }
            })
        this.dialogTableVisible=false
        this.clicked=0;
      }else{
        if((this.auctions[index].auctionSituation.match(getNowPrice)[1]-this.yourPrice)>this.auctions[index].auctionSituation.match(getMinimumReduction)[1]*4&&this.auctions[index].auctionSituation.match(getNowPrice)[1]<10000000){
            this.$confirm('当前您出示的价格与当前价格的差别过大，减去价格已经大于等于单次最小减价的三倍，请问您确认要进行提交吗？'+'当前您出示的价格为('+this.yourPrice+'元)', '警告', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              customClass: 'myConfirmBox', // 在这里添加自定义类名
              customStyle:{
                backgroundColor:'red',
                fontWeight:'bolder',
              },
            })
              .then(() => {
                postRequestByToken("/auction/getPrice",{'auctionNumber':index,'auctionId':this.auctions[index].auctionId,'auctionPrice':this.yourPrice})
                    .then(resp=>{
                      this.yourPrice=null
                      //console.log(resp.data.data)
                      if(resp.data.data[1]=="false"){
                        ElMessage.error("当前您出价高于最低价格")
                        this.reload()
                      }else {
                        this.auctions[index].auctionSituation='您当前出价最低,请勿重复出价！！!('+resp.data.data[2]+'元)';
                      }
                    })
                this.dialogTableVisible=false
                this.clicked=0;
              })
              .catch(() => {
                // catch error
              })
        } else{
          postRequestByToken("/auction/getPrice",{'auctionNumber':index,'auctionId':this.auctions[index].auctionId,'auctionPrice':this.yourPrice})
              .then(resp=>{
                this.yourPrice=null
                //console.log(resp.data.data)
                if(resp.data.data[1]=="false"){
                  ElMessage.error("当前您出价高于最低价格")
                  this.reload()
                }else {
                  this.auctions[index].auctionSituation='您当前出价最低,请勿重复出价！！!('+resp.data.data[2]+'元)';
                }
              })
          this.dialogTableVisible=false
          this.clicked=0;
        }
      }
    },
    /**
     * 添加竞价品
     * */
    addAuction(){
      this.addAuctionDialogVisible=true
    },
    addAuctionBeforeClose(){
      ElMessageBox.confirm('请问是否添加当前竞价品?')
          .then(() => {
            this.addAuctionDialogVisible=false
          })
          .catch(() => {
            // catch error
          })
    },
    showAuctionTableBeforeClose(){
      ElMessageBox.confirm('请问关闭页面')
          .then(() => {
            this.auctionsFinished=[];
            this.finishedAuctionProperty=[];
            this.finishedStartDate=[];
            this.showAuctionTable=false
          })
          .catch(() => {
            // catch error
          })
    },
    addAuctionForm(){
      for (let i = 0; i < this.addDescription.length; i++) {
        this.addAuctionViews.description+=this.addDescription[i].data+"<br/>"
      }
      postRequestByToken("/auction/createAuction",{"auctionName":this.addAuctionViews.auctionName,"auctionInvoiceCompany":this.auctionInvoiceCompany,"auctionSpecifications":this.addAuctionViews.auctionSpecifications, "auctionMaterials":this.addAuctionViews.auctionSpecifications, "auctionUnit":this.addAuctionViews.auctionUnit, "auctionQuantity":this.auctionQuantity, "auctionNotes":this.addAuctionViews.auctionNotes, "auctionDestination":this.auctionDestination,"auctionPrice":this.auctionPrice,"description":this.addAuctionViews.description.replace("undefined",''),"auctionStartTime":this.addAuctionViews.date[0],"auctionEndTime":this.addAuctionViews.date[1],"singleBid":this.singleBid,"status":"0"})
          .then(()=>{
            this.auctions=[]
            this.getAuctionInformation()
          })
      this.auctionDestination='海安';
      this.singleBid=100;
      this.auctionPrice=0;
      this.auctionQuantity=1;
      this.auctionInvoiceCompany='';
      this.addDescription=[{id:"", data:""}]
      this.addAuctionViews=[{auctionName:'',auctionInvoiceCompany:'',auctionSpecifications:'',auctionMaterials:'',auctionUnit:'',auctionQuantity:'',auctionNotes:'',auctionDestination:'',description:[{}],auctionPrice:'',date:'',time:'',singleBid:''}]
      this.addAuctionDialogVisible=false
    },
    /**
     * 获取竞价品
     * */
    getAuctionInformation(){
      this.auctions=[];
      postRequestByToken("/auction/getAuctionInformation",{"auctionName":this.needShowAuctions})
          .then(resp=>{
            if(resp.data.data==null&&(window.sessionStorage.getItem("role").indexOf("admin"))==-1){
              this.fullscreenLoading=true
            }
            window.sessionStorage.setItem("auctionsNum",resp.data.data.length)
            for (let i = 0; i < resp.data.data.length; i++) {
              let a = new Date(resp.data.data[i].auctionEndTime);
              const date = new Date(a);
              const Y = date.getFullYear() + '/';
              const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
              const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
              const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
              const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())+':';
              const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
              const endTime = Y + M + D + h + m + s;
              let auctionWords="";
              if(resp.data.data[i].auctionBidders!=window.sessionStorage.getItem("userId")){
                 auctionWords="当前出价为"+resp.data.data[i].auctionPrice+"元,每次最低减价"+resp.data.data[i].singleBid+"元."
              }else {
                  auctionWords="当前您出价最低，请勿重复出价！！（"+resp.data.data[i].auctionPrice+"元）.";
              }
              this.auctions.push({"auctionBidder":resp.data.data[i].auctionBidders,"auctionName":resp.data.data[i].auctionName,"auctionInvoiceCompany":resp.data.data[i].auctionInvoiceCompany,"auctionNotes":resp.data.data[i].auctionNotes,"auctionSpecifications":resp.data.data[i].auctionSpecifications,"auctionMaterials":resp.data.data[i].auctionMaterials,"auctionUnit":resp.data.data[i].auctionUnit,"auctionQuantity":resp.data.data[i].auctionQuantity,"auctionDestination":resp.data.data[i].auctionDestination,"description":resp.data.data[i].description,"auctionEndTime":endTime,"auctionSituation":auctionWords,"isIngOrNot":resp.data.data[i].status,"auctionId":resp.data.data[i].auctionId,})
            }
          })
    },
    /**
     * 用于更新数据
     * */
    getMaxPrice(){
      ElMessageBox.confirm('请问是否开始更新实时价格')
          .then(() => {
            this.startRealTimeRefresh=true;
            postRequestByToken("/auction/getMaxPrice")
          })
          .catch(() => {
            // catch error
          })
    },
    initWebSocket: function () {
     this.websock = new WebSocket(`ws://${location.host}/ws/auction/WebSocket/`+window.sessionStorage.getItem("userSatokenValue"));
      /*this.websock = new WebSocket(`ws://localhost:8082/auction/WebSocket/`+window.sessionStorage.getItem("userSatokenValue"));*/
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen: function () {
      console.log("WebSocket连接成功");
    },
    websocketonerror: function (e) {
      console.log("WebSocket连接发生错误"+e);
    },
    websocketonmessage: function (e) {
      if(e.data=="有任务信息更改！！！"){
          console.log('有任务更新')
          this.reload();
      }
      else if(e.data=="有项目结束了"){
          console.log('结束啦')
          this.reload();
      }
      else if(e.data=="没有搜到数据"){
          console.log('没有搜到数据')
          if(window.sessionStorage.getItem("auctionsNum")!=-1){
              this.reload();
              window.sessionStorage.setItem("auctionsNum",-1);
          }
      }
      else if(e.data=="有搜索到数据"){
          console.log('有搜索到数据')
              ElMessage.error("竞价列表个数已经更新，请及时刷新页面！！！")
              setTimeout(() => {
                  this.fullscreenLoading.value = false
              }, 1000)
              this.reload()
      }
      else if(e.data.indexOf("价格进行了更新")!=-1){
          const maxPriceInformation=e.data.split(";",7);
          if(new Date(this.auctions[maxPriceInformation[1]].auctionEndTime).getTime()!=maxPriceInformation[6]){
              this.reload()
              this.auctions[maxPriceInformation[1]].auctionEndTime=this.timestampToTime(parseInt(maxPriceInformation[6]))
          }
          if(maxPriceInformation[4]==window.sessionStorage.getItem("userId")){
              console.log("你是最低价")
              this.auctions[maxPriceInformation[1]].auctionSituation="您当前出价最低,请勿重复出价！！！"+"("+maxPriceInformation[3]+"元"+")"
          }else{
              console.log("你不是最低价")
              this.auctions[maxPriceInformation[1]].auctionSituation="当前出价为"+maxPriceInformation[3]+"元"+",每次最低减价"+maxPriceInformation[5]+"元."
          }
      }
    },
    send:function (e){
      this.websock.send(e)
    },
    websocketclose: function (e) {
      //getRequestByToken("/logout")
      console.log("connection closed (" + e.code + ")");
    },
      /**
       * 时间戳转化成date
       * */
      timestampToTime(timestamp) {
          let date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
          let Y = date.getFullYear() + '/';
          let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '/';
          let D = date.getDate() + ' ';
          let h = date.getHours() + ':';
          let m = date.getMinutes() + ':';
          let s = date.getSeconds();
          return Y+M+D+h+m+s;
      },
      /**
     * 关闭系统刷新
     * */
    stopMaxPrice(){
      ElMessageBox.confirm('请问是否关闭更新实时价格')
        .then(() => {
          getRequestByToken("/auction/stopMaxPrice")
          this.startRealTimeRefresh=false
        })
        .catch(() => {
          // catch error
        })
    },
    /**
     * 查看已经完成的竞价
     * */
    showAuctions(){
      this.showAuctionTable=true
      getRequestByToken("/auction/showAuctionFinished")
          .then(resp=>{
            const startDateFinishs=[];
            const title=[];
            for (let i = 0; i < resp.data.data.length; i++) {
              let start=new Date(resp.data.data[i].auctionStartTime)
              let end = new Date(resp.data.data[i].auctionEndTime);
              const startDate = new Date(start);
              const endDate = new Date(end);
              const SY = startDate.getFullYear() + '-';
              const SM = (startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : startDate.getMonth() + 1) + '-';
              const SD = (startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate()) + '  ';
              //const Sh = (startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours()) + ':';
              //const Sm = (startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes());
              const EY = endDate.getFullYear() + '-';
              const EM = (endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : endDate.getMonth() + 1) + '-';
              const ED = (endDate.getDate() < 10 ? '0' + endDate.getDate() : endDate.getDate()) + '  ';
              const Eh = (endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours()) + ':';
              const Em = (endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes());
              const startTime=SY + SM + SD ;
              const startDateFinish=SY + SM.replace("-","");
              const endTime = EY + EM + ED + Eh + Em;
              this.auctionsFinished.push({"auctionStatus":resp.data.data[i].status,"auctionName":resp.data.data[i].auctionName,"auctionProperty":resp.data.data[i].auctionName.slice(0,4),"auctionSpecifications":resp.data.data[i].auctionSpecifications,"auctionBidders":resp.data.data[i].auctionBidders,"auctionStartTime":startTime,"auctionEndTime":endTime,"auctionPrice":resp.data.data[i].auctionPrice});
              startDateFinishs.push(startDateFinish);
              title.push(resp.data.data[i].auctionName.slice(0,4));
            }
              let res = [];
              let resTitle = [];
              for (let i = 0; i < startDateFinishs.length; i++) {
                  if (res.indexOf(startDateFinishs[i]) === -1) {
                      res.push(startDateFinishs[i])
                  }
              }
              for (let i = 0; i < title.length; i++) {
                  if (resTitle.indexOf(title[i]) === -1) {
                      resTitle.push(title[i])
                  }
              }
              for (const re of res) {
                  this.finishedStartDate.push({"text":re,"value":re})
              }
              for (const resTitleElement of resTitle) {
                  this.finishedAuctionProperty.push({"text":resTitleElement,"value":resTitleElement})
              }
          })
    },
      finishedStartDateHandler(value, row, column) {
          const property = column["property"];
          return row[property].slice(0,7) === value;
      },
      finishedAuctionPropertyHandler(value, row, column) {
          const property = column["property"];
          return row[property] === value;
      },

    /**
     * 详情描述删除
     * */
    deleteDescription(item,index){
      if(this.addDescription.length<=1){
        return false;
      }this.addDescription.splice(index,1)
    },
    /**
     * 详情描述增加
     * */
    add(){
      this.addDescription.push(
          {
            id:this.descriptionNum++,
            data: ""
          }
      )
    },
    /**
     * 展示结束的竞价的最高价格获得者
     * */
    showMaxPrices(){

    },
    /**
     * 导入excel
     * */
    uploadSuccess(){
      this.form={
        file: ''
      },
      this.fileList=[]
      this.reload()
      ElMessage.success("添加任务成功！！")
    },
    uploadError(){
      this.form={
        file: ''
      }
          this.fileList=[]
      ElMessage.error("添加任务失败，请修改表格内容！！")
      this.reload()
    },
    uploadExcel(){
      this.upLoadExcelVisible=true
    },
    uploadFile() {
      this.$refs.uploadDemo.submit()
    },
    /**
     * 下载
     * */
    downloadAuctions(){
      postRequestByTokenDownLoad("/auction/download",{'selectTimeStart':this.selectTime[0],'selectTimeEnd':this.selectTime[1]})
          .then(resp=>{
            if(!resp){
              ElMessage.error("文件下载错误")
            }
            let url=window.URL.createObjectURL(new Blob([resp.data],{type:'application/vnd.ms-excel'}))
            let link=document.createElement('a')
            link.style.display='none'
            link.href=url
            link.setAttribute('download',"竞价结果表")
            document.body.appendChild(link)
            link.click();
            link.remove()
          })
    },
    /**
     * 查询当前在线用户
     * */
    selectLogin(){
      this.loginUsersShow=true
      getRequestByToken("/auction/getLogin")
          .then(resp=>{
            for (let i = 0; i < resp.data.data.length; i++) {
              this.loginUsers.push({"username": resp.data.data[i].nickname})
            }
          })
    },
    showLoginUsersBeforeClose(){
      ElMessageBox.confirm('请问是否关闭当前窗口')
          .then(() => {
            this.loginUsers=[]
            this.loginUsersShow=false
          })
          .catch(() => {
            // catch error
          })
    },
      /**
       * 查询竞拍列表
       * */
    selectAuctionsList(){
        this.setStartTimeTableVisible=true;
        getRequestByToken("/auction/getNotStartAuctionOfNoStartTime")
            .then(resp=>{
                let buttonName=null;
                let buttonNameTwo=null;
                if(resp.data.data.length!=0){
                    for(let i=0;i<=resp.data.data.length;i++){
                        if(resp.data.data[i].status==0||resp.data.data[i].status==3){
                            buttonName="创建任务";
                            buttonNameTwo="删除";
                        }else {
                            buttonName="更新数据";
                            buttonNameTwo="暂停";
                        }
                        let descriptions=[];
                        descriptions=resp.data.data[i].description.split("<br/>",5);
                        this.setStartTimeTable.push({"auctionId":resp.data.data[i].auctionId,
                            "auctionNotes":resp.data.data[i].auctionNotes,
                            "auctionName": resp.data.data[i].auctionName,
                            "auctionSpecifications":resp.data.data[i].auctionSpecifications,
                            "auctionMaterials":resp.data.data[i].auctionMaterials,
                            "auctionUnit":resp.data.data[i].auctionUnit,
                            "auctionQuantity":resp.data.data[i].auctionQuantity,
                            "auctionDestination":resp.data.data[i].auctionDestination,
                            "auctionStartTime":resp.data.data[i].auctionStartTime,
                            "singleBid":resp.data.data[i].singleBid,
                            "auctionEndTime":resp.data.data[i].auctionEndTime,
                            "auctionPrice":resp.data.data[i].auctionPrice,
                            "auctionBidders":resp.data.data[i].auctionBidders,
                            "auctionStatus":resp.data.data[i].status,
                            "buttonName":buttonName,
                            "buttonNameTwo":buttonNameTwo,
                            "descriptionOne":descriptions[0],
                            "descriptionTwo":descriptions[1],
                            "descriptionThree":descriptions[2],
                            "descriptionFour":descriptions[3],
                            "descriptionFive":descriptions[4],
                            "isUpdate":false})
                    }
                }
            })
    },
    closeStartTimeTable(){
      ElMessageBox.confirm('是否关闭此界面?')
          .then(() => {
              this.setStartTimeTableVisible=false;
              this.setStartTimeTable=[];
          })
          .catch(() => {
              // catch error
          })
    },
      handleSelectionChange(row){
        this.ids=row.map(item=>item.auctionId);
        this.single=row.length!==1;
        this.multiple = !row.length
          /*for (let i = 0; i < row.length; i++) {
              this.auctionsNeedUpdate.push({
                  "auctionId":row[i].auctionId,
                  "auctionName":row[i].auctionName,
                  "auctionStartTime":row[i].auctionStartTime,
                  "singleBid":row[i].singleBid,
                  "auctionEndTime":row[i].auctionEndTime,
                  "auctionNotes":row[i].auctionNotes,
                  "auctionSpecifications":row[i].auctionSpecifications,
                  "auctionMaterials":row[i].auctionMaterials,
                  "auctionUnit":row[i].auctionUnit,
                  "auctionQuantity":row[i].auctionQuantity,
                  "auctionDestination":row[i].auctionDestination,
                  "auctionPrice":row[i].auctionPrice,
                  "auctionBidders":row[i].auctionBidders,
                  "description":row[i].descriptionOne+"<br/>"+row[i].descriptionTwo+"<br/>"+row[i].descriptionThree+"<br/>"+row[i].descriptionFour+"<br/>"+row[i].descriptionFive+"<br/>"
              })
          }*/
      },
      auctionsUpdate(){
          for (const id of this.ids) {
              for (const startTimeTableElement of this.setStartTimeTable) {
                  if(id==startTimeTableElement.auctionId){
                      this.auctionsNeedUpdate.push({
                          "auctionId":startTimeTableElement.auctionId,
                          "auctionName":startTimeTableElement.auctionName,
                          "auctionStartTime":startTimeTableElement.auctionStartTime,
                          "singleBid":startTimeTableElement.singleBid,
                          "auctionEndTime":startTimeTableElement.auctionEndTime,
                          "auctionNotes":startTimeTableElement.auctionNotes,
                          "auctionSpecifications":startTimeTableElement.auctionSpecifications,
                          "auctionMaterials":startTimeTableElement.auctionMaterials,
                          "auctionUnit":startTimeTableElement.auctionUnit,
                          "auctionQuantity":startTimeTableElement.auctionQuantity,
                          "auctionDestination":startTimeTableElement.auctionDestination,
                          "auctionPrice":startTimeTableElement.auctionPrice,
                          "auctionBidders":startTimeTableElement.auctionBidders,
                          "description":startTimeTableElement.descriptionOne+"<br/>"+startTimeTableElement.descriptionTwo+"<br/>"+startTimeTableElement.descriptionThree+"<br/>"+startTimeTableElement.descriptionFour+"<br/>"+startTimeTableElement.descriptionFive+"<br/>"
                      })
                  }
              }
          }
          for (let i = 0; i <this.auctionsNeedUpdate.length; i++) {
              if(Date.parse(this.auctionsNeedUpdate[i].auctionStartTime)>Date.parse(this.auctionsNeedUpdate[i].auctionEndTime)||this.auctionsNeedUpdate[i].auctionStartTime==null||this.auctionsNeedUpdate[i].auctionEndTime==null){
                  ElMessage.error(this.auctionsNeedUpdate[i].auctionName+"的开始或结束时间设置有问题，请重新设置!!!")
                  this.auctionsNeedUpdate=[];
                  break
              }else {
                  postRequestByTokenDownLoad("/auction/updateAuctions",this.auctionsNeedUpdate)
                  this.auctionsNeedUpdate=[];
              }
          }
      },
      updateStartTime(row){
        if(this.setStartTimeTable[row].buttonName=="创建任务"){
            if(Date.parse(this.setStartTimeTable[row].auctionStartTime)<Date.parse(new Date(this.setStartTimeTable[row].auctionEndTime))){
                postRequestByToken("/auction/updateStartTimeAndSingleBid",{
                    "auctionId":this.setStartTimeTable[row].auctionId,
                    "auctionStartTime":this.setStartTimeTable[row].auctionStartTime,
                    "singleBid":this.setStartTimeTable[row].singleBid,
                    "auctionEndTime":this.setStartTimeTable[row].auctionEndTime,
                    "auctionNotes":this.setStartTimeTable[row].auctionNotes,
                    "auctionSpecifications":this.setStartTimeTable[row].auctionSpecifications,
                    "auctionMaterials":this.setStartTimeTable[row].auctionMaterials,
                    "auctionUnit":this.setStartTimeTable[row].auctionUnit,
                    "auctionQuantity":this.setStartTimeTable[row].auctionQuantity,
                    "auctionDestination":this.setStartTimeTable[row].auctionDestination,
                    "auctionPrice":this.setStartTimeTable[row].auctionPrice,
                    "auctionBidders":this.setStartTimeTable[row].auctionBidders,
                    "description":this.setStartTimeTable[row].descriptionOne+"<br/>"+this.setStartTimeTable[row].descriptionTwo+"<br/>"+this.setStartTimeTable[row].descriptionThree+"<br/>"+this.setStartTimeTable[row].descriptionFour+"<br/>"+this.setStartTimeTable[row].descriptionFive+"<br/>"
                })
                this.setStartTimeTable[row].isUpdate='ture';
            }else {
                ElMessage.error("当前开始时间晚于结束时间，请进行更改！！")
            }
        }else {
            if(Date.parse(this.setStartTimeTable[row].auctionStartTime)<Date.parse(new Date(this.setStartTimeTable[row].auctionEndTime))){
                postRequestByToken("/auction/updateEndTime",{
                    "auctionId":this.setStartTimeTable[row].auctionId,
                    "auctionStartTime":this.setStartTimeTable[row].auctionStartTime,
                    "singleBid":this.setStartTimeTable[row].singleBid,
                    "auctionEndTime":this.setStartTimeTable[row].auctionEndTime,
                    "auctionNotes":this.setStartTimeTable[row].auctionNotes,
                    "auctionSpecifications":this.setStartTimeTable[row].auctionSpecifications,
                    "auctionMaterials":this.setStartTimeTable[row].auctionMaterials,
                    "auctionUnit":this.setStartTimeTable[row].auctionUnit,
                    "auctionQuantity":this.setStartTimeTable[row].auctionQuantity,
                    "auctionDestination":this.setStartTimeTable[row].auctionDestination,
                    "auctionPrice":this.setStartTimeTable[row].auctionPrice,
                    "auctionBidders":this.setStartTimeTable[row].auctionBidders,
                    "status":this.setStartTimeTable[row].auctionStatus,
                    "description":this.setStartTimeTable[row].descriptionOne+"<br/>"+this.setStartTimeTable[row].descriptionTwo+"<br/>"+this.setStartTimeTable[row].descriptionThree+"<br/>"+this.setStartTimeTable[row].descriptionFour+"<br/>"+this.setStartTimeTable[row].descriptionFive+"<br/>"
                })
            }else {
                ElMessage.error("当前开始时间晚于结束时间，请进行更改！！")
            }
        }
    },
      pauseAuction(row) {
          if(this.setStartTimeTable[row].buttonNameTwo=="暂停"){
              postRequestByToken("/auction/pauseAuction",{"auctionId":this.setStartTimeTable[row].auctionId})
                  .then(resp=>{
                      if (resp){
                          this.reload()
                      }
                  })
          }else if(this.setStartTimeTable[row].buttonNameTwo=="删除"){
              ElMessageBox.confirm('你确定要删除'+this.setStartTimeTable[row].auctionName+'吗?')
                  .then(() => {
                      postRequestByToken("/auction/deleteAuction",{"auctionId":this.setStartTimeTable[row].auctionId,"status":this.setStartTimeTable[row].auctionStatus})
                          .then(resp=>{
                              if(resp){
                                  delete this.setStartTimeTable[row];
                              }
                          })
                  })
                  .catch(() => {
                      // catch error
                  })
          }
      },
      //表格显示状态
      tableRowClassName({row}){
        if(row.auctionStatus===4){
            console.log(row.auctionStatus)
            return 'warning-row'
        }
        return''
      }
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-table .warning-row {
    background: #f34949;
}
.auctionBg{
  position: fixed;
  height: 100%;
  width: 130%;
  overflow-x: hidden;
  overflow-y: auto;
  background:  linear-gradient(
      230deg,
      rgb(11, 30, 110) 0%,
      rgb(12, 12, 12) 100%
  );;
}
/deep/.el-menu{
  width: 100%;
  background-color: #666666;
}
#itemCss{
  margin-left: 3%;
  border-radius:16px 16px 16px 16px;
  background:  linear-gradient(
      230deg,
      rgba(53, 57, 74, 0) 0%,
      rgb(108, 110, 107) 100%
  );;
  line-height: 0.3rem;
  height: calc(100vh * 600 / 1080);
}
</style>
