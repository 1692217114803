<template>
  <div id="login">
   <div id="bgd">
     <canvas
         id='myCanvas'
         :width='width'
         :height='height'
       >
     </canvas>
    </div>
    <div id="loginBox">
      <el-form
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          label-width="0px"
        >
        <el-form-item
            label=""
            prop="userName"
            style="margin-top:0.5rem;"
          >
          <el-row>
            <el-col :span='3' :prefix-icon="UserFilled">
              <UserFilled />
            </el-col>
            <el-col :span='21'>
              <el-input
                  type="mobile"
                  placeholder='请输入用户名'
                  v-model="loginForm.username"
                ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item
            label=""
            prop="passWord"
          >
          <el-row>
            <el-col :span='3' :prefix-icon="Lock">
              <Lock />
            </el-col>
            <el-col :span='21'>
              <el-input
                  type="password"
                  placeholder='请输入密码'
                  @keyup.enter="submitForm"
                  v-model="loginForm.password"
                ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item style="margin-top:0.1rem;">
          <el-button
              type="primary"
              round
              class="submitBtn"
              @click="submitForm"
            >登录</el-button>
          <el-button
              type="info"
              style="margin-left: 80%"
              link
              @click="this.resetPas=true"
          >重置密码</el-button>
        </el-form-item>
        <el-image :src="require('../../assets/edblogo.png')" @click="$router.push('/')" />
      </el-form>
    </div>
   </div>
  <el-dialog
      v-model="resetPas"
      title="修改密码"
      width="40%"
      :before-close="handleClose"
  >
    <el-form
        :model="resetNewPas"
        :rules="resetRules"
        ref="resetPas"
        label-width="0px">
      <el-form-item style="margin-top:0.1rem;">
       <span>输入您的用户名：</span><el-input  style="width: 80%" v-model="resetNewPas.username" type="text" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item style="margin-top:0.1rem;">
      <span>请输入旧的密码：</span><el-input  style="width: 80%" v-model="resetNewPas.oldPassword" type="text" placeholder="请输入旧密码"></el-input>
      </el-form-item>
      <el-form-item style="margin-top:0.1rem;">
      <span>请输入新的密码：</span><el-input  style="width: 80%" v-model="resetNewPas.newPassword" type="password" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item style="margin-top:0.1rem;">
      <span>再次输入新密码：</span><el-input  style="width: 80%" v-model="resetNewPas.newPasAgain" type="password" placeholder="请重新输入新密码"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetPas = false">取消</el-button>
        <el-button type="primary" @click="resetPassword">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import {UserFilled,Lock} from "@element-plus/icons-vue";
import {postKeyValueRequest, postRequestWithoutToken} from "@/utils/api";
import {ElMessage} from "element-plus";
//import axios from "axios";
export default {
  inject:['reload'],
  name: "myHome",
  data() {
    return {
      resetPas:false,
      resetNewPas:{
        username: '',
        oldPassword:'',
        newPassword:'',
        newPasAgain:''
      },
      resetRules:{
        userName:[{required:true,message :"用户名不能为空！！",trigger:"blur"}],
        oldPassword:[{required:true,message :"旧密码不能为空！！",trigger:"blur"}],
        newPasWord:[{required:true,message :"新密码不能为空！！",trigger:"blur"}],
        newPasAgain: [{required:true,message :"新密码不能为空！！",trigger:"blur"}]
      },
      canvas: null,
      context: null,
      stars: [], //星星数组
      shadowColorList: [
     "#39f",
     "#ec5707",
     "#b031d4",
     "#22e6c7",
     "#92d819",
     "#14d7f1",
     "#e23c66"
     ], //阴影颜色列表
      directionList: ["leftTop", "leftBottom", "rightTop", "rightBottom"], //星星运行方向
      speed: 50, //星星运行速度
      last_star_created_time: new Date(), //上次重绘星星时间
      Ball: class Ball {
        constructor(radius) {
          this.x = 0;
          this.y = 0;
          this.radius = radius;
          this.color = "";
          this.shadowColor = "";
          this.direction = "";
        }
        draw(context) {
           context.save();
           context.translate(this.x, this.y);
           context.lineWidth = this.lineWidth;
            let my_gradient = context.createLinearGradient(0, 0, 0, 8);
            my_gradient.addColorStop(0, this.color);
            my_gradient.addColorStop(1, this.shadowColor);
            context.fillStyle = my_gradient;
            context.beginPath();
            context.arc(0, 0, this.radius, 0, Math.PI * 2, true);
            context.closePath();

            context.shadowColor = this.shadowColor;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;
            context.shadowBlur = 10;

            context.fill();
            context.restore();
        }
        }, //工厂模式定义Ball类
      width: window.innerWidth,
      height: window.innerHeight,
      loginForm: {
         username: window.localStorage.getItem("username"),
         password: ""
      },
      loginRules: {
      userName: [{ required: true, message: "请输入用户名", trigger: blur}],
      passWord: [{ required: false, message: "请输入密码", trigger: blur }]
     }
    };
  },
  methods: {
    // 判断phone端还是pc端
    _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    //提交登录
    submitForm() {
      postKeyValueRequest('/login',{'username':this.loginForm.username,'password':this.loginForm.password}).then(resp=>{
        window.localStorage.setItem("username",this.loginForm.username)
        window.sessionStorage.setItem("userSatokenValue",resp.data.data[0].tokenValue)
        window.sessionStorage.setItem("userId",resp.data.data[0].loginId)
        window.sessionStorage.setItem("nickname",resp.data.data[1])
        window.sessionStorage.setItem("role",resp.data.data[2])
              if (this._isMobile()) {
                  this.$router.push("/auctionShow")
                  }
              else {
                  this.$router.push("/auctionShow")
              }
      })
    },
      //重复动画
      drawFrame() {
          requestAnimationFrame(this.drawFrame);
          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          this.createStar(false);
          this.stars.forEach(this.moveStar);
        },
      //展示所有的星星
      createStar(params) {
          let now = new Date();
          if (params) {
             //初始化星星
             for (let i = 0; i < 50; i++) {
                 const radius = Math.random() * 3 + 2;
                 let star = new this.Ball(radius);
                 star.x = Math.random() * this.canvas.width + 1;
                 star.y = Math.random() * this.canvas.height + 1;
                 star.color = "#ffffff";
                 star.shadowColor = this.shadowColorList[
                     Math.floor(Math.random() * this.shadowColorList.length)
                 ];
                 star.direction = this.directionList[
                     Math.floor(Math.random() * this.directionList.length)
                 ];
                 this.stars.push(star);
             }
          } else if (!params && now - this.last_star_created_time > 3000) {
            //每隔3秒重绘修改颜色其中30个球阴影颜色
            for (let i = 0; i < 30; i++) {
              this.stars[i].shadowColor = this.shadowColorList[
                  Math.floor(Math.random() * this.shadowColorList.length)
                  ];
            }
            this.last_star_created_time = now;
          }
          },
      //移动
      moveStar(star) {
        if (star.y - this.canvas.height > 0) {
          //触底
          if (Math.floor(Math.random() * 2) === 1) {
            star.direction = "leftTop";
          } else {
            star.direction = "rightTop";
          }
        } else if (star.y < 2) {
          //触顶
          if (Math.floor(Math.random() * 2) === 1) {
            star.direction = "rightBottom";
          } else {
            star.direction = "leftBottom";
          }
        } else if (star.x < 2) {
          //左边
          if (Math.floor(Math.random() * 2) === 1) {
              star.direction = "rightTop";
            } else {
              star.direction = "rightBottom";
            }
        } else if (star.x - this.canvas.width > 0) {
          //右边
          if (Math.floor(Math.random() * 2) === 1) {
              star.direction = "leftBottom";
            } else {
              star.direction = "leftTop";
            }
        }
      if (star.direction === "leftTop") {
          star.y -= star.radius / this.speed;
          star.x -= star.radius / this.speed;
        } else if (star.direction === "rightBottom") {
          star.y += star.radius / this.speed;
          star.x += star.radius / this.speed;
        } else if (star.direction === "leftBottom") {
          star.y += star.radius / this.speed;
          star.x -= star.radius / this.speed;
        } else if (star.direction === "rightTop") {
          star.y -= star.radius / this.speed;
          star.x += star.radius / this.speed;
        }
      star.draw(this.context);
      },
    /**
     * 重置密码
     * */
    resetPassword(){
      if(this.resetNewPas.newPassword!=this.resetNewPas.newPasAgain){
        ElMessage.error("请重新输入新密码，两次新密码输入不同！！")
      }else {
        postRequestWithoutToken("/reset",{"username":this.resetNewPas.username,"password":this.resetNewPas.oldPassword,"newPassword":this.resetNewPas.newPassword})
            .then(()=>{
              this.resetNewPas={
                username: '',
                oldPassword:'',
                newPassword:'',
                newPasAgain:''}
            })
        this.resetPas=false
        this.reload()
      }
    },
    handleClose(){

    }
  },
  mounted() {
    this.canvas = document.getElementById("myCanvas");
    this.context = this.canvas.getContext("2d");


    this.createStar(true);
    this.drawFrame();
  },
  components:{
    UserFilled,Lock
  }
};
</script>

<style lang='scss' scoped>
#login {
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: left top;
  background-color: #242645;
  color: rgba(255, 255, 255, 0.98);
  font-family: "Source Sans Pro";
  background-image: url("~@/assets/bg.png");
  background-size: 100vw 100vh;
  position: relative;
  #bgd {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  #loginBox {
        width: 3.5rem;
        height: 4rem;
        position: absolute;
        border-radius:16px 16px 16px 16px;
        top: 2rem;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        padding: 50px 40px 40px 40px;
        box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
        opacity: 1;
        background: linear-gradient(
         230deg,
         rgba(53, 57, 74, 0) 0%,
         rgb(102, 100, 100) 80%
       );
    /deep/ .inps input {
        border: none;
        color: #880c0c;
        background-color: transparent;
        font-size: 12px;
      }
        .submitBtn {
        background-color: transparent;
        color: #39f;
        width: 200px;
      }
        .iconfont {
         color: #fff;
        }
    }
  }
</style>
