<template>
  <router-view v-if="isRouterView" />
</template>

<script>

export default {
  name: 'App',
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterView:true
    }
  },
  methods: {
      //自动刷新
    reload(){
      this.isRouterView=false
      this.$nextTick(function (){
        this.isRouterView=true
      })
    },
  },
  components: {
  },
  mounted() {
  }
}
</script>

<style>
#app {

}
</style>
