<script>
export default {
  name:'energySaving',
  data(){
      return{
        width:document.body.clientWidth,
        chooseLanguage:window.localStorage.getItem('language'),
      }
  },
  methods:{

  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else {
        return this.width = 1366
      }
    }
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  },
}
</script>

<template>
<div style="margin: 0;padding: 0">
  <div :style="{width:this.width+'px'}" class="firstPart">
    <div :style="{width:(this.width/2543)*1364+'px'}">
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{height:(this.width/2543)*710+'px',width:(this.width/2543)*240+'px'}" class="energySavingImgOne"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{height:(this.width/2543)*710+'px',width:(this.width/2543)*240+'px'}" class="energySavingImgOneEn"></div>
      <div :style="{marginLeft:(this.width/2543)*36+'px'}">
        <div :style="{width:(this.width/2543)*872+'px',height:(this.width/2543)*205+'px',marginBottom:(this.width/2543)*8+'px'}" style="background-color:  #d20000;float: left;border-radius: 5px;display: flex;justify-content: center;align-items: center">
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*724+'px',height:(this.width/2543)*129+'px',fontSize:(this.width/2543)*20+'px',lineHeight:2}" style="font-weight: bolder;font-family: '华文细黑';color: white">
          <span>
            爱登堡电梯选配其自主研发的集成四象限变频器，利用上升和下降的不平衡重量，将势能和动能转化为电能，并将所发的电给电梯周边的用电设备使用，减少楼宇设备向外界电网获取的电能，从而降低电费支出。
          </span>
          </p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*724+'px',height:(this.width/2543)*129+'px',fontSize:(this.width/2543)*14+'px',lineHeight:2}" style="font-weight: bolder;font-family: '华文细黑';color: white">
            Edunburgh Elevator is equipped with its independently developed integrated four quadrant frequency converter,Utilize the unbalanced weight of rising and falling to convert potential and kinetic energy into electrical energy,And provide the generated electricity to the electrical equipment around the elevator, reducing the amount of electricity that building equipment obtains from the external power grid, thereby reducing electricity bills.
          </p>
        </div>
        <div v-show="this.chooseLanguage==='CHINESE'" :style="{marginTop:(this.width/2543)*8+'px',width:(this.width/2543)*900+'px',height:(this.width/2543)*330+'px',left:(this.width/2543)*90+'px'}" style="display: flex;justify-content: center;align-items: center"  class="energySavingImgTwo"></div>
        <div v-show="this.chooseLanguage!=='CHINESE'" :style="{marginTop:(this.width/2543)*8+'px',width:(this.width/2543)*900+'px',height:(this.width/2543)*330+'px',left:(this.width/2543)*90+'px'}" style="display: flex;justify-content: center;align-items: center"  class="energySavingImgTwoEn"></div>
        <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*541+'px',height:(this.width/2543)*259+'px',marginLeft:(this.width/2543)*510+'px'}" style="display: flex;justify-content: center" class="energySavingImgThree"></div>
        <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*541+'px',height:(this.width/2543)*259+'px',marginLeft:(this.width/2543)*510+'px'}" style="display: flex;justify-content: center" class="energySavingImgThreeEn"></div>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*728+'px'}" style="background-color: #eaf0dd" class="secondPart">
    <p :style="{fontSize:(this.width/2543)*40+'px',width:this.width+'px',paddingTop:(this.width/2543)*40+'px'}">
      <span v-show="this.chooseLanguage==='CHINESE'" class="partTitle"><span style="color:  #d20000">发电的电梯，</span>大楼供耗电示意图</span>
      <span v-show="this.chooseLanguage!=='CHINESE'" class="partTitle"><span style="color:  #d20000">Power generation elevator,</span> building power supply and consumption schematic diagram</span>
    </p>
    <div :style="{width:this.width+'px',height:(this.width/2543)*334+'px'}" style="display: flex;justify-content: center;align-items: center">
      <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*554+'px',marginTop:(this.width/2543)*180+'px'}" style="display: flex;justify-content: center;align-items: center;background-color: #296c08">
        <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1333+'px',height:(this.width/2543)*526+'px'}" class="energySavingImgFour"></div>
        <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1333+'px',height:(this.width/2543)*526+'px'}" class="energySavingImgFourEn"></div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.firstPart{
  display: flex;
  justify-content: center;
  .energySavingImgOne{
    float: left;
    background-image: url("/src/assets/imgs/about/energySavingImgOne.png");
    background-size: 100% 100%;
  }
  .energySavingImgOneEn{
    float: left;
    background-image: url("/src/assets/imgs/about/energySavingImgOneEn.png");
    background-size: 100% 100%;
  }
  .energySavingImgTwo{
    background-image: url("/src/assets/imgs/about/energySavingImgTwo.png");
    background-size: 100% 100%;
  }
  .energySavingImgTwoEn{
    background-image: url("/src/assets/imgs/about/energySavingImgTwoEn.png");
    background-size: 100% 100%;
  }
  .energySavingImgThree{
    background-image: url("/src/assets/imgs/about/energySavingImgThree.png");
    background-size: 100% 100%;
  }
  .energySavingImgThreeEn{
    background-image: url("/src/assets/imgs/about/energySavingImgThreeEn.png");
    background-size: 100% 100%;
  }
}
.secondPart{
  text-align: center;
  .energySavingImgFour{
    background-image: url('/src/assets/imgs/about/energySavingImgFour.png');
    background-size: 100% 100%;
  }
  .energySavingImgFourEn{
    background-image: url('/src/assets/imgs/about/energySavingImgFourEn.png');
    background-size: 100% 100%;
  }
}
</style>
