<script>


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'companyIntroduction',
  inject:['reload'],
  data() {
    return {
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
      isPlaying: false,
      showControls: false,
      width:document.body.clientWidth,
      progress: 0,
      src: require('/src/assets/imgs/about/aboutCompany.mp4'),
      srcEn: require('/src/assets/imgs/about/aboutCompanyEn.mp4'),
      poster: require('/src/assets/imgs/about/videoCover.png'),
      videoPlayColor:'white',
    }
  },
  methods:{
    clickVideoPlay(){
      this.isPlaying='true'
    },
    togglePlay() {
      const video = this.$refs.video;
      if (video.paused) {
        video.play();
        this.isPlaying = true;
      } else {
        video.pause();
        this.isPlaying = false;
      }
    },
    updateProgress() {
      const video = this.$refs.video;
      const progressBar = this.$refs.progressBar;
      const percent = (video.currentTime / video.duration) * 100;
      this.progress = percent;
      progressBar.style.setProperty('--progress', `${percent}%`);
    },
    closeVideo(){
      this.$refs.videoPlayer.pause()
    },
    openVideo(){
      this.$refs.videoPlayer.play()
    },
    openPdf(){
      window.open('/pdfView');
    },
    chooseLanguageByCh(){
      window.sessionStorage.setItem('language','CHINESE')
      this.chooseLanguage='CHINESE'
    },
    chooseLanguageByEn(){
      window.sessionStorage.setItem('language','ENGLISH')
      this.chooseLanguage='ENGLISH'
    },
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
    /*const video = this.$refs.video;
    video.addEventListener('play', () => {
      this.isPlaying = true;
      this.showControls = true;
      this.$refs.progressBar.style.setProperty('--transition', 'none');
      this.interval = setInterval(this.updateProgress, 1000 / 60);
    });
    video.addEventListener('pause', () => {
      this.isPlaying = false;
      clearInterval(this.interval);
    });
    video.addEventListener('ended', () => {
      this.isPlaying = false;
      clearInterval(this.interval);
      this.progress = 0;
      this.$refs.progressBar.style.setProperty('--transition', 'width 0.3s ease');
    });
    let videoNeed = document.getElementById("myVideo");
    videoNeed.currentTime = 3; // 设置从第三秒开始播放*/
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
  <div style="padding: 0;margin: 0;min-width: 1366px">
    <el-menu
        class="topNavBar"
        mode="horizontal"
        :ellipsis="false"
    >
      <el-menu-item index="0" @click="$router.push('/')">
        <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
      </el-menu-item>
      <div class="flex-grow" />
      <el-select
          v-model="chooseLanguage"
          @change="chooseCNorEN"
          class="m-2"
          placeholder="中/EN"
          size="large"
          :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
      >
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
      <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
      <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
      <el-sub-menu index="2" @click="$router.push('/technology/safe')">
        <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
        <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
        <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
        <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
      </el-sub-menu>
      <el-sub-menu index="3" @click="$router.push('/product')">
        <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
        <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
        <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
        <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
        <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
        <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
        <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
        <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
      <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="5" style="display: flex;" disabled>
        <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
        <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
      </el-menu-item>

    </el-menu>
    <div :style="{width:this.width+'px'}" class="firstPart">
      <div :style="{width:(this.width/2543)*1364+'px'}"  style="height: 100%;position: absolute;z-index: 1">
        <div :style="{width:(this.width/2543)*657+'px'}" class="firstPartWordsBox">
          <div :style="{width:(this.width/2543)*620+'px'}">
            <p>
              <span :style="{fontSize:(this.width/2543)*40+'px'}" class="underline">关于爱登堡 | About us</span>
            </p>
            <p>
              <span :style="{fontSize:(this.width/2543)*20+'px'}" class="firstPartWordsText"><span v-show="this.chooseLanguage==='CHINESE'">上海爱登堡电梯集团是一家专注于高端电梯研发、制造、安装及售后服务的全产业链集团化股份公司，集团产业涵盖电梯/扶梯/车库智造、智能楼宇、科技产业园、教育培训、鼎恒晟网络科技五大板块。</span><p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px',lineHeight:1.2}">Shanghai Edunburgh Elevator Group INC. is a industrial chain conglomerate that fully focuses on the research and development, manufacturing, installation, and after-sales service of high-end elevators.The group's industry covers five major sectors: intelligent manufacturing of elevators/escalators/garages, intelligent buildings, technology industrial parks, education and training, and Dinghengsheng Network Technology.</p></span>
            </p>
            <p><el-button :style="{width:(this.width/2543)*194+'px',fontSize:(this.width/2543)*16+'px',fontFamily:'宋体'}" @click="openPdf"><span v-show="this.chooseLanguage==='CHINESE'">浏览集团简介 >></span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*14+'px'}">Browse Group Profile >></span></el-button></p>
          </div>
        </div>
      </div>
      <el-image :src="require('/src/assets/imgs/about/bg.png')" />
    </div>
    <div :style="{height:(this.width/2543)*406+'px',width:this.width+'px'}" class="secondPart">
      <div :style="{width:(this.width/2543)*1364+'px'}" style="position: absolute;z-index: 1" class="secondPartWordsBox">
        <div :style="{width:(this.width/2543)*670.1+'px'}" style="float: left">
          <span :style="{fontSize:(this.width/2543)*20+'px',lineHeight:(this.width/2543)*40+'px'}" class="secondPartWordsText"><span v-show="this.chooseLanguage==='CHINESE'">&emsp;&emsp;在电梯制造业领域，爱登堡以德国和上海为<span style="color:  #d20000">双研发中心</span>构建<span style="color:  #d20000">四大智造基地</span>，在电梯<span style="color:  #d20000">安全、绿色节能及智慧服务</span>方面始终处于行业领先水平，拥有<span style="color:  #d20000">100多</span>项专利技术，设立院士专家工作站，是上海市创新型企业，荣获<span style="color:  #d20000">上海市科技进步奖</span>。是政府采购电梯<span style="color:  #d20000">首选安全、首选服务和首选节能品牌</span>。</span><p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px',lineHeight:1.2}">&emsp;&emsp;In the field of elevator manufacturing, Edunburgh has built <span style="color:  #d20000">four major intelligent manufacturing bases as having a particular centre in Germany and Shanghai for development and research center</span>.Always leading the industry in elevator <span style="color:  #d20000">safety, green energy conservation, and smart services</span>, with <span style="color:  #d20000">over 100</span> patented technologies,Established an academician and expert workstation, it is an <span style="color:  #d20000">innovative enterprise in Shanghai</span> and has won the Shanghai Science and Technology Progress Award.<span style="color:  #d20000">It is the preferred safety, service, and energy-saving brand for government procurement elevators</span>.</p></span>
          <br v-show="this.chooseLanguage==='CHINESE'">
<!--          <span :style="{fontSize:(this.width/2543)*20+'px',lineHeight:(this.width/2543)*40+'px'}" class="secondPartWordsText"><span v-show="this.chooseLanguage==='CHINESE'">&emsp;&emsp;最新研发的人工智能绿色电梯被评为<span style="color:  #d20000">“上海品牌”</span>，是行业首先把“人工智能”和“绿色节能技术”运用到电梯产品的创新者。</span><p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px',lineHeight:1.2}">&emsp;&emsp;The newly developed artificial intelligence green elevator has been awarded the <span style="color:  #d20000">"Shanghai Brand"</span> and is the first innovator in the industry to apply "artificial intelligence" and "green energy-saving technology" to elevator products.</p></span>-->
        </div>
        <div :style="{width:(this.width/2543)*581+'px',height:(this.width/2543)*318+'px',marginLeft:(this.width/2543)*112+'px'}" class="videoPicture">
          <div class="videoPlay" :style="{width:(this.width/2543)*581+'px',height:(this.width/2543)*318+'px',fontSize:(this.width/2543)*100+'px',color:this.videoPlayColor}" @mouseenter="this.videoPlayColor='red'" @mouseleave="this.videoPlayColor='white'" @click="clickVideoPlay" style="display: flex;justify-content: center;align-items: center;">
            <svg t="1687767738004" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3685" width="64" height="64"><path d="M512 96C282.624 96 96 282.624 96 512s186.624 416 416 416 416-186.624 416-416S741.376 96 512 96z m0 768C317.92 864 160 706.08 160 512S317.92 160 512 160s352 157.92 352 352-157.92 352-352 352z" p-id="3686" :fill="this.videoPlayColor"></path><path d="M466.816 324.96a32 32 0 0 0-50.816 25.888v339.776a32 32 0 0 0 50.816 25.856l233.6-169.888a32 32 0 0 0 0-51.776l-233.6-169.856z" p-id="3687" :fill="this.videoPlayColor"></path></svg>
          </div>
        </div>
      </div>
    </div>
    <div :style="{height:(this.width/2543)*801+'px',width:this.width+'px'}" class="thirdPart">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <p :style="{fontSize:(this.width/2543)*40+'px'}" style="text-align: center">
          <span class="partTitle"><span v-show="this.chooseLanguage==='CHINESE'">全球战略布局</span><span v-show="this.chooseLanguage!=='CHINESE'">Global strategic layout</span></span>
        </p>
        <div>
          <div :style="{width:(this.width/2543)*667+'px',height:(this.width/2543)*317+'px',float: 'left'}">
            <div :style="{width:(this.width/2543)*667+'px',height:(this.width/2543)*277+'px'}" class="adbOne"></div>
            <div :style="{width:(this.width/2543)*667+'px',height:(this.width/2543)*40+'px',fontSize:(this.width/2543)*16+'px',}" style="align-items: center;background-color: #ebebeb; display: flex;justify-content: center;font-family: '宋体'">
              <span v-show="this.chooseLanguage==='CHINESE'">爱登堡电梯集团
                <span style="color:  #d20000">
                  （全球总部·上海）
                </span>
              </span>
              <span v-show="this.chooseLanguage!=='CHINESE'">Edunburgh Elevator Group
                <span style="color:  #d20000">
                  (Global Headquarters · Shanghai)
                </span>
              </span>
            </div>
          </div>
          <div :style="{width:(this.width/2543)*666+'px',height:(this.width/2543)*317+'px',marginLeft:(this.width/2543)*30+'px',float: 'left'}">
            <div :style="{width:(this.width/2543)*666+'px',height:(this.width/2543)*277+'px'}" class="adbTwo"></div>
            <div :style="{width:(this.width/2543)*666+'px',height:(this.width/2543)*40+'px',fontSize:(this.width/2543)*16+'px',}" style="align-items: center;background-color: #ebebeb; display: flex;justify-content: center;font-family: '宋体'">
              <span v-show="this.chooseLanguage==='CHINESE'">
                 爱登堡电梯
                <span style="color:  #d20000">
                  （德国）
                </span>有限公司
              </span>
              <span v-show="this.chooseLanguage!=='CHINESE'">
                 Edunburgh Elevator
                <span style="color:  #d20000">
                  (Germany)
                </span>INC.
              </span>
            </div>
          </div>
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*317+'px',marginTop:(this.width/2543)*30+'px',float: 'left'}">
            <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*277+'px'}" class="adbThree"></div>
            <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*40+'px',fontSize:(this.width/2543)*16+'px',}" style="align-items: center;background-color: #ebebeb; display: flex;justify-content: center;font-family: '宋体'">
              <span v-show="this.chooseLanguage==='CHINESE'">
                 爱登堡电梯
                <span style="color:  #d20000">
                  （江苏）
                </span>有限公司
              </span>
              <span v-show="this.chooseLanguage!=='CHINESE'">
                 Edunburgh Elevator Group
                <span style="color:  #d20000">
                  (Jiangsu)
                </span>INC.
              </span>
            </div>
          </div>
          <div :style="{width:(this.width/2543)*435+'px',height:(this.width/2543)*317+'px',marginTop:(this.width/2543)*30+'px',marginLeft:(this.width/2543)*30+'px',float: 'left'}">
            <div :style="{width:(this.width/2543)*435+'px',height:(this.width/2543)*277+'px'}" class="adbFour"></div>
            <div :style="{width:(this.width/2543)*435+'px',height:(this.width/2543)*40+'px',fontSize:(this.width/2543)*16+'px',}" style="align-items: center;background-color: #ebebeb; display: flex;justify-content: center;font-family: '宋体'">
             <span v-show="this.chooseLanguage==='CHINESE'">
                 爱登堡电梯
                <span style="color:  #d20000">
                  （湖北）
                </span>有限公司
              </span>
             <span v-show="this.chooseLanguage!=='CHINESE'">
                 Edunburgh Elevator Group
                <span style="color:  #d20000">
                  (Hubei)
                </span>INC.
              </span>
            </div>
          </div>
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*317+'px',marginTop:(this.width/2543)*30+'px',marginLeft:(this.width/2543)*30+'px',float: 'left'}">
            <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*277+'px'}" class="adbFive"></div>
            <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*40+'px',fontSize:(this.width/2543)*16+'px',}" style="align-items: center;background-color: #ebebeb; display: flex;justify-content: center;font-family: '宋体'">
             <span v-show="this.chooseLanguage==='CHINESE'">
                 爱登堡电梯
                <span style="color:  #d20000">
                  （贵州）
                </span>有限公司
              </span>
             <span v-show="this.chooseLanguage!=='CHINESE'">
                 Edunburgh Elevator Group
                <span style="color:  #d20000">
                  (Guizhou)
                </span>INC.
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div :style="{height:(this.width/2543)*642+'px',width:this.width+'px'}" class="fourthPart">
      <p :style="{fontSize:(this.width/2543)*40+'px'}" style="text-align: center">
        <span class="partTitle"><span v-show="this.chooseLanguage==='CHINESE'">爱登堡集团的<span style="color: #d20000;">发展</span></span><span v-show="this.chooseLanguage!=='CHINESE'">The <span style="color: #d20000;">Development</span> of the Edunburgh Elevator Group INC.</span></span>
      </p>
      <div :style="{height:(this.width/2543)*473+'px'}" class="fourthPartBox">
        <div :style="{width:(this.width/2543)*1364+'px'}">
          <div :style="{width:(this.width/2543)*603.9+'px',height:(this.width/2543)*473+'px'}" style="display: flex;justify-content: center;align-items: center;">
            <p :style="{width:(this.width/2543)*603.9+'px',fontSize:(this.width/2543)*20+'px',lineHeight:(this.width/2543)*48+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            <span v-show="this.chooseLanguage==='CHINESE'">
               &emsp;&emsp;<span style="color:  #d20000">爱登堡临港智能楼宇科技大厦</span>项目位于临港新片区的核心区，该项目定位为国际人工智能绿色楼宇设备研发中心，在爱登堡电梯集团的发展进程中具有里程碑意义。它的建成，将进一步完善、增强新区楼宇设备人工智能绿色为特点的城市功能，将引领智能化楼宇设备向人工智能和低碳方向发展，同时大大的提高爱登堡品牌的知名度和美誉度，不仅为新片区建设步伐作出重要贡献，也将有力的推动人工智能和绿色环保技术的快速发展。
            </span>
            </p>
            <p :style="{width:(this.width/2543)*603.9+'px',fontSize:(this.width/2543)*20+'px',lineHeight:1.2}" v-show="this.chooseLanguage!=='CHINESE'">
              &emsp;&emsp;<span style="color:  #d20000">The Edunburgh Lingang Intelligent Building Technology Building project</span> is located in the core area of the Lingang New Area,This project is positioned as an international research and development center for artificial intelligence green building equipment, and has milestone significance in the development process of the Edunburgh Elevator Group.Its completion will further improve and enhance the urban functions characterized by artificial intelligence and green building equipment in the new area, we will lead the development of intelligent building equipment towards artificial intelligence and low-carbon direction, while greatly improving the awareness and reputation of the Edunburgh brand.Not only we will make important contributions to the construction pace of the new area, but we will also effectively promote the rapid development of artificial intelligence and green environmental protection technology.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :style="{height:(this.width/2543)*900+'px',width:this.width+'px'}" class="fifthPart">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <p :style="{fontSize:(this.width/2543)*40+'px'}" style="text-align: center">
          <span class="partTitle"><span v-show="this.chooseLanguage==='CHINESE'">集团荣誉</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Honors</span></span>
        </p>
        <div style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="one">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            合同信用等级AAA级
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Contract credit rating AAA
          </p>
        </div>
        <div style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px',marginLeft:(this.width/2543)*30+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="two">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            上海市科技进步奖
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Shanghai Science and Technology Progress Award
          </p>
        </div>
        <div style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px',marginLeft:(this.width/2543)*30+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="three">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            政府采购首选安全、首选服务、首选节能品牌
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Government procurement prioritizes safety, services, and energy-saving brands
          </p>
        </div>
        <div :style="{marginTop:(this.width/2543)*30+'px'}" style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="four">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            全国电梯行业质量领先品牌
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Leading Quality Brand in the National Elevator Industry
          </p>
        </div>
        <div :style="{marginTop:(this.width/2543)*30+'px'}" style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px',marginLeft:(this.width/2543)*30+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="five">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            中国质量信用企业AAA+级
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Chinese Quality Credit Enterprise AAA+
          </p>
        </div>
        <div :style="{marginTop:(this.width/2543)*30+'px'}" style="float: left;text-align: center">
          <div :style="{width:(this.width/2543)*434+'px',height:(this.width/2543)*275+'px',marginLeft:(this.width/2543)*30+'px'}" style=" box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)" class="six">
          </div>
          <p :style="{fontSize:(this.width/2543)*16+'px'}" v-show="this.chooseLanguage==='CHINESE'">
            政府采购百强电梯维修保养企业
          </p>
          <p :style="{fontSize:(this.width/2543)*12+'px'}" v-show="this.chooseLanguage!=='CHINESE'">
            Top 100 Government Procurement Elevator Maintenance Enterprises
          </p>
        </div>
      </div>
    </div>
    <div :style="{width:this.width+'px'}" class="sixthPart">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <p :style="{fontSize:(this.width/2543)*40+'px'}" style="text-align: center">
          <span class="partTitle"><span v-show="this.chooseLanguage==='CHINESE'">部分（战略）合作伙伴</span><span v-show="this.chooseLanguage!=='CHINESE'">Partial (strategic) partners</span></span>
        </p>
        <div style="text-align: center">
          <el-image :src="require('/src/assets/imgs/about/hezuohuoban.png')" />
        </div>
      </div>
    </div>
    <div :style="{marginTop:(this.width/2543)*30+'px',width:this.width+'px'}" class="titleEight">
      <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
    </div>
    <el-dialog style="background-color: rgba(0,0,0,0.5)" :fullscreen="true" width="100%" v-model="this.isPlaying" @close="closeVideo" @open="openVideo">
      <video autoplay ref="videoPlayer" :src="this.chooseLanguage==='CHINESE'?this.src:this.srcEn" style="width: 100%"></video>
    </el-dialog>
  </div>
</template>

<style  lang="scss" scoped>
.flex-grow{
  flex-grow: 0.2;
}
.floatWindow {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vw * 50 / 1920);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.floatWindowMobile{
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vh/10);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.topNavBar{
  width: 100%;
  height: auto;
  z-index: 1;
}
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
/deep/ .el-dialog__headerbtn{
  background-color: white;
}
.firstPart{
  display: flex;
  justify-content: center;
  .firstPartWordsBox{
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px 20px 20px 20px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .underline {
    font-family: 华文细黑;
    font-weight: bolder;
    position: relative;
    z-index: 1;
    display: inline-block;
  }

  .underline::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    height: 5px;
    background-color:  #d20000;
    z-index: -1;
  }
  .firstPartWordsText{
    line-height: 2.5;
  }
  .el-button{
    font-size: 15px;
    background-color:  #d20000;
    border-radius: 6px;
    border-color: rgba(0, 0, 0, 0);
    color: white;
    font-weight: bolder;
  }
}
.secondPart{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assets/imgs/about/partTwoBg.png");
  background-size: 100% 100%;
  .secondPartWordsBox{
    .videoPicture{
      text-align: center;
      float: left;
      justify-content: center;
      position: relative;
      background-image: url("/src/assets/imgs/about/videoCover.png");
      background-size: 100% 100%;
    }
    .secondPartWordsText{
    }
  }
}
.thirdPart{
  display: flex;
  justify-content: center;
  .adbOne{
    background-image: url("/src/assets/imgs/about/shanghaiadb.png");
    background-size: 100% 100%;
  }
  .adbTwo{
    background-image: url("/src/assets/imgs/about/deguoadb.png");
    background-size: 100% 100%;
  }
  .adbThree{
    background-image: url("/src/assets/imgs/about/jiangsuadb.png");
    background-size: 100% 100%;
  }
  .adbFour{
    background-image: url("/src/assets/imgs/about/hubeiadb.png");
    background-size: 100% 100%;
  }
  .adbFive{
    background-image: url("/src/assets/imgs/about/guiyangadb.png");
    background-size: 100% 100%;
  }
}
.fourthPart{
  .fourthPartBox{
    display: flex;
    justify-content: center;
    background-image: url("/src/assets/imgs/about/partFourBg.png") ;
    background-size: 100% 100%;
  }
  .fourthPartWords{
    margin-left: .5rem;
    font-size: .35rem;
    line-height: 2.5;
  }
}
.fifthPart{
  display: flex;
  justify-content: center;
  .one{
    background-image: url('/src/assets/imgs/about/shanghaipinpai.png');
    background-size: 100% 100%;
  }
  .two{
    background-image: url('/src/assets/imgs/about/shanghaikejijiang.png');
    background-size: 100% 100%;
  }
  .three{
    background-image: url('/src/assets/imgs/about/shouxuannpingpai.png');
    background-size: 100% 100%;
  }
  .four{
    background-image: url('/src/assets/imgs/about/lingxianpinpai.png');
    background-size: 100% 100%;
  }
  .five{
    background-image: url('/src/assets/imgs/about/zhongguozhiliang.png');
    background-size: 100% 100%;
  }
  .six{
    background-image: url('/src/assets/imgs/about/baoyangqiye.png');
    background-size: 100% 100%;
  }
}
.sixthPart{
  display: flex;
  justify-content: center;
  background-color: rgba(190, 190, 190, 0.4);
}
.titleEight{
  bottom:0;
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
</style>
