import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'
import router from './router'
import './assets/icon/index'
import * as echarts from 'echarts'
import store from '/src/utils/store'
// px2rem 自适应npm  install
import 'lib-flexible'
import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const app = createApp(App)

router.beforeEach((to, from, next) => {
    if(to.fullPath==="/villaLadder"){
        // chrome
        document.body.scrollTop = 0
        // firefox
        document.documentElement.scrollTop = 0
        // safari
        window.pageYOffset = 0
    }
    next()
})
app.mount('#app')
// 定义全局变量
if(document.body.clientWidth>1366){
    app.config.globalProperties.$globalWidth = document.body.clientWidth
}else if(document.body.clientWidth<1366) {
    app.config.globalProperties.$globalWidth = 1366
}
app.config.globalProperties.$echarts = echarts
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

createApp(App)
    .use(router).use(ElementPlus).use(VueQuillEditor, /* { default global options } */).use(store).mount('#app')
