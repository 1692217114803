<script>
// eslint-disable-next-line no-unused-vars
import * as echarts from 'echarts'
// eslint-disable-next-line no-unused-vars
import router from "@/router";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:'intelligence',
    data(){
        return{
          width:document.body.clientWidth,
          chooseLanguage:window.localStorage.getItem('language'),
          src: null,
          poster: require('/src/assets/imgs/about/videoCover.png'),
          videoPlayColor:'white',
          videoPlayTwoColor:'white',
          isPlaying: false,
        }
    },
    methods:{
      clickVideoPlayOne(){
        if(this.chooseLanguage==='CHINESE'){
         this.src=require('/src/assets/imgs/about/intelligenceVideoOne.mp4')
        }else {
          this.src=require('/src/assets/imgs/about/intelligenceVideoOneEn.mp4')
        }
        this.isPlaying='true'
      },
      closeVideo(){
        this.$refs.videoPlayer.pause()
      },
      openVideo(){
        this.$refs.videoPlayer.play()
      },
      clickVideoPlayTwo(){
        if(this.chooseLanguage==='CHINESE'){
          this.src=require('/src/assets/imgs/about/intelligenceVideoTwo.mp4')
        }else {
          this.src=require('/src/assets/imgs/about/intelligenceVideoTwoEn.mp4')
        }
        this.isPlaying='true'
      },
    },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  },
}
</script>

<template>
<div style="margin: 0;padding: 0">
  <div :style="{width:this.width+'px'}" class="firstPart">
    <div :style="{width:(this.width/2543)*1364+'px'}" style="justify-content: center;display: flex;align-items: center;flex-direction: column;">
      <div :style="{width:(this.width/2543)*1034+'px',height:(this.width/2543)*197+'px',marginBottom:(this.width/2543)*90+'px'}" style="background-color:  #d20000;border-radius: 5px;justify-content: center;display: flex;align-items: center">
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*944.83+'px',height:(this.width/2543)*120+'px'}">
          <span :style="{fontSize:(this.width/2543)*20+'px'}" style="font-weight: bolder;color: white;line-height: 2">爱登堡人工智能绿色曳引驱动乘客电梯通过搭载先进传感器、控制器、执行器等装置，结合信息通信、互联网、大数据、云计算、传感检测等新技术，为电梯赋予了人类智慧特征：<span :style="{fontSize:(this.width/2543)*24+'px'}" style="color: yellow;text-decoration: underline">会看、会听、会感知、会通讯、会说话、会推理、会决策、能执行，</span>并确保乘客<span :style="{fontSize:(this.width/2543)*24+'px'}" style="color: yellow;text-decoration: underline">健康</span>乘梯！</span>
        </p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*944.83+'px',height:(this.width/2543)*120+'px'}">
          <span :style="{fontSize:(this.width/2543)*14+'px'}" style="font-weight: bolder;color: white;">The Edunburgh artificial intelligence green traction driven passenger elevator is equipped with advanced sensors, controllers, actuators, and other devices,Combining new technologies such as information communication, internet, big data, cloud computing, and sensor detection, elevators have been endowed with human intelligence features:<span :style="{fontSize:(this.width/2543)*24+'px'}" style="color: yellow;text-decoration: underline">We will watch, listen, perceive, communicate, speak, reason, make decisions, execute</span>, and ensure <span :style="{fontSize:(this.width/2543)*20+'px'}" style="color: yellow;text-decoration: underline">the health </span>of passengers who taking our elevators!</span>
        </p>
      </div>
      <div :style="{width:(this.width/2543)*751+'px',height:(this.width/2543)*321+'px',marginBottom:(this.width/2543)*93+'px'}" class="intelligenceImgOne"></div>
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1026+'px',height:(this.width/2543)*464+'px',marginBottom:(this.width/2543)*71+'px'}" class="intelligenceImgTwo"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1026+'px',height:(this.width/2543)*464+'px',marginBottom:(this.width/2543)*71+'px'}" class="intelligenceImgTwoEn"></div>
      <div :style="{width:(this.width/2543)*900+'px',height:(this.width/2543)*286.31+'px',marginLeft:(this.width/2543)*150+'px'}" >
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">音视频智能识别、检测和分析电梯轿厢内外的各种行为特征</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">电梯控制系统分析、判断并确定电梯的实时工况</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">环控设备检测电梯运行的各类环境要素</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">通过对各种结果和数据进行综合评估、分析和判断后，切换、控制电梯的运行模式</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">基于人脸识别的智能门禁系统，通过信息共享与电梯控制系统实现互联互通</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">Intelligent recognition, detection, and analysis of various behavioral characteristics inside and outside elevator cars through audio and video technology</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">The elevator control system can analyze, judge, and determine the real-time operating conditions </p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">Environmental control equipment detects various environmental factors of elevator operation</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">After comprehensive evaluation, analysis, and judgment of various results and data, switch and control the operating mode of the elevator</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceFirstPartWords">Intelligent access control system based on facial recognition, interconnected through information sharing and elevator control system</p>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*888+'px',marginBottom:(this.width/2543)*50+'px'}" style="background-color: #eaf0dd" class="secondPart">
    <div :style="{width:(this.width/2543)*1364+'px',height:100+'%'}">
      <div :style="{width:(this.width/2543)*1364*0.5+'px',height:100+'%',float:'right'}">
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*30+'px',paddingTop:(this.width/2543)*39+'px'}" style="text-align: center">
          <span class="partTitle">人行闸机与电梯联动派梯</span>
          <br>
          <br>
          <span>——免去乘客手动选层</span>
        </p>
        <p  v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*24+'px',paddingTop:(this.width/2543)*39+'px'}" style="text-align: center">
          <span class="partTitle">Linkage dispatching of pedestrian gate and elevator</span>
          <br>
          <br>
          <span>——Exempt passengers from manual layer selection</span>
        </p>
        <div :style="{width:(this.width/2543)*721+'px',height:(this.width/2543)*348+'px',fontSize:(this.width/2543)*100+'px',color:this.videoPlayColor}" @mouseenter="this.videoPlayColor='red'" @mouseleave="this.videoPlayColor='white'" @click="clickVideoPlayOne" class="intelligenceImgFour">
          <div :style="{height:100+'%',width:100+'%'}" style="display: flex;justify-content: center;align-items: center;">
            <svg t="1687767738004" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3685" width="64" height="64"><path d="M512 96C282.624 96 96 282.624 96 512s186.624 416 416 416 416-186.624 416-416S741.376 96 512 96z m0 768C317.92 864 160 706.08 160 512S317.92 160 512 160s352 157.92 352 352-157.92 352-352 352z" p-id="3686" :fill="this.videoPlayColor"></path><path d="M466.816 324.96a32 32 0 0 0-50.816 25.888v339.776a32 32 0 0 0 50.816 25.856l233.6-169.888a32 32 0 0 0 0-51.776l-233.6-169.856z" p-id="3687" :fill="this.videoPlayColor"></path></svg>
          </div>
        </div>
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">①，乘客采用人脸识别方式通过人行闸机时，</p>
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">②，电梯系统感知到他是19楼的办公人员，派梯（B梯）。</p>
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">③，电梯大厅显示屏上显示B梯去19楼，无需乘客再按外呼按钮。</p>
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">④，乘客进入电梯，使用语音输入“电梯关门”或智能视觉系统判断厅外无乘客进入电梯自动关门，不需要用手按关门按钮。</p>
        <p v-show="this.chooseLanguage==='CHINESE'"  :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">⑤，B梯将把乘客直接送达至19楼。</p>
        <p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceSecondPartOne">①，When passengers pass through the pedestrian gate using facial recognition,</p>
        <p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceSecondPartOne">②，The elevator system perceives him as an office worker on the 19th floor, dispatching elevator (B elevator).</p>
        <p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceSecondPartOne">③，The display screen in the elevator hall shows that elevator B is going to the 19th floor, and passengers do not need to press the outbound button again.</p>
        <p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceSecondPartOne">④，Passengers entering the elevator can use voice input "elevator door closing" or intelligent visual system to determine if there are no passengers outside the hall entering the elevator and automatically close the door, without the need to manually press the door closing button.</p>
        <p v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*16+'px'}" class="intelligenceSecondPartOne">⑤，The B elevator will deliver passengers directly to the 19th floor.。</p>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*600+'px',marginBottom:(this.width/2543)*50+'px'}" style="background-color: #eaf0dd" class="thirdPart">
    <div :style="{width:(this.width/2543)*1364+'px',height:100+'%'}">
      <div :style="{width:(this.width/2543)*1364*0.5+'px',height:100+'%'}" style="float: left">
        <div :style="{width:(this.width/2543)*414+'px',height:100+'%'}" style="float: right" class="intelligenceImgSix">
        </div>
      </div>
      <div :style="{width:(this.width/2543)*1364*0.5+'px',height:100+'%',float:'right'}">
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px',paddingTop:(this.width/2543)*39+'px'}" style="text-align: center">
          <span class="partTitle">智能调度派梯</span>
          <br>
          <span :style="{fontSize:(this.width/2543)*16+'px'}">节省乘客候梯、乘梯综合实践</span>
          <br>
          <span :style="{fontSize:(this.width/2543)*16+'px'}">——解决乘梯排队拥挤现象</span>
        </p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px',paddingTop:(this.width/2543)*39+'px'}" style="text-align: center">
          <span class="partTitle">Intelligent dispatch of elevators</span>
          <br>
          <span :style="{fontSize:(this.width/2543)*16+'px'}">Comprehensive Practice of Saving Passengers' Waiting and Riding on Elevators</span>
          <br>
          <span :style="{fontSize:(this.width/2543)*16+'px'}">——Solving the problem of crowded elevator queues</span>
        </p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">由系统组织同一楼层去向的乘客同乘系统指定电梯 避免多个楼层去向的乘客乘坐同一梯而发生多站停靠</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">减少群控多台电梯综合停站次数 提高综合运行效率</p>
        <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">减少群控多台电梯综合运行时间、乘客进出时间和开关门时间 提高综合运输效率</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">The system organizes passengers traveling on the same floor to take the same elevator as designated by the system to avoid passengers traveling on multiple floors from taking the same elevator and stopping at multiple stations</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">Reduce the number of comprehensive stops for multiple elevators under group control and improve overall operational efficiency</p>
        <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px',marginLeft:(this.width/2543)*80+'px'}" class="intelligenceSecondPartTwo">Reduce the comprehensive operation time, passenger entry and exit time, and door opening and closing time of multiple elevators under group control to improve comprehensive transportation efficiency
        </p>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*484+'px'}" style="background-color: #eaf0dd" class="fourthPart">
    <div :style="{width:(this.width/2543)*1364+'px',height:100+'%'}">
      <div :style="{width:(this.width/2543)*1364+'px',height:100+'%'}" style="background-color: rgba(225,225,225,0.1)">
        <div :style="{width:(this.width/2543)*1364*0.5+'px',height:100+'%',float:'left'}">
          <div :style="{width:(this.width/2543)*573+'px',height:(this.width/2543)*451+'px',float:'left',marginLeft:(this.width/2543)*25+'px',marginTop:(this.width/2543)*22+'px',fontSize:(this.width/2543)*100+'px'}" @mouseenter="this.videoPlayTwoColor='red'" @mouseleave="this.videoPlayTwoColor='white'" @click="clickVideoPlayTwo" class="intelligenceImgEight">
            <div :style="{marginTop:(this.width/2543)*573*0.25+'px',marginLeft:(this.width/2543)*250+'px'}">
              <svg t="1687767738004" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3685" width="64" height="64"><path d="M512 96C282.624 96 96 282.624 96 512s186.624 416 416 416 416-186.624 416-416S741.376 96 512 96z m0 768C317.92 864 160 706.08 160 512S317.92 160 512 160s352 157.92 352 352-157.92 352-352 352z" p-id="3686" :fill="this.videoPlayTwoColor"></path><path d="M466.816 324.96a32 32 0 0 0-50.816 25.888v339.776a32 32 0 0 0 50.816 25.856l233.6-169.888a32 32 0 0 0 0-51.776l-233.6-169.856z" p-id="3687" :fill="this.videoPlayTwoColor"></path></svg>
            </div>
          </div>
        </div>
        <div :style="{width:(this.width/2543)*1364*0.5+'px',height:100+'%',float:'left'}">
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*25+'px',paddingTop:(this.width/2543)*39+'px',marginLeft:(this.width/2543)*100+'px'}">
            <span class="partTitle">电梯与机器人等智能设备交互信息</span>
          </p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px',paddingTop:(this.width/2543)*39+'px',marginLeft:(this.width/2543)*100+'px'}">
            <span class="partTitle">Interactive information between elevators and intelligent devices such as robots</span>
          </p>
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">①，人行闸机与机器人交互进出信息；</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" class="intelligenceSecondPartOne">①，Interactive entry and exit information between the pedestrian gate and the robot;；</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">②，电梯与机器人交互信息：机器人要去哪一层；</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" class="intelligenceSecondPartOne">②，Elevator and robot interaction information: which floor the robot is going to;</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">③，智能派梯系统派梯，告知机器人所乘电梯的梯号；</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" class="intelligenceSecondPartOne">③，Intelligent elevator dispatch system sends elevators, informing the robot of the elevator number it is taking;</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">④，电梯与机器人交互：进出轿厢和电梯到达楼层的信息；</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" class="intelligenceSecondPartOne">④，Elevator and robot interaction: information on entering and exiting the car and elevator reaching the floor;</p>
          <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}" class="intelligenceSecondPartOne">⑤，在这过程中机器人受到乘客阻挡，电梯语音系统将提示乘客行为；</p>
          <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}" class="intelligenceSecondPartOne">⑤，During this process, the robot is obstructed by passengers, and the elevator voice system will prompt passengers for behavior;</p>
        </div>
      </div>
    </div>
  </div>
  <el-dialog style="background-color: rgba(0,0,0,0.5)" :fullscreen="true" width="100%" v-model="this.isPlaying" @close="closeVideo" @open="openVideo">
    <video autoplay ref="videoPlayer" :src="this.src" style="width: 100%;height: 100vh"></video>
  </el-dialog>
</div>
</template>

<style lang="scss" scoped>
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.firstPart{
  display: flex;
  justify-content: center;
}
.intelligenceFirstPartWords{
  color: #6c6e6b;
  position: relative;
  padding-left: 30px;
}
.intelligenceFirstPartWords::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e0bb2c;
}
.intelligenceSecondPartTwo{
  color: #6c6e6b;
  line-height: 2;
  position: relative;
  padding-left: 30px;
}
.intelligenceSecondPartTwo::before{
  content: "";
  position: absolute;
  top: 50%;
  left: 3%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d20000;
}
.intelligenceImgOne{
  background-image: url('/src/assets/imgs/about/intelligenceImgOne.png');
  background-size: 100% 100%;
}
.intelligenceImgTwo{
  background-image: url('/src/assets/imgs/about/intelligenceImgTwo.png');
  background-size: 100% 100%;
}
.intelligenceImgTwoEn{
  background-image: url('/src/assets/imgs/about/intelligenceImgTwoEn.png');
  background-size: 100% 100%;
}
.secondPart{
  display: flex;
  justify-content: center;
  background-image: url('/src/assets/imgs/about/intelligenceImgThree.png');
  background-size: 100% 100%;
}
.thirdPart{
  display: flex;
  justify-content: center;
  background-image: url('/src/assets/imgs/about/intelligenceImgFive.png');
  background-size: 100% 100%;
}
.fourthPart{
  display: flex;
  justify-content: center;
  background-image: url('/src/assets/imgs/about/intelligenceImgSeven.png');
  background-size: 100% 100%;
}
.intelligenceImgFour{
  background-image: url('/src/assets/imgs/about/intelligenceImgFour.png');
  background-size: 100% 100%;
}
.intelligenceImgSix{
  background-image: url('/src/assets/imgs/about/intelligenceImgSix.png');
  background-size: 100% 100%;
}
.intelligenceImgEight{
  background-image: url('/src/assets/imgs/about/intelligenceImgEight.png');
  background-size: 100% 100%;
}
/deep/ .el-dialog__headerbtn{
  background-color: white;
}
</style>
