<template>
  <div id="editor"  ref="editor"></div>
  <button @click="updateContent"></button>
  <div style="width: 1000px;height: 100px">
    <div v-html="this.htmlContent"></div>
  </div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.snow.css'



export default {

  data() {
    return {
      quill: null,
      htmlContent: '',
      colorNeed:[]
    }
  },
  mounted() {
    this.quill = new Quill('#editor', {
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            // Quill 默认的按钮
            ['image'],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6,false] }],
            [{ color: ['picker'] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }], // 居中按钮
            ['clean'],
          ],
          handlers: {
            'color': function (value) {
              if (value === 'picker') {
                this.quill.setSelection(this.quill.getSelection(), 'silent');
                let input = document.createElement('input');
                input.setAttribute('type', 'color');
                input.addEventListener('change', () => {
                  this.quill.format('color', input.value);
                });
                document.body.appendChild(input);
                input.click();
                document.body.removeChild(input); // remove afterwards
              } else {
                this.quill.format('color', value);
              }
            }
          }
        }
      }
    })
    // 监听文本更改事件
    //this.quill.on('text-change', this.updateContent)
    // 监听 Text Change 事件
    this.quill.on('text-change', (delta, oldDelta, source) => {
      if (source === 'user') {
        const htmlContent = this.quill.root.innerHTML;
        const firstChar = htmlContent.charAt();
        console.log(firstChar)
        // 将开头的空格替换为 &nbsp;
        if (firstChar ===' ') {
          this.quill.root.innerHTML = `&nbsp;${this.htmlContent.slice(1)}`;
        }
      }
    });
  },
  methods: {
    updateContent() {
      console.log(this.quill.root.innerHTML)
      /*const printContent = () => {
        const content=this.quill.root.innerHTML
        const style = `
        <style>
          ${this.quill.root.ownerDocument.defaultView.getComputedStyle(this.quill.root).cssText}
        </style>
      `;
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        // eslint-disable-next-line no-undef
        printWindow.document.write(`${style}<div>${content}</div>`);
        printWindow.document.close();
        printWindow.print();
        console.log('进入了')
      }
      console.log(printContent)*/
    },
  }
}
</script>

<style lang="scss" scoped>
.ql-editor img {
  width: auto;
  max-width: 100%;
  height: auto;
}
</style>
