<script>
export default {
  inject:['reload'],
  // eslint-disable-next-line vue/multi-word-component-names
  name:'escalator',
  data() {
    return {
      width: document.body.clientWidth,
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  methods:{
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
<div :style="{width:this.width+'px',height:(this.width/2543)*4355+'px'}" style="padding: 0px;margin: 0px">
  <div :style="{width:this.width+'px',height:(this.width/2543)*863+'px'}" class="escalatorOne">
    <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*863+'px'}" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
      <div :style="{width:(this.width/2543)*703+'px',height:(this.width/2543)*863+'px'}">
        <div :style="{width:(this.width/2543)*703+'px',height:(this.width/2543)*109+'px',marginTop:(this.width/2543)*290+'px'}" style="display: flex;justify-content: center;flex-direction: column;align-items: center;background-color: #d20000">
          <p>
            <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*65.06+'px',fontWeight:'bolder'}" style="color:white;font-family: 黑体">自动扶梯/自动人行道</span>
            <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*55.06+'px',fontWeight:'bolder'}" style="color:white;font-family: 黑体">Escalator/moving walkway</span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*1070+'px'}" class="escalatorTwo">
    <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*1070+'px'}" class="escalatorTwoBg"></div>
    <div :style="{width:(this.width/2543)*920+'px',height:(this.width/2543)*1070+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column;align-items: center;">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">商用型自动扶梯（室内、室外）</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">Commercial escalators (indoor and outdoor)</span>
      </p>
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">节能:运用先进的扶梯技术，能耗比达31.1%！获得A+++能效认证。</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">Energy saving: Using advanced escalator technology, the energy consumption ratio reaches 31.1%! Obtain A+++energy efficiency certification.</span>
      </p>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*819+'px'}" class="escalatorThree">
    <div :style="{width:(this.width/2543)*920+'px',height:(this.width/2543)*819+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column;align-items: center;">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">公共交通型自动扶梯（室内、室外）</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">Public transportation type escalators (indoor and outdoor)</span>
      </p>
      <p :style="{width:(this.width/2543)*800+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">超大高度、超节能、重载，经久耐用，安全可靠，应用于地铁站、汽车站、过街天桥、火车站、 机场等公共交通枢纽。提升高度42米，成就行业顶尖！</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">Ultra high height, ultra energy-saving, heavy-duty, durable, safe and reliable.Applied to public transportation hubs such as subway stations, bus stations, overpasses, train stations, airports, etc. Raise the height by 42 meters, achieving the top in the industry!</span>
      </p>
    </div>
    <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*819+'px'}" class="escalatorThreeBg"></div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*819+'px'}" class="escalatorFour">
    <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*819+'px'}" class="escalatorFourBg"></div>
    <div :style="{width:(this.width/2543)*920+'px',height:(this.width/2543)*819+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column;align-items: center;">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">自动人行道（室内、室外）</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">Moving walkways (indoor and outdoor)</span>
      </p>
      <p :style="{width:(this.width/2543)*800+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">是一款在水平或微倾斜方向连续运送客流的交通工具。具有连续工作、运输量大、水平运输距离长的特点，主要用于人流量大的公共场所如机场、车站和大型购物中心或超市等处的长
距离水平运输。</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">It is a transportation tool that continuously transports passenger flow in a horizontal or slightly inclined direction. It has the characteristics of continuous operation, large transportation volume, and long horizontal transportation distance,Mainly used for long-distance horizontal transportation in public places with high pedestrian flow, such as airports, stations, and large shopping centers or supermarkets.</span>
      </p>
    </div>
  </div>
  <div :style="{width:this.width+'px',height:(this.width/2543)*819+'px'}" class="escalatorFive">
    <div :style="{width:this.width+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column;align-items: center;">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">人性化的设计</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: '黑体">humanity design</span>
      </p>
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">商用型自动梯根据需求，有多种入口方式和流向灯选配（具体配置请咨询销售人员）</span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">Commercial escalators have multiple entry methods and optional flow lights according to demand (please consult sales personnel for specific configurations)</span>
      </p>
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*2193+'px',height:(this.width/2543)*470+'px',marginTop:(this.width/2543)*30+'px'}" class="escalatorSixBg"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*2193+'px',height:(this.width/2543)*470+'px',marginTop:(this.width/2543)*30+'px'}" class="escalatorSixBgEn"></div>
    </div>
  </div>
  <div class="titleEight">
    <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
  </div>
</div>
</template>

<style scoped lang="scss">
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
.escalatorOne{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgOne.png");
  background-size: 100% 100%;
}
.escalatorTwoBg{
  float: left;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgTwo.png");
  background-size: 100% 100%;
}
.escalatorThreeBg{
  float: left;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgThree.png");
  background-size: 100% 100%;
}
.escalatorFourBg{
  float: left;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgFour.png");
  background-size: 100% 100%;
}
.escalatorSixBg{
  float: left;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgSix.png");
  background-size: 100% 100%;
}
.escalatorSixBgEn{
  float: left;
  background-image: url("/src/assets/imgs/product/escalator/escalatorBgSixEn.png");
  background-size: 100% 100%;
}
</style>
