<script>
export default {
  inject:['reload'],
    name:'retrofittingOfOldBuildings',
    data(){
        return{
          width:document.body.clientWidth,
          chooseLanguage:'',
          options:[
            {
              value: 'CHINESE',
              label: '中',
            },
            {
              value: 'ENGLISH',
              label: 'EN',
            }
          ],
        }
    },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  methods:{
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
<div style="margin: 0;padding: 0">
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div :style="{width:this.width+'px',height:(this.width/2543)*874+'px'}" class="jljzOneImg">
    <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*874+'px'}">
      <div :style="{marginLeft:(this.width/2543)*870+'px',marginTop:(this.width/2543)*120+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*72.88+'px'}" style="font-weight: bolder;color: white;font-family: '华文宋体';letter-spacing: 1px">旧楼<span style="background-color: #016fce">加装电梯</span></span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*55.88+'px'}" style="font-weight: bolder;color: white;font-family: '华文宋体';letter-spacing: 1px"><span style="background-color: #016fce">Installing elevators</span> in old buildings</span>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',marginTop:(this.width/2543)*50+'px'}" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :style="{width:(this.width/2543)*1364+'px',}" style="text-align: center">
      <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">多种入户方式</span>
      <p>
        <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Multiple Ways to Enter the Home</span>
      </p>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*1081+'px'}" style="background-color: #e6f3f9;display: flex;justify-content: center;align-items: center">
      <div :style="{width:this.width+'px',height:(this.width/2543)*1077+'px'}" class="jljzTwoImg"></div>
    </div>
  </div>
  <div :style="{width:this.width+'px',marginTop:(this.width/2543)*50+'px'}" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :style="{width:(this.width/2543)*1364+'px',}" style="text-align: center">
      <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">产品优势</span>
      <p>
        <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Product Advantage</span>
      </p>
    </div>
    <div :style="{width:(this.width/2543)*1364+'px',}">
      <div :style="{width:(this.width/2543)*725+'px',height:(this.width/2543)*967+'px'}" style="float:left">
        <div :style="{width:(this.width/2543)*650+'px',height:(this.width/2543)*967+'px'}" class="jljzThreeImg"></div>
      </div>
      <div :style="{width:(this.width/2543)*639+'px',height:(this.width/2543)*967+'px'}" style="float:left">
        <div :style="{width:(this.width/2543)*603+'px',height:(this.width/2543)*967+'px'}" class="jljzFourImg"></div>
      </div>
    </div>
  </div>
  <div :style="{width:this.width+'px',marginTop:(this.width/2543)*50+'px'}" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :style="{width:(this.width/2543)*1364+'px',}" style="text-align: center">
      <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">智慧平台</span>
      <p>
        <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Smart Platform</span>
      </p>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*935+'px',}" style="background-color: #e6f3f9;display: flex;justify-content: center;align-items: center;flex-direction: column;">
      <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*935+'px',}" class="jljzFiveImg"></div>
    </div>
  </div>
  <div :style="{width:this.width+'px',marginTop:(this.width/2543)*50+'px'}" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :style="{width:(this.width/2543)*1364+'px',}" style="text-align: center">
      <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">首选安全</span>
      <p>
        <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Preferred Security</span>
      </p>
    </div>
    <div :style="{width:(this.width/2543)*914+'px',height:(this.width/2543)*675+'px'}" class="jljzSixImg"></div>
  </div>
  <div :style="{width:this.width+'px',marginTop:(this.width/2543)*50+'px'}" style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <div :style="{width:(this.width/2543)*1364+'px',}" style="text-align: center">
      <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">全生命周期服务</span>
      <p>
        <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Life Cycle Services</span>
      </p>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*910+'px'}" style="background-color: #f0f6d6;display: flex;justify-content: center">
      <div :style="{width:(this.width/2543)*1113+'px',height:(this.width/2543)*888+'px',marginTop:(this.width/2543)*20+'px'}" class="jljzSevenImg"></div>
    </div>
  </div>
  <div class="titleEight">
    <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
  </div>
</div>
</template>

<style lang="scss" scoped>
.flex-grow{
  flex-grow: 0.5;
}
.jljzOneImg{
  display: flex;
  justify-content: center;
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzOneImg.png');
  background-size: 100% 100%
}
.jljzTwoImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzTwoImg.png');
  background-size: 100% 100%
}
.jljzThreeImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzThreeImg.png');
  background-size: 100% 100%
}
.jljzFourImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzFourImg.png');
  background-size: 100% 100%
}
.jljzFiveImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzFiveImg.png');
  background-size: 100% 100%
}
.jljzSixImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzSixImg.png');
  background-size: 100% 100%
}
.jljzSevenImg{
  background-image: url('/src/assets/imgs/product/retrofittingOfOldBuildings/jljzSevenImg.png');
  background-size: 100% 100%
}
.titleEight{
  bottom:0;
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
.text {
  display: inline-block;
  position: relative;
  font-family: "华文宋体";
}

.text::before,
.text::after {
  content: '';
  position: absolute;
  width: var(--lineWidth);
  height: var(--lineHeight);
  background-color: #d80c18;
  font-family: "华文宋体";
  top: 40%;
}

.text::before {
  right: 100%; /* 将伪元素放置在文字左侧 */
  margin-right: var(--margin); /* 这是为了在文字和伪元素之间增加一些间距 */
}

.text::after {
  left: 100%; /* 将伪元素放置在文字右侧 */
  margin-left: var(--margin); /* 这是为了在文字和伪元素之间增加一些间距 */
}
</style>
