<template>
  <template>
    <i v-if="iconFileName.indexOf('el-icon-') === 0" :class="iconFileName" />
    <svg v-else class="svg-icon" aria-hidden="true" v-bind="$attrs">
      <use :xlink:href="`#icon-${iconFileName}`" />
    </svg>
  </template>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconFileName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: calc(100vw * 120 / 1920);
  height: calc(100vh * 120 / 1920); ;
  overflow: hidden;
  vertical-align: calc(-100vw * 16 / 1920); ;
  fill: currentColor;
}
</style>
