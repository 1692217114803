<script>
export default {
  name:'villaLadder',
  data(){
    return{
      width:document.body.clientWidth,
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    };
  },
  methods:{
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div style="margin: 0;padding: 0;" :style="{width:(this.width/2543)*2543+'px'}">
    <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*2543+'px',height:(this.width/2543)*679+'px'}" class="titleBg"></div>
    <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*2543+'px',height:(this.width/2543)*679+'px'}" class="titleBgEn"></div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*50+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}" style="text-align: center">
        <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">定制化轿厢设计</span>
        <p>
          <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Customized Design</span>
        </p>
      </div>
    </div>
    <div v-show="this.chooseLanguage==='CHINESE'" :style="{height:(this.width/2543)*480+'px'}" class="srdgBg"></div>
    <div v-show="this.chooseLanguage!=='CHINESE'" :style="{height:(this.width/2543)*480+'px'}" class="srdgBgEn"></div>
    <div style="display: flex;justify-content: center;" :style="{width:(this.width/2543)*2543+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <div :style="{marginTop:(this.width/2543)*45+'px'}">
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*170+'px'}" class="EC662" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*217+'px'}" class="EC632" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*190+'px'}" class="EC890" style="float: left"></div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*50+'px'}">
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*480+'px'}" class="jstdBg"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*480+'px'}" class="jstdBgEn"></div>
    </div>
    <div style="display: flex;justify-content: center;" :style="{width:(this.width/2543)*2543+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <div :style="{marginTop:(this.width/2543)*45+'px'}">
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*170+'px'}" class="EC660" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*217+'px'}" class="EC681" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*190+'px'}" class="EC690" style="float: left"></div>
        </div>
      </div>
    </div>
    <div v-show="this.chooseLanguage==='CHINESE'" :style="{height:(this.width/2543)*480+'px',marginTop:(this.width/2543)*50+'px'}" class="khzsBg"></div>
    <div v-show="this.chooseLanguage!=='CHINESE'" :style="{height:(this.width/2543)*480+'px',marginTop:(this.width/2543)*50+'px'}" class="khzsBgEn"></div>
    <div style="display: flex;justify-content: center;" :style="{width:(this.width/2543)*2543+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}">
        <div :style="{marginTop:(this.width/2543)*45+'px'}">
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*170+'px'}" class="EC610" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*217+'px'}" class="EC633" style="float: left"></div>
          <div :style="{width:(this.width/2543)*196+'px',height:(this.width/2543)*430+'px',marginLeft:(this.width/2543)*190+'px'}" class="EC601" style="float: left"></div>
        </div>
      </div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*50+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}" style="text-align: center">
        <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">产品特点</span>
        <p>
          <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Product Feature</span>
        </p>
      </div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',}">
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1065+'px',height:(this.width/2543)*666+'px'}" class="cpzx"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1065+'px',height:(this.width/2543)*666+'px'}" class="cpzxEn"></div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*50+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}" style="text-align: center">
        <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">别墅土建图</span>
        <p>
          <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Construction Layout Drawing</span>
        </p>
      </div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',}">
      <div :style="{width:(this.width/2543)*1322+'px',height:(this.width/2543)*1870+'px'}" class="bstjt"></div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',marginTop:(this.width/2543)*50+'px'}">
      <div :style="{width:(this.width/2543)*1364+'px'}" style="text-align: center">
        <span :style="{fontSize:(this.width/2543)*31+'px',fontWeight:'bolder',color:'#4f4f4f','--lineWidth':(this.width/2543)*512+'px','--lineHeight':(this.width/2543)*8+'px','--margin':(this.width/2543)*60+'px'}" class="text">别墅经典案例</span>
        <p>
          <span :style="{fontSize:(this.width/2543)*28+'px',color:'#a7a7a7'}">Villa Ladder Classic Case</span>
        </p>
      </div>
    </div>
    <div style="display: flex;justify-content: center;align-items: center;" :style="{width:(this.width/2543)*2543+'px',}">
      <div v-show="this.chooseLanguage==='CHINESE'" :style="{width:(this.width/2543)*1363+'px',height:(this.width/2543)*861+'px'}" class="bsjdal"></div>
      <div v-show="this.chooseLanguage!=='CHINESE'" :style="{width:(this.width/2543)*1363+'px',height:(this.width/2543)*861+'px'}" class="bsjdalEn"></div>
    </div>
    <div class="titleEight" :style="{marginTop:(this.width/2543)*38+'px'}">
      <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.flex-grow{
  flex-grow: 0.5;
}
.titleBg{
  background-image: url("/src/assets/imgs/product/villaLadder/new/titleBg.png");
  background-size: 100% 100%;
}
.titleBgEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/titleBgEn.png");
  background-size: 100% 100%;
}
.srdgBg{
  background-image: url("/src/assets/imgs/product/villaLadder/new/srdz.png");
  background-size: 100% 100%;
}
.srdgBgEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/srdzEn.png");
  background-size: 100% 100%;
}
.jstdBg{
  background-image: url("/src/assets/imgs/product/villaLadder/new/jstd.png");
  background-size: 100% 100%;
}
.jstdBgEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/jstdEn.png");
  background-size: 100% 100%;
}
.khzsBg{
  background-image: url("/src/assets/imgs/product/villaLadder/new/khzs.png");
  background-size: 100% 100%;
}
.khzsBgEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/khzsEn.png");
  background-size: 100% 100%;
}
.cpzx{
  background-image: url("/src/assets/imgs/product/villaLadder/new/cpzx.png");
  background-size: 100% 100%;
}
.cpzxEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/cpzxEn.png");
  background-size: 100% 100%;
}
.bstjt{
  background-image: url("/src/assets/imgs/product/villaLadder/new/bstjt.png");
  background-size: 100% 100%;
}
.bsjdal{
  background-image: url("/src/assets/imgs/product/villaLadder/new/bsjdal.png");
  background-size: 100% 100%;
}
.bsjdalEn{
  background-image: url("/src/assets/imgs/product/villaLadder/new/bsjdalEn.png");
  background-size: 100% 100%;
}
.text {
  display: inline-block;
  position: relative;
}

.text::before,
.text::after {
  content: '';
  position: absolute;
  width: var(--lineWidth);
  height: var(--lineHeight);
  background-color: #d80c18;
  font-family: "华文细黑";
  top: 40%;
}

.text::before {
  right: 100%; /* 将伪元素放置在文字左侧 */
  margin-right: var(--margin); /* 这是为了在文字和伪元素之间增加一些间距 */
}

.text::after {
  left: 100%; /* 将伪元素放置在文字右侧 */
  margin-left: var(--margin); /* 这是为了在文字和伪元素之间增加一些间距 */
}
.EC662{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-662.png");
  background-size: 100% 100%;
}
.EC632{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-632.png");
  background-size: 100% 100%;
}
.EC890{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-890.png");
  background-size: 100% 100%;
}
.EC660{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-660.png");
  background-size: 100% 100%;
}
.EC681{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-681.png");
  background-size: 100% 100%;
}
.EC690{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-690.png");
  background-size: 100% 100%;
}
.EC610{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-610.png");
  background-size: 100% 100%;
}
.EC633{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-633.png");
  background-size: 100% 100%;
}
.EC601{
  background-image: url("/src/assets/imgs/product/villaLadder/new/EC-601.png");
  background-size: 100% 100%;
}
.titleEight{
  bottom:0;
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
</style>
