<script>
// eslint-disable-next-line no-unused-vars
import {ArrowRightBold, Right} from "@element-plus/icons-vue";
import router from "@/router";

export default {
  inject:['reload'],
  // eslint-disable-next-line vue/multi-word-component-names
  name:'technology',
  // eslint-disable-next-line vue/no-unused-components
  components:{Right},
  data(){
    return{
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
      width:document.body.clientWidth,
      iconColorS:'red',
      wordsColorS:'black',
      wordsBgColorS:'#a4a4a4',
      iconColorE:'red',
      wordsColorE:'black',
      wordsBgColorE:'#a4a4a4',
      iconColorI:'red',
      wordsColorI:'black',
      wordsBgColorI:'#a4a4a4',
      chooseWho:window.location.pathname,
    }
  },
  methods:{
    chooseSafe(){
      this.$router.push({path:'/technology/safe',query:{language:this.chooseLanguage}})
      this.chooseWho='/technology/safe'
    },
    chooseEnergySaving(){
      this.chooseWho='/technology/energySaving'
      router.push('/technology/energySaving')
    },
    chooseIntelligence(){
      this.chooseWho='/technology/intelligence'
      router.push('/technology/intelligence')
    },
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
  watch: {
    width: {
      immediate: true,
      handler: function () {

      }
    }
  },
}
</script>

<template>
<div style="margin: 0;padding: 0;">
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div :style="{height:(this.width/2543)*460+'px',width:this.width+'px'}" class="firstPart">
    <div :style="{width:(this.width/2543)*1364+'px'}" style="position: absolute;z-index: 1">
      <div :style="{height:(this.width/2543)*70+'px',width:(this.width/2543)*920+'px',marginLeft:(this.width/2543)*52+'px',marginTop:(this.width/2543)*58+'px'}" class="adbLogo" />
      <div :style="{marginTop:(this.width/2543)*83+'px',marginLeft:(this.width/2543)*52+'px',fontSize:(this.width/2543)*65.06+'px'}" class="words">
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'">
            为全球客户提供
          </span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*50+'px'}">
            Provide global customers with
          </span>
          <br>
          <span  v-show="this.chooseLanguage==='CHINESE'">
            <span style="background-color: #d20000">安全、节能、智慧</span>的高科技产品
          </span>
          <span  v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*45+'px'}">
            <span style="background-color: #d20000">safe, energy-saving, and intelligent</span> high-tech products
          </span>
        </p>
      </div>
    </div>
    <div :style="{marginLeft:(this.width/2543)*1100+'px',marginTop:(this.width/2543)*100+'px',width:(this.width/2543)*564+'px',height:(this.width/2543)*244+'px'}" class="abdLogoTwo"/>
  </div>
  <div :style="{width:this.width+'px'}" class="secondPart">
    <div :style="{width:(this.width/2543)*1364+'px'}">
      <p :style="{fontSize:(this.width/2543)*40+'px',width:(this.width/2543)*1364+'px'}" style="text-align: center">
        <span class="partTitle"><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></span>
      </p>
      <div style="float: left">
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*280+'px'}" class="safe"></div>
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*181+'px'}" :class="this.chooseWho!='/technology/safe'?'threeWords':'chosenTechnology'" style="text-align: center;align-items: center;display: flex;justify-content: center" @click="chooseSafe">
          <p>
            <span :style="{fontSize:(this.width/2543)*40+'px'}" style="font-family: '华文细黑';font-weight: bolder;text-align: center;align-items: center;display: flex;justify-content: center"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span><div :style="{width:(this.width/2543)*45+'px',height:(this.width/2543)*45+'px',marginLeft:(this.width/2543)*40+'px'}" :class="this.chooseWho==='/technology/safe'?'rightIconChosen':'rightIcon'"></div> </span>
            <br>
            <span :style="{fontSize:(this.width/2543)*30+'px'}"><span v-show="this.chooseLanguage==='CHINESE'">独特的创新安全技术</span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}">Unique and innovative security technology</span></span>
          </p>
        </div>
      </div>
      <div style="float: left">
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*280+'px',marginLeft:(this.width/2543)*30+'px'}" class="energySaving"></div>
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*181+'px',marginLeft:(this.width/2543)*30+'px'}" :class="this.chooseWho!='/technology/energySaving'?'threeWords':'chosenTechnology'" style="text-align: center;align-items: center;display: flex;justify-content: center" @click="chooseEnergySaving">
          <p>
            <span :style="{fontSize:(this.width/2543)*40+'px'}" style="font-family: '华文细黑';font-weight: bolder;text-align: center;align-items: center;display: flex;justify-content: center"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">Energy Conservation</span><div :style="{width:(this.width/2543)*45+'px',height:(this.width/2543)*45+'px',marginLeft:(this.width/2543)*40+'px'}" :class="this.chooseWho==='/technology/energySaving'?'rightIconChosen':'rightIcon'"></div></span>
            <br>
            <span :style="{fontSize:(this.width/2543)*30+'px'}"><span v-show="this.chooseLanguage==='CHINESE'">会发电的电梯</span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}">An elevator that generates electricity</span></span>
          </p>
        </div>
      </div>
      <div style="float: left">
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*280+'px',marginLeft:(this.width/2543)*30+'px'}" class="intelligence"></div>
        <div :style="{width:(this.width/2543)*430+'px',height:(this.width/2543)*181+'px',marginLeft:(this.width/2543)*30+'px'}" :class="this.chooseWho!='/technology/intelligence'?'threeWords':'chosenTechnology'" style="text-align: center;align-items: center;display: flex;justify-content: center" @click="chooseIntelligence">
          <p>
            <span :style="{fontSize:(this.width/2543)*40+'px'}" style="font-family: '华文细黑';font-weight: bolder;text-align: center;align-items: center;display: flex;justify-content: center"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">Intelligence</span><div :style="{width:(this.width/2543)*45+'px',height:(this.width/2543)*45+'px',marginLeft:(this.width/2543)*40+'px'}" :class="this.chooseWho==='/technology/intelligence'?'rightIconChosen':'rightIcon'"></div></span>
            <br>
            <span :style="{fontSize:(this.width/2543)*30+'px'}"><span v-show="this.chooseLanguage==='CHINESE'">具有人类智慧特征</span><span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*20+'px'}">Characterized by human intelligence</span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div :style="{marginTop:(this.width/2543)*27+'px'}">
    <router-view />
  </div>
  <div :style="{marginTop:(this.width/2543)*30+'px',width:this.width+'px'}" class="titleEight">
    <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
  </div>
</div>
</template>

<style lang="scss" scoped>
.flex-grow{
  flex-grow: 0.5;
}
.floatWindow {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vw * 50 / 1920);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.floatWindowMobile{
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vh/10);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.topNavBar{
  width: 100%;
  height: auto;
  z-index: 1;
}
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color: red;
}
.firstPart{
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  background-image: url("/src/assets/imgs/about/technologyBg.png");
  background-size: 100% 100%;
  .words{
    color: white;
    font-family: '华文细黑';
    font-weight: bolder;
  }
}
.secondPart{
  display: flex;
  justify-content: center;
  .safe{
    background-image: url("/src/assets/imgs/about/safeTitle.png");
    background-size: 100% 100%;
  }
  .rightIcon{
    background-image: url("/src/assets/imgs/about/rightRed.png");
    background-size: 100% 100%;
  }
  .rightIconChosen{
    background-image: url("/src/assets/imgs/about/rightWhite.png");
    background-size: 100% 100%;
  }
  .chosenTechnology{
    color: white;
    background-color: #d20000;
  }
  .threeIcons{
    color: #d20000
  }
  .threeWords{
    color: black;
    background-color: #dedede;
  }
  .threeWords:hover{
    color: white;
    background-color: #d20000;
    .rightIcon{
      background-image: url("/src/assets/imgs/about/rightWhite.png");
    }
  }
  .energySaving{
    background-image: url("/src/assets/imgs/about/energySavingTitle.png");
    background-size: 100% 100%;
  }
  .intelligence{
    background-image: url("/src/assets/imgs/about/intelligenceTitle.png");
    background-size: 100% 100%;
  }
}
.titleEight{
  bottom:0;
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
</style>
