<script>
import {ChatDotRound, Phone} from "@element-plus/icons-vue";
import {ElMessage, ElMessageBox} from "element-plus";
import axios from "axios";
import { onBeforeMount } from 'vue';

export default {
  inject:['reload'],
  name:'adbWebHome',
  components: {Phone, ChatDotRound},
  data(){
    return{
      width: document.body.clientWidth,
      isShowTels:false,
      folder:[
        {
          needFolder: '一中心/胡怀华/',
          label: '胡怀华',
        },
        {
          needFolder: '一中心/杨海飞/',
          label: '杨海飞',
        },
        {
          needFolder: '一中心/周龙全/',
          label: '周龙全',
        },
        {
          needFolder: '二中心/付家家/',
          label: '付家家',
        },
        {
          needFolder: '二中心/韩茜/',
          label: '韩茜',
        },
        {
          needFolder: '二中心/刘宜蓉/',
          label: '刘宜蓉',
        },
        {
          needFolder: '二中心/许飞燕/',
          label: '许飞燕',
        },
        {
          needFolder: '三中心/赵永青/',
          label: '赵永青',
        },
      ],
      images: [
        require('/src/assets/imgs/bg1.jpg'),
        require('/src/assets/imgs/bg2.jpg'),
        require('/src/assets/imgs/bg3.jpg'),
        require('/src/assets/imgs/bg4.jpg'),
        require('/src/assets/imgs/bg5.jpg'),
        // 更多图片路径...
      ],
      currentImageIndex: 0,
      chooseLanguage:'',
      joinUsIsShow:false,
      uploadPhoAndVidIsShow:false,
      joinUsForm:[],
      uploadPhoAndVidForm:[],
      uploadJoinUs: {
        // 是否禁用上传
        isUploading: false,
        url:'',
        // 上传的文件列表
        fileList: [],
        isShowTels:false,
      },
      uploadPhoAndVid: {
        // 是否禁用上传
        isUploading: false,
        url:'',
        // 上传的文件列表
        fileList: [],
        isShowTels:false,
      },
      formDate: new FormData(),
      uploadPhoAndVidFormDate: new FormData(),
      fileNeed:[],
      uploadPhoAndVidFileNeed:[],
    }
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
  },
  created() {
    setInterval(this.nextImage, 5000);
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
  mounted() {
  },
  setup() {
    onBeforeMount(() => {
      window.onresize = () => {
        if(this._isMobile()!=null){
          return this.width = 1366
        }else {
          if(document.body.clientWidth>1366){
            return this.width = document.body.clientWidth
          }else if(document.body.clientWidth<1366) {
            return this.width = 1366
          }
        }
      }
    });
  },
  methods:{
    nextImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    },
    chooseLanguageByCh(){
      window.localStorage.setItem('language','CHINESE')
      this.chooseLanguage='CHINESE'
    },
    chooseLanguageByEn(){
      window.localStorage.setItem('language','ENGLISH')
      this.chooseLanguage='ENGLISH'
    },
    // 判断phone端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    subdownVr(){
      window.open(`http://yzt.sh-ea.net.cn/ADB/?scene_id=72293156`)
    },
    /** 加入我们*/
    joinUs(){
      this.joinUsIsShow=true;
    },
    uploadPhoAndVidIsShowOrNot(){
      this.uploadPhoAndVidIsShow=true;
    },
    uploadSuccess() {
      this.form = {
        file: ''
      }
      this.fileList=[]
      this.reload()
      ElMessage.success("添加任务成功！！")
    },
    /**  自定义上传*/
    // eslint-disable-next-line no-unused-vars
    uploadFileJoinUs(file){
      this.formDate.append('getFiles', file.file);
      console.log(this.formDate)
    },
    // eslint-disable-next-line no-unused-vars
    uploadPhoAndVidFile(file){
      this.uploadPhoAndVidFormDate.append('getFiles', file.file);
    },
    uploadError(){
      this.form={
        file: ''
      }
      this.fileList=[];
      ElMessage.error("添加任务失败，请修改表格内容！！");
      this.reload();
    },
    subuploadPhoAndVid(){
      this.$refs.uploadPhoAndVidMethod.submit();
      this.uploadPhoAndVidFormDate.append('uploadUrl', this.folder.needFolder);
      this.uploadPhoAndVid.isUploading=true;
      axios.post(`http://localhost:8082/uploadFileApplication`,this.uploadPhoAndVidFormDate,)
          .then(()=>{
            this.uploadPhoAndVid.fileList=[];
            this.uploadPhoAndVidForm=[];
            this.uploadPhoAndVid.isUploading=false;
            ElMessage.success("上传成功");
            this.reload();
          });
    },
    // eslint-disable-next-line no-unused-vars
    handleRemove(file, fileList) {
      this.$refs.uploadJoinUs.clearFiles();
      this.formDate=new FormData();
    },
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {
    },
    // eslint-disable-next-line no-unused-vars
    beforeUplpad(file){
    },
    showTel(){
      this.isShowTels=true;
    },
    beforeCloseJoinUs(){
      ElMessageBox.confirm('你确定要关闭此页面吗?')
          .then(() => {
            this.joinUsIsShow=false;
            this.fileList=[];
            this.formDate=new FormData();
          })
          .catch(() => {
            // catch error
          })
    },
    beforeCloseuploadPhoAndVid(){
      ElMessageBox.confirm('你确定要关闭此页面吗?')
          .then(() => {
            this.uploadPhoAndVidIsShow=false;
            this.uploadPhoAndVid.fileList=[];
            this.uploadPhoAndVidFormDate=new FormData();
          })
          .catch(() => {
            // catch error
          })
    },
  },
}
</script>

<template>
  <div :style="{width:this.width+'px',height:(this.width/2543)*50+'px'}" style="display: flex;align-items: center;background-color: #ffffff">
    <div class="webHomeTitle" :style="{width:(this.width/2543)*30+'px',height:(this.width/2543)*50+'px',marginLeft:(this.width/2543)*50+'px',fontSize:(this.width/2543)*18+'px'}" @click="chooseLanguageByCh">中</div>|<div class="webHomeTitle" :style="{width:(this.width/2543)*30+'px',height:(this.width/2543)*50+'px',fontSize:(this.width/2543)*18+'px'}" @click="chooseLanguageByEn">EN</div>
    <el-popover
        placement="top-start"
        :width="200"
        trigger="hover"
    >
      <template #reference>
        <div :style="{width:(this.width/2543)*50+'px',height:(this.width/2543)*50+'px',}" class="webHomeTitle">
          <el-icon :style="{fontSize: (this.width/2543)*30+'px'}"><ChatDotRound style="color: #67c23a;"/></el-icon>
        </div>
      </template>
      <img :src="require('/src/assets/imgs/QR1.png')" :style="{height:(this.width/2543)*200+'px',}"  alt=""/>
    </el-popover>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',marginLeft:(this.width/2543)*100+'px'}"><div v-show="this.chooseLanguage==='CHINESE'">首页</div><div v-show="this.chooseLanguage==='ENGLISH'">Home Page</div></div>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',}" @click="$router.push('/companyIntroduction')"><div v-show="this.chooseLanguage==='CHINESE'">集团概况</div><div v-show="this.chooseLanguage==='ENGLISH'">Group Overview</div></div>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',}" @click="$router.push('/technology/safe')"><div v-show="this.chooseLanguage==='CHINESE'">核心技术</div><div v-show="this.chooseLanguage==='ENGLISH'">Core Technology</div></div>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',}" @click="$router.push('/projectPresentation')"><div v-show="this.chooseLanguage==='CHINESE'">项目展示</div><div v-show="this.chooseLanguage==='ENGLISH'">Project Presentation</div></div>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',}" @click="subdownVr"><div v-show="this.chooseLanguage==='CHINESE'" >VR展示厅</div><div v-show="this.chooseLanguage==='ENGLISH'">VR Cloud Exhibition Hall</div></div>
    <div class="webHomeTitle" :style="{fontSize:(this.width/2543)*22+'px',width:(this.width/2543)*300+'px',height:(this.width/2543)*50+'px',}" @click="$router.push('/hotNews')"><div v-show="this.chooseLanguage==='CHINESE'">新闻中心</div><div v-show="this.chooseLanguage==='ENGLISH'">News Center</div></div>
  </div>
  <div style="position: absolute;z-index: 1">
    <el-image :style="{width:(this.width/2543)*250+'px'}" :src="require('/src/assets/imgs/logo.svg')" />
  </div>
  <div :class="this._isMobile()==null?'floatWindow':'floatWindowMobile'">
    <div class="telShow" @click="this.isShowTels=true" @mouseenter="this.showTel">
      <el-icon><Phone style="color: white" /></el-icon>
    </div>
    <div :class="this._isMobile()==null?'telShowWords':'telShowWordsMobile'" @mouseleave="this.isShowTels=false" v-show="isShowTels">
      <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">总机：</h1>
      <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">switchboard：</h1>
      <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">021-33884999</p>
      <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">免费服务热线：</h1>
      <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">Free Service Hotline：</h1>
      <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">400 889 0388</p>
      <p :class="this._isMobile()==null?'telWords':'telWordsMobile'">800 988 0388</p>
      <h1 v-show="this.chooseLanguage==='CHINESE'" style="color: #fcfafa;">集团产业联系人：</h1>
      <h1 v-show="this.chooseLanguage!=='CHINESE'" style="color: #fcfafa;">Contact person for the group's industry：</h1>
      <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">电梯改造/加装：15921690151</p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Elevator renovation/installation：15921690151</p>
      <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">产业园区：13817622467</p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Industrial Park：13817622467</p>
      <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">教育培训：13671895774</p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Education Training：13671895774</p>
      <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">智能楼宇：15821672579</p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">Smart Buildings：15821672579</p>
      <p v-show="this.chooseLanguage==='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">鼎恒昇：15821672360</p>
      <p v-show="this.chooseLanguage!=='CHINESE'" :class="this._isMobile()==null?'telWords':'telWordsMobile'">DHS：15821672360</p>
    </div>
  </div>
  <div class="slider" :style="{width:this.width+'px',height:(this.width/2543)*1210+'px'}">
    <transition :name="slide">
      <div  :key="currentImageIndex" :style="{width:this.width+'px',height:(this.width/2543)*1210+'px',backgroundImage: `url(${currentImage})`}" style="display: flex;align-items: flex-end;background-size: 100% 100%;object-fit: cover; position: absolute;">
        <div :style="{width:this.width+'px'}" style="position: absolute;z-index: 0;justify-content: center; display: flex;align-items: center;">
          <div class="fiveModule" @click=" $router.push('/product')" :style="{width:(this.width/1897)*348+'px',height:(this.width/1897)*201+'px',fontSize:(this.width/1897)*18+'px'}" style="float: left;background: rgba(0,110,166,0.9);text-align: center">
            <div  class="moduleText">
              <h1 class="moduleText1">电梯产业</h1>
              <h2 class="moduleText2">Elevator Industry</h2>
            </div>
          </div>
          <div class="fiveModule" @click=" $router.push('/industrialPark')" :style="{width:(this.width/1897)*348+'px',height:(this.width/1897)*201+'px',fontSize:(this.width/1897)*18+'px'}" style="float: left;background: rgba(204,74,50,0.9);text-align: center">
            <div  class="moduleText">
              <h1 class="moduleText1">产业园区</h1>
              <h2 class="moduleText2">Industrial Park</h2>
            </div>
          </div>
          <div class="fiveModule" @click=" $router.push('/educationAndTraining')" :style="{width:(this.width/1897)*348+'px',height:(this.width/1897)*201+'px',fontSize:(this.width/1897)*18+'px'}" style="float: left;background: rgba(18,147,160,0.9);text-align: center">
            <div  class="moduleText">
              <h1 class="moduleText1">教育培训</h1>
              <h2 class="moduleText2">Education Training</h2>
            </div>
          </div>
          <div class="fiveModule" @click=" $router.push('/toProduct')" :style="{width:(this.width/1897)*348+'px',height:(this.width/1897)*201+'px',fontSize:(this.width/1897)*18+'px'}" style="float: left;background: rgba(227,190,50,0.9);text-align: center">
            <div  class="moduleText">
              <h1 class="moduleText1">智能楼宇</h1>
              <h2 class="moduleText2">Smart Buildings</h2>
            </div>
          </div>
          <div class="fiveModule" @click=" $router.push('/toProduct')" :style="{width:(this.width/1897)*348+'px',height:(this.width/1897)*201+'px',fontSize:(this.width/1897)*18+'px'}" style="float: left;background: rgba(0,110,166,0.9);text-align: center">
            <div  class="moduleText">
              <h1 class="moduleText1">鼎恒昇</h1>
              <h2 class="moduleText2">DHS</h2>
            </div>
          </div>
        </div>
        <div :style="{width:this.width+'px',height:(this.width/2543)*30+'px',fontSize:(this.width/2543)*18+'px'}" style="cursor: pointer;text-align: right;z-index: 1;background-color: #0c0c0c;color: white;">
          <span style="" onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)" >沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
        </div>
      </div>
    </transition>
  </div>
  <div class="joinUsImg">
    <el-dialog
        v-model="this.uploadPhoAndVidIsShow"
        title="上传文件"
        width="80%"
        draggable
        :before-close="beforeCloseuploadPhoAndVid"
    >
      <el-form :model="uploadPhoAndVidForm" label-width="120px">
        <el-form-item label="上传地址">
          <el-select
              v-model="this.folder.needFolder"
              placeholder="Select"
              size="large"
              style="width: 240px"
          >
            <el-option
                v-for="item in folder"
                :key="item.needFolder"
                :label="item.label"
                :value="item.needFolder"
            />
          </el-select>
        </el-form-item>
        <el-upload
            style="width: 50%;opacity: 0.7"
            id="third-upload-uploadServer"
            ref="uploadPhoAndVidMethod"
            :limit="1000"
            multiple
            drag
            accept=""
            :auction="uploadPhoAndVid.url"
            :file-list="uploadPhoAndVid.fileList"
            :headers="uploadPhoAndVid.headers"
            :before-upload="beforeUplpad"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :http-request="uploadPhoAndVidFile">
          <el-button size="small" type="primary">选取文件</el-button>
          <div class="el-upload__tip">....</div>
        </el-upload>
        <el-button type="primary" @click="subuploadPhoAndVid(uploadPhoAndVidForm)">
          提交
        </el-button>
        <el-button @click="beforeCloseJoinUs">取消</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>
<style scoped lang="scss">
.slider {
  position: relative;
  overflow: hidden;
}
.slide {
  position: absolute;
  object-fit: cover;
}
.slide-enter-active,
.slide-leave-active {
  transition: 1s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(-100%);
}
.moduleText{
  display: inline-block;
  color: #fff;
  text-align: left;
  font-family: "Arial","Microsoft YaHei",Tahoma,"Simsun",sans-serif;
  .moduleText1{

  }
  .moduleText2{

  }
}
.fiveModule{
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
}
.fiveModule:hover{
  transform:translateY(-20px);
  animation-play-state: running;
}
.webHomeTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.webHomeTitle:hover{
  color: #06a3cb;
  background-color: rgba(0, 201, 255, 0.18);
}
/deep/.el-dialog{
  //background: url("/src/assets/imgs/about/bg.png") no-repeat;
}
.floatWindow {
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vw * 50 / 1920);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.floatWindowMobile{
  position: absolute;
  z-index: 1;
  text-align: center;
  padding-top: calc(100vh/10);
  float: left;
  width: auto;
  height: auto;
  right: 0;
}
.telShow {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: calc(100vh/18);
  width: calc(100vh/18);
  font-size: calc(100vh/18);
  float: left;
  .icon {
    display: flex;
  }
}
.telShowWords{
  float: left;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: auto;
  width: calc(100vh/2);
}
.telShowWordsMobile{
  float: left;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #000;
  height: auto;
  width: calc(100vh/2);
}
.telWords{
  color: #f80606;
  font-size: calc(100vw/90)
}
.telWordsMobile{
  color: #fcf8f8;
  font-size: calc(100vh/60)
}
</style>
