<script>
import inductionTraining from "@/view/inductionTraining/inductionTraining.vue";
import {postRequestWithoutToken} from "@/utils/api";

export default {
  name:'inductionTrainingLogin',
  computed: {
    inductionTraining() {
      return inductionTraining
    }
  },
  data(){
    return{
      inductionTrainingTel:'',
    }
  },
  methods:{
    validationTel(){
      postRequestWithoutToken('/inductionTraining/login',this.inductionTrainingTel)
    }
  },

}
</script>

<template>
<div>
  <div>
    <span>用户名:</span><el-input v-model="inductionTrainingTel" placeholder="请输入手机号" />
    <el-button @click="validationTel">登录</el-button>
  </div>
</div>
</template>

<style scoped lang="scss">

</style>
