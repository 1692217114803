<script>
import {CloseBold} from "@element-plus/icons-vue";

export default {
  inject:['reload'],
  name:'educationAndTraining',
  components:{CloseBold},
  data() {
    return {
      width: document.body.clientWidth,
      dialogVisible:true,
      xuzhouShow:false,
      shanghaiaidengbaozhiyejinengShow:false,
      xiaoqihezuoShow:false,
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  methods:{
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
}
</script>

<template>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div style="position: absolute;z-index: 0">
    <div :style="{width:(this.width/2543)*1327+'px',height:(this.width/2543)*337+'px',marginTop:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*368+'px'}" class="xzadgxx" @click="this.xuzhouShow=true"></div>
    <div :style="{width:(this.width/2543)*1327+'px',height:(this.width/2543)*337+'px',marginTop:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*849+'px'}" class="jnpxxx" @click="this.shanghaiaidengbaozhiyejinengShow=true"></div>
    <div :style="{width:(this.width/2543)*1327+'px',height:(this.width/2543)*337+'px',marginTop:(this.width/2543)*20+'px',marginLeft:(this.width/2543)*421+'px',marginBottom:(this.width/2543)*32+'px'}" class="xqhz" @click="this.xiaoqihezuoShow=true"></div>
  </div>
  <div v-show="this.xuzhouShow" :style="{width:this.width+'px',height:100+'vh'}" style="position: absolute;z-index: 1;background-color:rgba(12,12,12,0.9)"></div>
  <div v-show="this.xuzhouShow" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*1021+'px',marginLeft:(this.width/2543)*590+'px',marginTop:(this.width/2543)*20+'px',border: (this.width/2543)*30+'px solid #e94f3c'}" style="position: absolute;z-index: 2;background-color: #fafbfc;">
    <div :style="{width:(this.width/2543)*791+'px',height:(this.width/2543)*167+'px',marginLeft:(this.width/2543)*290+'px',marginTop:(this.width/2543)*43+'px'}" style="float:left" class="xuzhouaidenggaoTitle"></div>
    <div :style="{width:(this.width/2543)*920+'px',marginLeft:(this.width/2543)*225+'px',marginTop:(this.width/2543)*53+'px'}" style="float:left">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}">
           &nbsp;&nbsp;徐州爱登高学校（原爱登堡国际学校）于2014年创办，由上海爱登堡电梯集团爱高教育投资公司全资控股。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*12+'px'}">
           &nbsp;&nbsp;Xuzhou Aidenggao School (formerly known as Edunburgh International School) was founded in 2014 and is wholly-owned by Shanghai Edunburgh Elevator Group Aidengao Education Investment Company.
        </span>
      </p>
      <p :style="{marginTop:(this.width/2543)*30+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;徐州爱登高学校（原爱登堡国际学校），采用15年一贯制教育模型，计划用3个三年规划共9年的时间，打造徐州市及淮海经济区最好的精英学校，并建立国际教育高地。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*12+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;Xuzhou Aidenggao School (formerly known as Edunburgh International School) adopts a 15 year consistent education model,We plan to use three three-year plans for a total of 9 years to build the best elite schools in Xuzhou City and Huaihai Economic Zone, and establish an international education highland.
        </span>
      </p>
      <p :style="{marginTop:(this.width/2543)*30+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;爱登堡国际学校奉行精英教育理念，倡导“宽松、多元、自由”的办学理念和“感恩、责任、服务”的育人理念，致力于营造绿色的教育生态。学校以“培养具有国际视野和创新精神的未来领袖”为办学理念，致力于为学生提供优质的教育资源和全面的发展机会。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*12+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;The Edunburgh International School adheres to the concept of elite education, advocating for the educational philosophy of "relaxation, diversity, and freedom" and the educational philosophy of "gratitude, responsibility, and service", and is committed to creating a green educational ecosystem.The school adheres to the educational philosophy of "cultivating future leaders with an international perspective and innovative spirit", and is committed to providing students with high-quality educational resources and comprehensive development opportunities.
        </span>
      </p>
      <p :style="{marginTop:(this.width/2543)*30+'px'}">
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;学校的教育模式注重学生的个性化发展和全面素质的提升，采用小班化教学和精英化管理模式，为学生提供了良好的学习环境和优质的教育服务。学校还注重学生的体育锻炼和艺术培养，开设了多个体育和艺术课程，为学生提供了多元化的发展机会。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*12+'px'}" style="line-height: 1.5">
           &nbsp;&nbsp;The education model of the school emphasizes the personalized development and comprehensive quality improvement of students, adopting small class teaching and elite management models, providing students with a good learning environment and high-quality educational services.The school also focuses on students' physical exercise and artistic cultivation, offering multiple sports and artistic courses, providing students with diverse development opportunities.
        </span>
      </p>
    </div>
    <div :style="{width:(this.width/2543)*1188+'px',height:(this.width/2543)*383+'px',marginLeft:(this.width/2543)*88+'px',marginTop:(this.width/2543)*40+'px'}" style="float:left" class="xuzhouaidenggaoBg"></div>
    <div style="display: flex;justify-content: flex-end">
      <CloseBold :style="{width: (this.width/2543)*33+'px',height: (this.width/2543)*33+'px'}" style="color: #e94f3c;cursor: pointer;" @click="this.xuzhouShow=false"/>
    </div>
  </div>
  <div v-show="this.shanghaiaidengbaozhiyejinengShow" :style="{width:this.width+'px',height:100+'vh'}" style="position: absolute;z-index: 1;background-color:rgba(12,12,12,0.9)"></div>
  <div v-show="this.shanghaiaidengbaozhiyejinengShow" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*1021+'px',marginLeft:(this.width/2543)*590+'px',marginTop:(this.width/2543)*20+'px',border: (this.width/2543)*30+'px solid #015f3d'}" style="position: absolute;z-index: 2;background-color: #fafbfc;">
    <div :style="{width:(this.width/2543)*791+'px',height:(this.width/2543)*167+'px',marginLeft:(this.width/2543)*300+'px',marginTop:(this.width/2543)*43+'px'}" style="float:left" class="shanghaiaidengbaozhiyexuexiaoTitle"></div>
    <div :style="{width:(this.width/2543)*388+'px',height:(this.width/2543)*534+'px',marginLeft:(this.width/2543)*38+'px'}" style="float:left" class="shanghaiaidengbaozhiyejinengBgOne"></div>
    <div :style="{width:(this.width/2543)*830+'px',marginLeft:(this.width/2543)*30+'px',marginTop:(this.width/2543)*30+'px'}" style="float:left;">
      <p>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">爱登堡职业技能培训学校</span>于2016年成立，由<span style="color: #ff0000">上海市闵行区人力资源和社会保障局</span>主管的正规办学机构，是一所专业从事电梯行业职业技能培训的机构。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">The Edunburgh Vocational Skills Training School</span>was established in 2016 and is a formal educational institution<span style="color: #ff0000"> managed by the Human Resources and Social Security Bureau of Minhang District, Shanghai.</span>It is a professional institution engaged in vocational skills training in the elevator industry.
        </span>
        <br />
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;With years of educational experience, the school has established a comprehensive teaching system,<span style="color: #ff0000">which comprehensively cultivates professional elevator talents from beginners to technicians,</span>helps enterprises complete their career planning paths, effectively combines employees' career development with the talent needs of the enterprise, and is conducive to selecting and retaining talents for the enterprise.
        </span>
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;学校多年的办学经验，建立了完善的教学体系，<span style="color: #ff0000">从初级到技师全面培养专业的电梯人才</span>，帮助企业完成员工的职业规划途径，有效把员工的职业发展和企业的人才需求紧密结合，有利于企业选拔人才留住人才。
        </span>
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;学校提供电梯安装维修工 （五级、四级、三级、二级）、电梯安全管理员（A证）、电梯维修上岗证（T证）的相关培训。学校现拥有<span style="color: #ff0000">2500平方米</span>的培训场地，多间培训教室，<span style="color: #ff0000">128个</span>实训工位。配备多种品类的电梯扶梯实训设备，能同时容纳<span style="color: #ff0000">200人</span>的学习实训。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;The school provides training on elevator installation and maintenance workers (Level 5, Level 4, Level 3, Level 2), elevator safety administrators (A certificate), and elevator maintenance work permits (T certificate).The school currently has<span style="color: #ff0000"> a training venue of 2500 square meters</span> , multiple training classrooms,and <span style="color: #ff0000">128 training workstations.</span>Equipped with various types of elevator and escalator training equipment, it can accommodate <span style="color: #ff0000">200</span> people for learning and training at the same time.
        </span>
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;学校依托于爱登堡电梯集团，能够做到电梯制造、安装、维修及保养等领域的知识与技能同步更新，在师资、设备各方面都具有绝对的优势。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;The school relies on the Edunburgh Elevator Group to update its knowledge and skills in elevator manufacturing, installation, maintenance, and other fields simultaneously. It has absolute advantages in teaching staff and equipment.
        </span>
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">1.  师资力量雄厚。</span>名师授课，<span style="color: #ff0000">“上海工匠”</span>和<span style="color: #ff0000">“大师工作室”</span>成员担任导师，同时聘请业内知名培训教师，确保教学质量。师资队伍中有高级工程师、中级工程师、高级技师，一体化教师达到80%以上。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">1. Strong teaching staff.</span>Famous teachers teach, and members of<span style="color: #ff0000"> "Shanghai Craftsman"  </span><span style="color: #ff0000">and "Master Studio" </span>serve as mentors.At the same time, well-known training teachers in the industry are hired to ensure the quality of teaching. The teaching staff consists of senior engineers, intermediate engineers, and senior technicians, with over 80% of integrated teachers.
        </span>
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">2.  教学设施齐全。</span>实训设施设备一应俱全，保障实操效果。实训设施：门机装置实训室、集成电控系统、有机房电梯、自动扶梯、智能串行通讯控制透明教学电梯等。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">2. The teaching facilities are complete. </span>The training facilities and equipment are fully equipped to ensure the effectiveness of practical operations. Training facilities: door machine device training room, integrated electrical control system, elevator with machine room, escalator, intelligent serial communication control transparent teaching elevator, etc.
        </span>
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">3.  提供在线学习平台。</span>为报名学名开通在线学习账号，随时登录练习，模拟考试，并设有错题集，便于针对性复习，提高学员的考试通过率。
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="color: #ff0000">3. Provide an online learning platform.</span> Open an online learning account for registering a scientific name, log in at any time to practice, simulate exams, and have a set of incorrect questions for targeted review and improve students' exam pass rate.
        </span>
        <br />
        <br />
        <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*16+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="font-weight: bolder" >培训范围：<span style="color: #ff0000">初级  →  中级  →  高级  →  技师</span></span>
        </span>
        <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*10+'px'}" style="line-height: 1.7">
          &nbsp;&nbsp;<span style="font-weight: bolder" >Training scope:<span style="color: #ff0000"> beginner → intermediate → advanced → technician</span></span>
        </span>
      </p>
    </div>
    <div :style="{width:(this.width/2543)*1200+'px',height:(this.width/2543)*231+'px',marginLeft:(this.width/2543)*60+'px'}" style="float:left" class="shanghaiaidengbaozhiyejinengBgTwo"></div>
    <div style="display: flex;justify-content: flex-end">
      <CloseBold :style="{width: (this.width/2543)*33+'px',height: (this.width/2543)*33+'px'}" style="color: #015f3d;cursor: pointer;" @click="this.shanghaiaidengbaozhiyejinengShow=false"/>
    </div>
  </div>
  <div v-show="this.xiaoqihezuoShow" :style="{width:this.width+'px',height:100+'vh'}" style="position: absolute;z-index: 1;background-color:rgba(12,12,12,0.9)"></div>
  <div v-show="this.xiaoqihezuoShow" :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*1021+'px',marginLeft:(this.width/2543)*590+'px',marginTop:(this.width/2543)*20+'px',border: (this.width/2543)*30+'px solid #8ee1c0'}" style="position: absolute;z-index: 2">
    <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*1021+'px'}" class="xiaoqihezuoTitle" style="float: left;position: relative"></div>
    <div :style="{width:(this.width/2543)*1330+'px',height:(this.width/2543)*713+'px',marginTop:(this.width/2543)*282+'px',marginLeft:(this.width/2543)*18+'px'}" style="float: left;position: absolute;z-index: 2" class="xiaoqihezuoBgOne"></div>
    <div :style="{width:(this.width/2543)*490+'px',marginLeft:(this.width/2543)*445+'px',marginTop:(this.width/2543)*280+'px'}" style="position: absolute;z-index: 2;text-align: center;color: #5f5f5f;font-weight: bolder;font-family: 华文细黑;line-height: 1.8">
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        爱登堡校企合作科普基地，
      </span>
      <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}">
        Edunburgh School Enterprise Cooperation Science Popularization Base,
      </p>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        向中小学生们，
      </span>
      <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}">
        To primary and secondary school students:
      </p>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        展示丰富的<span style="background-color: #e40015;color: #ffffff" >科学知识</span>：
      </span>
      <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}">
        to showcase rich <span style="background-color: #e40015;color: #ffffff" >scientific knowledge</span>：
      </p>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span  v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        乘梯安全和电扶梯安全保护措施等，
      </span>
      <p  v-show="this.chooseLanguage!=='CHINESE'"  :style="{fontSize:(this.width/2543)*18+'px'}">
        Improve the sense of safety of passenger elevators by taking safety measures，
      </p>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        提高乘用电梯的安全感；
      </span>
      <p v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}">
        such as elevator safety and escalator safety protection measures;
      </p>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        通过互动体验，
      </span>
      <br v-show="this.chooseLanguage==='CHINESE'" />
      <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*18+'px'}">
        Enhance students' through <span style="background-color: #e40015;color: #ffffff" >technological literacy</span> and <span style="background-color: #e40015;color: #ffffff" >innovation awareness</span> through interactive experiences.
      </span>
      <p v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*30+'px'}">
        增强学生的<span style="background-color: #e40015;color: #ffffff" >科技素养</span>和<span style="background-color: #e40015;color: #ffffff" >创新意识</span>。
      </p>
    </div>
    <div style="display: flex;justify-content: flex-end;position: absolute;z-index: 1">
      <CloseBold :style="{width: (this.width/2543)*33+'px',height: (this.width/2543)*33+'px',marginLeft:(this.width/2543)*1330+'px'}" style="color: #8ee1c0;cursor: pointer;" @click="this.xiaoqihezuoShow=false"/>
    </div>
  </div>
  <div class="titleEight">
    <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)">沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
  </div>
</template>

<style scoped lang="scss">
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
.xzadgxx{
  cursor: pointer;
  background-image: url("/src/assets/imgs/educationAndTraining/xzadgxx.png");
  background-size: 100% 100%;
}
.jnpxxx{
  cursor: pointer;
  background-image: url("/src/assets/imgs/educationAndTraining/jnpxxx.png");
  background-size: 100% 100%;
}
.xqhz{
  cursor: pointer;
  background-image: url("/src/assets/imgs/educationAndTraining/xqhz.png");
  background-size: 100% 100%;
}
.xuzhouaidenggaoTitle{
  background-image: url("/src/assets/imgs/educationAndTraining/xuzhouaidenggaotitle.png");
  background-size: 100% 100%;
}
.xuzhouaidenggaoBg{
  background-image: url("/src/assets/imgs/educationAndTraining/xuzhouaidenggaoBg.png");
  background-size: 100% 100%;
}
.shanghaiaidengbaozhiyexuexiaoTitle{
  background-image: url("/src/assets/imgs/educationAndTraining/shanghaiaidengbaozhiyexuexiaoTitle.png");
  background-size: 100% 100%;
}
.shanghaiaidengbaozhiyejinengBgOne{
  background-image: url("/src/assets/imgs/educationAndTraining/shanghaiaidengbaozhiyejinengBgOne.png");
  background-size: 100% 100%;
}
.shanghaiaidengbaozhiyejinengBgTwo{
  background-image: url("/src/assets/imgs/educationAndTraining/shanghaiaidengbaozhiyejinengBgTwo.png");
  background-size: 100% 100%;
}
.xiaoqihezuoTitle{
  background-image: url("/src/assets/imgs/educationAndTraining/xiaoqihezuoTitle.png");
  background-size: 100% 100%;
}
.xiaoqihezuoBgOne{
  background-image: url("/src/assets/imgs/educationAndTraining/xiaoqihezuoBgOne.png");
  background-size: 100% 100%;
}
</style>
