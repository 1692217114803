<script>
export default {
  inject:['reload'],
  // eslint-disable-next-line vue/multi-word-component-names
  name:'garage',
  data() {
    return {
      width: document.body.clientWidth,
      chooseLanguage:'',
      options:[
        {
          value: 'CHINESE',
          label: '中',
        },
        {
          value: 'ENGLISH',
          label: 'EN',
        }
      ],
    }
  },
  mounted() {
    window.onresize = () => {
      if(document.body.clientWidth>1366){
        return this.width = document.body.clientWidth
      }else if(document.body.clientWidth<1366) {
        return this.width = 1366
      }
    }
  },
  created() {
    this.chooseLanguage=window.localStorage.getItem('language')
    if(this.chooseLanguage===null){
      this.chooseLanguage='CHINESE'
    }
  },
  methods:{
    chooseCNorEN(value){
      window.localStorage.setItem('language',value)
      this.reload()
    }
  },
}
</script>

<template>
  <el-menu
      class="topNavBar"
      mode="horizontal"
      :ellipsis="false"
  >
    <el-menu-item index="0" @click="$router.push('/')">
      <el-image :src="require('/src/assets/imgs/logoBlack.png')" style="width: auto;height: auto;object-fit: cover;" />
    </el-menu-item>
    <div class="flex-grow" />
    <el-select
        v-model="chooseLanguage"
        @change="chooseCNorEN"
        class="m-2"
        placeholder="中/EN"
        size="large"
        :style="{width: (this.width/2543)*130+'px',marginTop:(this.width/2543)*15+'px'}"
    >
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
      />
    </el-select>
    <el-menu-item index="1" @click="$router.push('/')"><span v-show="this.chooseLanguage==='CHINESE'">首页</span><span v-show="this.chooseLanguage!=='CHINESE'">Home Page</span></el-menu-item>
    <el-menu-item index="2" @click="$router.push('/companyIntroduction')"><span v-show="this.chooseLanguage==='CHINESE'">集团概况</span><span v-show="this.chooseLanguage!=='CHINESE'">Group Overview</span></el-menu-item>
    <el-sub-menu index="2" @click="$router.push('/technology/safe')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">核心技术</span><span v-show="this.chooseLanguage!=='CHINESE'">Core Technology</span></template>
      <el-menu-item index="2-1" @click="$router.replace('/technology/safe')"><span v-show="this.chooseLanguage==='CHINESE'">安全</span><span v-show="this.chooseLanguage!=='CHINESE'">Safety</span></el-menu-item>
      <el-menu-item index="2-2" @click="$router.replace('/technology/energySaving')"><span v-show="this.chooseLanguage==='CHINESE'">节能</span><span v-show="this.chooseLanguage!=='CHINESE'">Energy Conservation</span></el-menu-item>
      <el-menu-item index="2-3" @click="$router.replace('/technology/intelligence')"><span v-show="this.chooseLanguage==='CHINESE'">智慧</span><span v-show="this.chooseLanguage!=='CHINESE'">Intelligence</span></el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" @click="$router.push('/product')">
      <template #title><span v-show="this.chooseLanguage==='CHINESE'">电梯产业</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator Industry</span></template>
      <el-menu-item index="3-1" @click="$router.push('/garage')"><span v-show="this.chooseLanguage==='CHINESE'">车库</span><span v-show="this.chooseLanguage!=='CHINESE'">Garage</span></el-menu-item>
      <el-menu-item index="3-2" @click="$router.push('/product')"><span v-show="this.chooseLanguage==='CHINESE'">电梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Elevator</span></el-menu-item>
      <el-menu-item index="3-3" @click="$router.push('/escalator')"><span v-show="this.chooseLanguage==='CHINESE'">扶梯</span><span v-show="this.chooseLanguage!=='CHINESE'">Escalator</span></el-menu-item>
      <el-menu-item index="3-4" @click="$router.push('/retrofittingOfOldBuildings')"><span v-show="this.chooseLanguage==='CHINESE'">旧楼加装</span><span v-show="this.chooseLanguage!=='CHINESE'">Installing New Facilities In Old Buildings</span></el-menu-item>
      <el-menu-item v-show="false" index="3-5">旧梯改造</el-menu-item>
      <el-menu-item v-show="false" index="3-6">配件销售</el-menu-item>
      <el-menu-item v-show="false" index="3-7">维修保养</el-menu-item>
    </el-sub-menu>
    <el-menu-item index="4" @click="$router.push('/projectPresentation')"><span v-show="this.chooseLanguage==='CHINESE'">项目展示</span><span v-show="this.chooseLanguage!=='CHINESE'">Project Presentation</span></el-menu-item>
    <el-menu-item index="5" @click="$router.push('/hotNews')"><span v-show="this.chooseLanguage==='CHINESE'">新闻中心</span><span v-show="this.chooseLanguage!=='CHINESE'">News Center</span></el-menu-item>
    <div class="flex-grow" />
    <el-menu-item index="5" style="display: flex;" disabled>
      <span v-show="this.chooseLanguage==='CHINESE'">总机：021-33884999&emsp;&emsp;&emsp;&emsp;免费服务热线：400-889-0388</span>
      <span v-show="this.chooseLanguage!=='CHINESE'">Switchboard：021-33884999&emsp;&emsp;&emsp;&emsp;Free Service Hotline：400-889-0388</span>
    </el-menu-item>

  </el-menu>
  <div :style="{width:this.width+'px',height:(this.width/2543)*3680+'px'}" style="padding: 0px;margin: 0px">
    <div :style="{width:this.width+'px',height:(this.width/2543)*863+'px'}" class="garageOne">
      <div :style="{width:(this.width/2543)*1364+'px',height:(this.width/2543)*863+'px'}" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
        <div :style="{width:(this.width/2543)*703+'px',height:(this.width/2543)*863+'px'}">
          <div :style="{width:(this.width/2543)*703+'px',height:(this.width/2543)*109+'px',marginTop:(this.width/2543)*290+'px'}" style="display: flex;justify-content: center;flex-direction: column;align-items: center;background-color: #d20000">
            <p>
              <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2543)*65.06+'px',fontWeight:'bolder'}" style="color:white;font-family: 黑体">立体停车设备</span>
              <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2543)*45.06+'px',fontWeight:'bolder'}" style="color:white;font-family: 黑体">Stereoscopic parking equipment</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*1138+'px'}" class="garageTwo">
      <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*1138+'px'}" class="garageTwoBg"></div>
      <div :style="{width:(this.width/2543)*601+'px',height:(this.width/2543)*1138+'px',marginLeft:(this.width/2543)*150+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column">
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: 黑体">垂直循环停车设备</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: 黑体">Vertical circulation parking equipment</span>
        </p>
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">全自动无需倒车，土地利用率高，2.5个平面车位可停放8辆车，可用于小区、单位、商务楼、公共场所，具有动作简单、操作方便，进出车速度快，维修、维护成本低。</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">Fully automatic without the need for reverse, with high land use efficiency, 2.5 flat parking spaces can accommodate 8 vehicles,It can be used in residential areas, units, commercial buildings, and public places, with simple operation, convenient operation, fast entry and exit speed, and low maintenance and repair costs.</span>
        </p>
      </div>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*870+'px'}" class="garageThree">
      <div :style="{width:(this.width/2543)*700+'px',height:(this.width/2543)*870+'px',marginLeft:(this.width/2543)*160+'px'}" style="float: left;display: flex;justify-content: center;flex-direction: column;">
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: 黑体">升降横移停车设备</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder'}" style="color: #000000;font-family: 黑体">Lifting and lateral parking equipment</span>
        </p>
        <p :style="{marginRight:(this.width/2543)*110+'px',width:(this.width/2543)*600+'px'}">
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">单排、联排等多种解决方案，可以全地面、半地下多种形式自由组合，满足不同场地需求。</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px',}">Multiple solutions such as single row and combined row can be freely combined in various forms, including all ground and semi underground, to meet the needs of different venues.</span>
        </p>
      </div>
      <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*870+'px'}" class="garageThreeBg"></div>
    </div>
    <div :style="{width:this.width+'px',height:(this.width/2543)*807+'px'}" class="garageFour">
      <div :style="{width:(this.width/2543)*1623+'px',height:(this.width/2543)*807+'px'}" class="garageFourBg"></div>
      <div :style="{width:(this.width/2543)*920+'px',height:(this.width/2543)*807+'px',}" style="float: left;display: flex;justify-content: center;flex-direction: column;">
        <p>
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder',marginLeft:(this.width/2534)*140+'px'}" style="color: #000000;font-family: 黑体">智能停车设备（AGV）</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*40+'px',fontWeight:'bolder',marginLeft:(this.width/2534)*140+'px'}" style="color: #000000;font-family: 黑体">Automatic Parking Vehicle (AGV)</span>
        </p>
        <p :style="{width:(this.width/2543)*590+'px',marginLeft:(this.width/2534)*140+'px'}">
          <span v-show="this.chooseLanguage==='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px'}">针对不同场合和需求，开发了多种搬运小车（AGV）。结合研发的自动导航系统、 多车调度系统和手机APP，车主无需进入车库，由“爱停车”代为泊车、取车，可实现全程无人全自动存取车。比传统停车方式的停车库增加20%～80%库存容量。</span>
          <span v-show="this.chooseLanguage!=='CHINESE'" :style="{fontSize:(this.width/2534)*16+'px'}">Various handling vehicles (AGV) have been developed for different occasions and needs.Combining the developed automatic navigation system, multi vehicle scheduling system, and mobile app,Car owners do not need to enter the garage, and "Love Parking" can park and pick up the car on their behalf, achieving full automatic unmanned access throughout the entire process.Increase inventory capacity by 20% to 80% compared to traditional parking garages.</span>
        </p>
      </div>
    </div>
    </div>
    <div class="titleEight">
      <span onclick="window.open(`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202004456`)">沪公网安备 31011202004456号 Copyright © 上海爱登堡电梯集团股份有限公司. All rights reserved 沪ICP备05018441号-1</span>
    </div>
</template>

<style scoped lang="scss">
.partTitle{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitlePJ{
  position: relative;
  display: inline-block;
  font-family: 华文细黑;
  color: #313335;
  font-weight: bolder;
}
.partTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 5px;
  background-color:  #d20000;
}
.flex-grow{
  flex-grow: 0.5;
}
.titleEight{
  height: auto;
  text-align: right;
  color: white;
  background-color: #0c0c0c;
}
.garageOne{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url("/src/assets/imgs/product/garage/garageOne.png");
  background-size: 100% 100%;
}
.garageTwoBg{
  float: left;
  background-image: url("/src/assets/imgs/product/garage/garageBgTwo.png");
  background-size: 100% 100%;
}
.garageThreeBg{
  float: left;
  background-image: url("/src/assets/imgs/product/garage/garageBgThree.png");
  background-size: 100% 100%;
}
.garageFourBg{
  float: left;
  background-image: url("/src/assets/imgs/product/garage/garageBgFour.png");
  background-size: 100% 100%;
}
</style>
